<template>
  <div>
    <IncludedNavBar />
    <LeftSideNav />

    <LogOut />
    <div class="rigthSideContents">
      <div class="topContents align-center d-flex justify-content-start">
        <div class="align-center d-flex justify-content-start topContentsLS">
          <img
            :src="`https://ard.searchcept.co.uk/${userData.for.imageProfile}`"
            class="img"
          />
          <div class="bodyContents">
            <h2>
              {{userData.for.Name}} <br />
            </h2>
            <div
              class="
                align-center
                d-flex
                justify-content-between
                align-baseline
                phoneAndAdress
              "
            >
              <i class="fas fa-phone-alt"></i>
              <p> {{userData.for.Phone}} </p>
              <i class="far fa-envelope"></i>
              <p>{{userData.for.Email}}</p>
            </div>
          </div>
        </div>
        <div class="userPlace">
          <h2>{{userData.for.Zone}}</h2>
        </div>
      </div>

      <div class="timeAndDate">
        <div class="contents">
          <i class="fas fa-stopwatch fa-2x"></i>
          <p>Heure: {{userData.createdAt.substr(0, 10)}} PM</p>
        </div>
        <div class="contents">
          <i class="fas fa-calendar-alt fa-2x"></i>
          <p>Date: {{userData.createdAt.substr(14, 5)}}</p>
        </div>
      </div>

      <div class="allContents">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              name="input-7-4"
              label=""
              :value="userData.InfoGenerale.NomSociete"
              disabled
            ></v-text-field>

            <v-text-field
              outlined
              name="input-7-4"
              label=""
              :value="userData.InfoGenerale.Tel"
              disabled
            ></v-text-field>

            <v-text-field
              outlined
              name="input-7-4"
              label=""
              :value="userData.InfoGenerale.Adress"
              disabled
            ></v-text-field>

            <v-text-field
              outlined
              name="input-7-4"
              label=""
              :value="userData.ModeOperation"
              disabled
            ></v-text-field>

            <v-textarea
              outlined
              name="input-7-4"
              label=""
              value="Descriptif"
              disabled
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="9">
            <!-- products -->
                <div
                  v-for="(product, index) in products"
                  :key="index"
                  class="d-flex align-center"
                >
                  <v-checkbox
                    v-model="selected"
                    :label="`${product.name}`"
                    disabled
                  ></v-checkbox>
                  <i class="far fa-eye ml-4" @click="redirectFun(product.name, userData.for._id)"></i>
                </div>
            <!-- <v-row>
              <v-col cols="12" md="4">
                <h2>ENGRAIS CLASSIQUES</h2>
                <div
                  v-for="(EngraisC, index) in EngraisC"
                  :key="index"
                  class="d-flex align-center"
                >
                  <v-checkbox
                    v-model="EngraisC.selected"
                    :label="`${EngraisC.name}`"
                  ></v-checkbox>
                  <i class="far fa-eye ml-4" v-if="EngraisC.selected" @click="redirectFun"></i>
                </div>

              </v-col>
              <v-col cols="12" md="4">
                                <h2>ENGRAIS SPECIAUX</h2>
                                <div
                  v-for="(EngraisS, index) in EngraisS"
                  :key="index"
                  class="d-flex align-center"
                >
                  <v-checkbox
                    v-model="EngraisS.selected"
                    :label="`${EngraisS.name}`"
                  ></v-checkbox>
                  <i class="far fa-eye ml-4" v-if="EngraisS.selected" @click="redirectFun"></i>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                  <h2>PESTICIDES</h2>

                                                  <div
                  v-for="(Pesticides, index) in Pesticides"
                  :key="index"
                  class="d-flex align-center"
                >
                  <v-checkbox
                    v-model="Pesticides.selected"
                    :label="`${Pesticides.name}`"
                  ></v-checkbox>
                  <i class="far fa-eye ml-4" v-if="Pesticides.selected" @click="redirectFun"></i>
                </div>

              </v-col>
            </v-row> -->
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #3f3f41;
$primary-background-color: #b41206;

$primary-color: #fcd309;
$primary-text-color: #242c47;

%connetionAndInscription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 90vh;
  background-image: url("../assets/loginBack.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 5%;
  @media (max-width: 990px) {
    padding-top: 15%;
  }
}

.rigthSideContents {
  width: 80%;
  float: left;
  height: 90vh !important;
  padding: 2% 4%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: $primaryFont;
  overflow: auto;
  .centerContents {
    text-align: center;
    .userName {
      color: #7b3f37;
      font-weight: 600;
      margin-bottom: 30px;
      letter-spacing: 1px;
    }
    .TXT {
      font-weight: bold;
      margin-right: 8px;
    }
    .allTxt {
      line-height: 15px;
    }
    // .email {
    // }
    // .phone {
    // }
    // .zone {
    // }
  }
  .modifyIcone {
    @media (max-width: 990px) {
      display: flex;
      justify-content: space-around;
      align-items: baseline;
    }
    i {
      cursor: pointer;
    }
  }
  .usersImg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .userImg {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
  @media (max-width: 1280px) {
    width: 70%;
  }
  @media (max-width: 989px) {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .v-card {
    width: 50%;
    margin: 0% -2%;
    margin-bottom: 3%;
    @media (max-width: 1400px) {
      width: 100%;
    }
  }
}

.editeAndLogout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    margin: 0% 2%;
    position: relative;
    top: 10px;
}

.timeAndDate {
  display: flex;
  flex-direction: column;
  margin: 2% 0%;
  .contents {
    display: flex;
    align-items: baseline;
    i {
      margin-right: 10px;
    }
    p {
      color: #7b3f37;
      font-weight: 700;
    }
  }
}

.allContents {
  width: 100%;
  i {
      cursor: pointer;
  }
  h2 {
    color: #7b3f37;
    font-weight: 700;
    font-size: 18px;
  }
}

.v-icon {
  font-size: 52px !important;
}
</style>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";

import LogOut from "@/components/Logout"

import axios from "axios";
import API_URL from "../../config";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut
  },
  data() {
    return {
      selected: true,
      userData: null,
      products: null,
    };
  },
  created() {
    axios.get(`${API_URL}/rapport/getOne/${this.$route.params.OID}`).then((selected) => {
      this.userData = selected.data
      this.products = selected.data.Rapport.produits
    })
  },
  methods: {
    redirectFun(val, userID) {
      this.$router.push(`/operationdetailstwo/${this.$route.params.OID}/${val}/${userID}`);
    },
  },
};

// $route.params.OID
</script>
