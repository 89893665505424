<template>
  <div>
    <IncludedNavBar />
    <LeftSideNav />

    <LogOut />

    <div class="rigthSideContents">
      <v-card
        elevation="2"
        class="pa-10"
        v-for="(user, index) in users"
        :key="index"
      >
        <v-row>
          <v-col cols="12" sm="3">
            <div class="usersImg">
              <img
                :src="`https://ard.searchcept.co.uk/${user.imageProfile}`"
                alt=""
                class="userImg"
                v-if="user.imageProfile != 'false'"
              />
              <img
                src="../assets/logo.png"
                alt=""
                class="userImg"
                v-if="user.imageProfile == 'false'"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="8">
            <div class="centerContents">
              <h2 class="userName">{{ user.Name }}</h2>
              <div class="centerContent">
                <p class="email allTxt">
                  <span class="TXT">Email </span> :
                  <span class="TXT2">{{ user.Email }}</span>
                </p>
                <p class="phone allTxt">
                  <span class="TXT">Telephone </span> :
                  <span class="TXT2">{{ user.Phone }}</span>
                </p>
                <p class="zone allTxt">
                  <span class="TXT">Zone </span> :
                  <span class="TXT2">{{ user.Zone }}</span>
                </p>
                <p class="zone allTxt">
                  <span class="TXT">Function </span> :
                  <span class="TXT2">{{ user.userRole }}</span>
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="1">
            <div class="modifyIcone">
              <v-icon
                class="mb-10"
                color="#00ABC4"
                large
                @click="userEditeFun(user._id)"
              >
                mdi-account-edit-outline
              </v-icon>
              <v-icon color="red" large @click="deleteFunc(user._id)">
                mdi-delete
              </v-icon>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.rigthSideContents {
  width: 80%;
  float: left;
  height: 80vh !important;
  padding: 2% 4%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: $primaryFont;
  overflow: auto;
  @media(max-width: 1800px) {
    height: 90vh !important;
  }
  .centerContents {
    text-align: center;
    .centerContent {
      text-align: left;
      margin-left: 10%;
    }
    .userName {
      color: #058b84;
      font-weight: 400;
      margin-bottom: 30px;
      letter-spacing: 1px;
      text-decoration: underline;
      width: 100%;
      word-break: break-word;
      text-align: center;
      font-size: 22px;
    }
    .TXT {
      font-weight: 400;
      margin-right: 8px;
      color: #8a8d8d;
      float: left;
      width: 22%;
      font-size: 14px;
    }
    .TXT2 {
      margin-left: 3%;
      text-decoration: underline;
      color: #adafaf;
      font-size: 14px;
    }
    .allTxt {
      line-height: 20px;
      white-space: nowrap;
    }
    &::before {
      content: "";
      background: url("../assets/line.png");
      width: 100px;
      height: 80%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      top: 10%;
      left: 22%;
    }
  }
  .modifyIcone {
    @media (max-width: 990px) {
      display: flex;
      justify-content: space-around;
      align-items: baseline;
    }
    i {
      cursor: pointer;
    }
  }
  .usersImg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
  }
  .userImg {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 70%;
  }
  @media (max-width: 989px) {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .v-card {
    width: 50%;
    margin: 0% -2%;
    margin-bottom: 3%;
    background: linear-gradient(to bottom, #f2f2f2, #ffffff);
    @media (max-width: 1400px) {
      width: 100%;
    }
  }
}

.editeAndLogout {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  margin: 0% 2%;
  position: relative;
  top: 10px;
  h2 {
    font-size: 20px;
    position: relative;
    margin: 3% 8% 0% 0%;
    cursor: pointer;
    top: -8px;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #757575;
    }
    &::before {
      content: "";
      position: absolute;
      width: 40px;
      height: 4px;
      background-color: black;
      left: -27%;
      top: 12px;
      @media (max-width: 990px) {
        display: none;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 40px;
      height: 4px;
      background-color: black;
      right: -27%;
      top: 12px;
      @media (max-width: 990px) {
        display: none;
      }
    }
  }
}
</style>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";

import LogOut from "@/components/Logout2";

import axios from "axios";
import API_URL from "../../config";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
      userIMG: "",
      users: null,
    };
  },

  created() {
    axios
      .get(`${API_URL}/user/users/all`)
      .then((users) => {
        this.users = users.data;
      })
      .catch(() => {});
  },

  methods: {
    redirectFun() {
      this.$router.push("/addUser");
    },
    userEditeFun(val) {
      this.$router.push(`/editeUser/${val}`);
    },
    deleteFunc(val) {
      this.$swal({
        title: "Do you want to save the changes?",
        showDenyButton: true,
        confirmButtonText: `Suprimer`,
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios.delete(`${API_URL}/user/delete/${val}`).then(() => {
            axios.get(`${API_URL}/user/users/all`).then((users) => {
              this.users = users.data;
            });
          });
          this.$swal("cette utilisateur a été suprimer!", "", "success");
        } else if (result.isDenied) {
          this.$swal("cette operation a été annuler", "", "info");
        }
      });
    },
  },
};
</script>
