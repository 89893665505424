<template>
  <div>
    <div class="PopupNewClient">
      <h1>Entrée de stock</h1>
      <div class="PrsonnePhysique">
        <div class="Form">
          <div class="LeftInputs">
            <div class="InputZone">
              <h6>Date d'éxperation</h6>
              <div class="InputFaild">
                <input
                  type="date"
                  placeholder="Date d'éxperation"
                  v-model="stockDetails.ExperationDate"
                />
              </div>
            </div>
            <div class="InputZone">
              <h6>Depot :</h6>
              <div class="InputFaild">
                <select v-model="stockDetails.Depot">
                  <option
                    v-for="(Depot, n) in listOfDepot"
                    :key="n"
                    :value="Depot"
                  >
                    {{ Depot }}
                  </option>
                </select>
              </div>
            </div>
            <div class="InputZone">
              <h6>Numero de lot</h6>
              <div class="InputFaild">
                <input
                  type="text"
                  placeholder="Numero de lot"
                  v-model="stockDetails.LotNumber"
                />
              </div>
            </div>
          </div>
          <div class="RightInputs">
            <!-- entrprise -->
            <div class="InputZone">
              <h6>Date</h6>
              <div class="InputFaild">
                <input
                  type="date"
                  placeholder="Date"
                  v-model="stockDetails.Date"
                />
              </div>
            </div>
            <div class="InputZone">
              <h6>Catégorie :</h6>
              <div class="InputFaild">
                <select v-model="stockDetails.Category">
                  <option
                    v-for="(Category, n) in listOfCategories"
                    :key="n"
                    :value="Category"
                  >
                    {{ Category }}
                  </option>
                </select>
              </div>
            </div>

            <div class="InputZone">
              <h6>Quantité</h6>
              <div class="InputFaild">
                <input
                  type="number"
                  placeholder="Quantité"
                  v-model="stockDetails.Quantity"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="BtnPopUp">
        <button @click="UpdateStockEnter">Mettre à jour</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Swal from "sweetalert2";

import axios from "axios";
import API_URL from "./../../../config";

export default {
  props: ["PropsToProductPopup"],
  data() {
    return {
      listOfDepot: null,
      listOfCategories: null,
      stockDetails: {
        ExperationDate: "",
        Date: "",
        Depot: "",
        LotNumber: "",
        Category: "",
        Quantity: "",
        Quantity: "",
      },
    };
  },
  computed: {},
  methods: {
    GetAllDepot() {
      axios.get(`${API_URL}/country/getAllCity`).then((response) => {
        this.listOfDepot = response.data;
      });
    },
    GetAllCategoryHave() {
      axios
        .get(`${API_URL}/products/GetAllProductsCategory`)
        .then((response) => {
          this.listOfCategories = response.data;
        });
    },
    GetStockEnterData() {
      this.stockDetails.ExperationDate =
        this.PropsToProductPopup.Details.ExperationDate;
      this.stockDetails.Date = this.PropsToProductPopup.Details.SelectedDate;
      this.stockDetails.Depot = this.PropsToProductPopup.Details.Depot;
      this.stockDetails.LotNumber = this.PropsToProductPopup.Details.LotNumber;
      this.stockDetails.Category = this.PropsToProductPopup.Details.Category;
      this.stockDetails.Quantity = this.PropsToProductPopup.Details.Quantity;
    },
    async UpdateStockEnter() {
      const updatedObject = {
        ExperationDate: this.stockDetails.ExperationDate,
        Depot: this.stockDetails.Depot,
        LotNumber: this.stockDetails.LotNumber,
        Quantity: this.stockDetails.Quantity,
        Category: this.stockDetails.Category,
        SelectedDate: this.stockDetails.Date,
      };

      const productDetailsObjectM = {
        selectedProducts: [
          {
            Name: this.PropsToProductPopup.Details.Produit,
            City: this.PropsToProductPopup.Details.Depot,
            quantity: this.PropsToProductPopup.Details.Quantity,
          },
        ],
      };

      const productDetailsObjectP = {
        selectedProducts: [
          {
            Name: this.PropsToProductPopup.Details.Produit,
            City: this.stockDetails.Depot,
            quantity: this.stockDetails.Quantity,
          },
        ],
      };

      await axios
        .post(`${API_URL}/Stock/updateStock`, productDetailsObjectM)
        .then(() => {})
        .catch((err) => {
          Vue.$toast.open({
            message: err.response.data.error,
            type: "error",
          });
        });

      await axios
        .post(`${API_URL}/Stock/updateStockAvoir`, productDetailsObjectP)
        .then(() => {})
        .catch((err) => {
          Vue.$toast.open({
            message: err.response.data.error,
            type: "error",
          });
        });

      await axios
        .put(`${API_URL}/Stock/updateStockEnter/${this.PropsToProductPopup.Details._id}`, updatedObject)
        .then(() => {
            this.$swal("modifiée", "", "success");

          this.$emit("Close");
        })
        .catch((err) => {
          Vue.$toast.open({
            message: err.response.data.error,
            type: "error",
          });
        });
    },
  },
  mounted() {
    this.GetAllDepot();
    this.GetAllCategoryHave();
    this.GetStockEnterData();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.PopupNewClient {
  position: fixed;
  width: 40%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 11px -4px black;
  left: 30%;
  top: 10%;
  z-index: 100000;
  padding: 40px 40px 100px;
  overflow-y: scroll;
  height: 80%;
  h4 {
    font-family: $primaryFont;
    font-size: 30px;
    font-weight: bold;
    color: $primary-background-color;
    padding-bottom: 30px;
  }
  button {
    font-size: 17px;
    font-family: $primaryFont;
    font-weight: 700;
    border-radius: 10px;
    padding: 6px 30px;
    background-color: $primary-background-color;
    color: white;
    &:hover {
      border: 1px solid $primary-background-color;
      background-color: transparent;
      color: $primary-background-color;
    }
  }
  .Form {
    display: flex;
    align-items: start;
    justify-content: space-between;
    .LeftInputs,
    .RightInputs {
      width: 100%;
      .InputZone {
        margin: 20px 0px;
        h6 {
          font-family: $primaryFont;
          font-size: 20px;
          font-weight: 700;
          color: #142048;
        }
      }
      .InputFaild {
        border: 1px solid #035a7f;
        width: 90%;
        padding: 6px;
        border-radius: 6px;
        display: flex;
        svg {
          margin-right: 5px;
        }
        input,
        select {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
  .TCP {
    margin-bottom: 20px;
    h6 {
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048;
    }

    select {
      border: 1px solid #035a7f;
      width: 95%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .BtnPopUp {
    width: 95%;
    text-align: end;
  }
  .Personne {
    display: flex;
    align-items: center;
    p {
      margin-right: 50px;
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048 !important;
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #142048;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @media screen and (max-width: 1840px) {
  }
  @media screen and (max-width: 1640px) {
    padding: 20px 40px !important;
    width: 60%;
    top: 2%;
    left: 20%;
  }
  @media screen and (max-width: 1440px) {
    h4 {
      font-size: 20px;
      padding-bottom: 10px !important;
    }
    h6 {
      font-size: 15px !important;
    }
    p {
      font-size: 15px !important;
    }
    input,
    select,
    textarea {
      padding: 3px !important;
    }
    .InputZone {
      padding: 3px;
      margin: 10px 0px !important;
    }
  }
}
</style>
