<template>
  <div class="NavBar">
    <!-- <h1>SHOPPY</h1> -->
    <img src="../assets/logo.png" class="navLogo" />
    <v-btn
      v-if="height <= '400'"
      class="mx-2 mobileBtn"
      fab
      dark
      large
      color="#1cb6b6"
      @click.stop="drawer = !drawer"
    >
      <v-icon dark> mdi-microsoft-xbox-controller-menu </v-icon>
    </v-btn>

    <div class="navBar" v-if="height > '400'">
      <ul>
        <li
          class="bellContents"
          @click.stop="notificationDrawer = !notificationDrawer"
          v-if="isLogged"
        >
          <img src="../assets/ardsvg/bell-solid.svg" class="bellStyle" />
          <p class="numbersOfNotife">{{ notificationLists.length }}</p>
        </li>
        <li class="contact">CONTACTEZ NOUS</li>
      </ul>
    </div>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-avatar>
          <img src="../assets/logo.png" class="logo" />
          <h1></h1>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>searchcrm</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="notificationDrawer"
      absolute
      temporary
      right
      width="500"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title
              style="
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 1px;
                margin-top: 5%;
              "
              >Notification</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list class="allNotification" dense>
        <v-list-item
          class="notificationContainer"
          v-for="(notificationList, index) in notificationLists"
          :key="index"
        >
          <div class="notificationContents">
            <div class="notificationTitleDate">
              <p class="notifTitle">{{ notificationList.title }}</p>
              <p class="notifdate">
                {{ notificationList.date }} {{ notificationList.time }}
              </p>
            </div>
            <p class="notificationText">
              {{ notificationList.text }}
            </p>
          </div>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

$primary-font: "PT Sans", sans-serif;
$primary-color: #00ABC4;

html {
  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px black;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }
}
.NavBar {
  background-color: #E1E1E1;
  height: 130px;
  border-bottom: 5px solid #00ABC4;
  position: relative;
  .copy {
    position: absolute;
    color: black;
    font-weight: bold;
    font-family: $primary-font;
    font-size: 20px;
    bottom: 0;
    left: 3%;
    span {
      color: white;
    }
  }
  .navLogo {
    position: absolute;
    z-index: 2;
    width: 150px;
    top: 10%;
    left: 10%;
    @media (max-width: 1200px) {
      left: 2%;
    }
  }
  .navBar {
    position: relative;
    color: $primary-color;
    font-weight: bold;
    padding: 2% 5% 0% 40%;
    @media (max-width: 1200px) {
      padding: 2% 2% 5% 25%;
    }
    ul {
      display: flex;
      align-items: baseline;
      justify-content: right;

      .bellContents {
        position: relative;
        img {
          width: 35px;
        }
        .numbersOfNotife {
          position: absolute;
          right: -10px;
          top: -16%;
          background-color: green;
          color: white;
          padding: 8px;
          border-radius: 50%;
          width: 25px;
          height: 27px;
          text-align: center;
          line-height: 10px;
          font-size: 10px;
        }
      }

      li {
        list-style: none;
        font-family: $primary-font;
        cursor: pointer;

        a {
          text-decoration: none;
          color: $primary-color;
          font-weight: bold;
        }

        // &:nth-of-type(2) {
        //   position: relative;
        //   &::before {
        //     content: "";
        //     position: absolute;
        //     background-color: $primary-color;
        //     width: 30%;
        //     height: 5px;
        //     left: -50px;
        //     top: 10px;
        //   }
        //   &::after {
        //     content: "";
        //     position: absolute;
        //     background-color: $primary-color;
        //     width: 30%;
        //     height: 5px;
        //     right: -50px;
        //     top: 10px;
        //   }
        // }



        // &:nth-of-type(2) {
        //   border: 3px solid $primary-color;
        //   padding: 10px 30px;
        //   border-radius: 40px 0px 40px 40px;
        //   transition: all 0.5s ease-in-out;
        //   margin-left: 5%;
        //   &:hover {
        //     background-color: $primary-color;
        //     color: white;
        //   }
        // }
      }

      .contact {
          border: 3px solid $primary-color;
          padding: 10px 30px;
          border-radius: 30px 0px 30px 0px;
          transition: all 0.5s ease-in-out;
          margin-left: 5%;
          &:hover {
            background-color: $primary-color;
            color: white;
          }
      }
    }
  }
  .logo {
    width: 100px !important;
  }
  .mobileBtn {
    position: absolute;
    right: 5%;
    top: 5%;
    z-index: 2;
  }
}

.allNotification {
  overflow-y: scroll;
  height: 100vh;
  .notificationContainer {
    margin-bottom: 4%;
    .notificationContents {
      background-color: rgba($primary-color, 0.1);
      width: 100%;
      border-radius: 10px;
      padding: 4%;
      p {
        margin-bottom: 0 !important;
      }
      .notificationTitleDate {
        display: flex;
        justify-content: space-between;
        .notifTitle {
          color: $primary-color;
        }
        .notifdate {
          color: $primary-color;
        }
      }
      .notificationText {
        color: #404040;
        font-weight: bold;
        margin-top: 5%;
      }
    }
  }
}
</style>

<script>
import axios from "axios";
import API_URL from "../../config";
import SOCKETS_URL from "../../configSocketsURL";
import io from "socket.io-client";

export default {
  data() {
    return {
      media: null,
      drawer: null,
      notificationDrawer: null,
      items: [
        { title: "Tableau de bord", icon: "mdi-home" },
        { title: "Gestion des opérateurs", icon: "mdi-keyboard-space" },
        { title: "Evaluation", icon: "mdi-information" },
        { title: "Suivi de stock", icon: "mdi-card-account-mail" },
        { title: "Liste des clients", icon: "mdi-home" },
        { title: "Liste des produits", icon: "mdi-keyboard-space" },
        { title: "Gestion de stock", icon: "mdi-information" },
        { title: "Facturation", icon: "mdi-card-account-mail" },
        { title: "Dépôt", icon: "mdi-card-account-mail" },
        { title: "Gestion des operation", icon: "mdi-card-account-mail" },
        { title: "Transfer de stock", icon: "mdi-card-account-mail" },
      ],
      notificationLists: [
        // { title: "Rapport validé", date: "15/02/2022", time: "15:33", text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Temporadolorum recusandae porro cupiditate natus enim? Laborum" },
        // { title: "Rapport annulée", date: "15/02/2022", time: "15:33", text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Temporadolorum recusandae porro cupiditate natus enim? Laborum" },
        // { title: "Rapport annulée", date: "15/02/2022", time: "15:33", text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Temporadolorum recusandae porro cupiditate natus enim? Laborum" },
      ],
      absolute: true,
      opacity: 0.7,
      overlay: true,

      sockets: io.connect(`${SOCKETS_URL}`),

      isLogged: false,
    };
  },
  methods: {
    getAllNotificationFunc() {
      axios.get(`${API_URL}/notification/getAll`).then((response) => {
        this.notificationLists = response.data.reverse();
      });
    },
    notifyMeWithMusic() {
      // var audio = new Audio("https://soundbible.com/mp3/Elevator%20Ding-SoundBible.com-685385892.mp3");
      var audio = new Audio(
        "https://server.searchcrm.net/UPLOADS/notification.wav"
      );
      audio.play();
    },
    islogged() {
      var get_user_id = localStorage.getItem("user_id")
      var get_token = localStorage.getItem('token')

      if (get_user_id && get_token) {
        axios
          .get(`${API_URL}/superAdmin/${get_user_id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${get_token}`,
            },
          })
          .then(() => {
            this.isLogged = true;
          });
      }
    },
  },
  async created() {
    this.sockets.on("getAllRapportBeforeFacture", () => {
      this.getAllNotificationFunc();
      this.notifyMeWithMusic();
    });
    this.sockets.on("getAllRapportAfterFacture", () => {
      this.getAllNotificationFunc();
      this.notifyMeWithMusic();
    });
    this.sockets.on("getAllFacturationMaroc", () => {
      this.getAllNotificationFunc();
      this.notifyMeWithMusic();
    });
    this.sockets.on("getAllFacturationBirot", () => {
      this.getAllNotificationFunc();
      this.notifyMeWithMusic();
    });
    this.sockets.on("getAllFacturationRecouvremment", () => {
      this.getAllNotificationFunc();
      this.notifyMeWithMusic();
    });

    this.getAllNotificationFunc();

    this.islogged()
  },
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
};
</script>

