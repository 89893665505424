<template>
  <div>
    <IncludedNavBar />
    <LeftSideNav />

    <div class="editeAndLogout">
      <v-btn fab dark color="red">
        <v-icon dark> mdi-power </v-icon>
      </v-btn>
    </div>

    <div class="rigthSideContents">
      <div class="topContents align-center d-flex justify-content-start">
        <div class="align-center d-flex justify-content-start topContentsLS">
          <img
            :src="`https://ard.searchcept.co.uk/${userData.for.imageProfile}`"
            class="img"
          />
          <div class="bodyContents">
            <h2>
              {{userData.for.Name}} <br />
            </h2>
            <div
              class="
                align-center
                d-flex
                justify-content-between
                align-baseline
                phoneAndAdress
              "
            >
              <i class="fas fa-phone-alt"></i>
              <p> {{userData.for.Phone}} </p>
              <i class="far fa-envelope"></i>
              <p>{{userData.for.Email}}</p>
            </div>
          </div>
        </div>
        <div class="userPlace">
          <h2>{{userData.for.Zone}}</h2>
        </div>
      </div>

      <div class="timeAndDate">
        <div class="contents">
          <i class="fas fa-stopwatch fa-2x"></i>
          <p>Heure: {{userData.createdAt.substr(0, 10)}} PM</p>
        </div>
        <div class="contents">
          <i class="fas fa-calendar-alt fa-2x"></i>
          <p>Date: {{userData.createdAt.substr(14, 5)}}</p>
        </div>
      </div>

      <div class="tableOfSelected">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">QUANTITIE</th>
                <th class="text-left">POIDS</th>
                <th class="text-left">TYPE D'EMBALLAGE</th>
                <th class="text-left">ETAT D'OPERATION</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in Details" :key="item.name">
                <td>
                  <v-checkbox
                    v-model="checkbox1"
                    :label="oneProd"
                    disabled
                  ></v-checkbox>
                </td>
                <td> {{oneProd.quantite}} </td>
                <td>{{oneProd.packaging}}</td>
                <td>A-3321457 xx</td>
                <td v-if="userData.Etat == 'A'"><v-btn depressed :color="error"> annuler </v-btn></td>
                <td v-if="userData.Etat == 'V'"><v-btn depressed :color="primary"> valider </v-btn></td>
                <td v-if="userData.Etat == 'N'"><v-btn depressed :color="warning"> en cours </v-btn></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <div class="allContents">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              name="input-7-4"
              label=""
              :value="userData.InfoGenerale.NomSociete"
              disabled
            ></v-text-field>

            <v-text-field
              outlined
              name="input-7-4"
              label=""
              :value="userData.InfoGenerale.Tel"
              disabled
            ></v-text-field>

            <v-text-field
              outlined
              name="input-7-4"
              label=""
              :value="userData.InfoGenerale.Adress"
              disabled
            ></v-text-field>

            <v-text-field
              outlined
              name="input-7-4"
              label=""
              :value="userData.ModeOperation"
              disabled
            ></v-text-field>

            <v-textarea
              outlined
              name="input-7-4"
              label=""
              value="Descriptif"
              disabled
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="9">
            <div class="tableWithBorder">
              <h4 style="text-transform: uppercase;">Derniéres opérations effuctuées</h4>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Produits</th>
                      <th class="text-left">Quantité</th>
                      <th class="text-left">Packaging</th>
                      <th class="text-left">Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in DetailsTwo" :key="index">
                      <td>{{ item.name }}</td>
                      <td>{{ item.quantite }}</td>
                      <td>{{ item.packaging }}</td>
                      <td>{{ item.price }} dh</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #3f3f41;
$primary-background-color: #b41206;

$primary-color: #fcd309;
$primary-text-color: #242c47;

%connetionAndInscription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 90vh;
  background-image: url("../assets/loginBack.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 5%;
  @media (max-width: 990px) {
    padding-top: 15%;
  }
}

.rigthSideContents {
  width: 80%;
  float: left;
  height: 90vh !important;
  padding: 2% 4%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: $primaryFont;
  overflow: auto;
  .centerContents {
    text-align: center;
    .userName {
      color: #7b3f37;
      font-weight: 600;
      margin-bottom: 30px;
      letter-spacing: 1px;
    }
    .TXT {
      font-weight: bold;
      margin-right: 8px;
    }
    .allTxt {
      line-height: 15px;
    }
    // .email {
    // }
    // .phone {
    // }
    // .zone {
    // }
  }
  .modifyIcone {
    @media (max-width: 990px) {
      display: flex;
      justify-content: space-around;
      align-items: baseline;
    }
    i {
      cursor: pointer;
    }
  }
  .usersImg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .userImg {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
  @media (max-width: 1280px) {
    width: 70%;
  }
  @media (max-width: 989px) {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .v-card {
    width: 50%;
    margin: 0% -2%;
    margin-bottom: 3%;
    @media (max-width: 1400px) {
      width: 100%;
    }
  }
}

.editeAndLogout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    margin: 0% 2%;
    position: relative;
    top: 10px;
}

.timeAndDate {
  display: flex;
  flex-direction: column;
  margin: 2% 0%;
  .contents {
    display: flex;
    align-items: baseline;
    i {
      margin-right: 10px;
    }
    p {
      color: #7b3f37;
      font-weight: 700;
    }
  }
}

.allContents {
  width: 100%;
  i {
    cursor: pointer;
  }
  h2 {
    color: #7b3f37;
    font-weight: 700;
    font-size: 18px;
  }
  .tableWithBorder {
    border: 2px solid gray;
    padding: 2%;
    h4 {
      color: #245776;
    }
  }
}

.v-icon {
  font-size: 52px !important;
}

.tableOfSelected {
  width: 100%;
  margin-bottom: 14px;
}
</style>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";

import axios from "axios";
import API_URL from "../../config";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
  },
  data() {
    return {
      checkbox1: true,
      Details: [
        {
          name: "Frozen Yogurt",
          calories: 159,
        },
      ],
      DetailsTwo: [],

    userData: null,
    products: null,

    oneProd: null
    };
  },
  created() {
    axios.get(`${API_URL}/rapport/getOne/${this.$route.params.OID}`).then((selected) => {
      this.userData = selected.data
      this.products = selected.data.Rapport.produits

      this.products.map((data) => {
        if(data.name == this.$route.params.SELECTED) {
          this.oneProd = data
        }
      })
    })

    axios.get(`${API_URL}/rapport/rapportProductsByOneMagasin/${this.$route.params.MagasinFor}`).then((selected) => {
      this.DetailsTwo = selected.data
    })
  },
  methods: {
    redirectFun() {
      this.$router.push("/userlists");
    },
  },
};
</script>
