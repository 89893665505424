<template>
  <div>
    <div class="RapportDeVente">
      <div class="TopPopup">
        <h2>Informations sur le stock</h2>
        <!-- <p>21{{NameCode}} <input type="text" /></p> -->
      </div>
      <div class="HeaderTable">
        <div>
          <h3>Produit</h3>
        </div>
        <div>
          <h3>Quantité totale</h3>
        </div>
        <div>
          <h3>Stock</h3>
        </div>
        <div>
          <h3>Quantité demmandée</h3>
        </div>
        <div>
          <h3>Quantité sortie</h3>
        </div>
        <div>
          <h3>Unité</h3>
        </div>
      </div>
      <div class="ProuitZone" v-for="(Produit, n) in ProduitHave" :key="n">
        <div
          class="Produit"
          v-for="(Stock, index) in Produit.produitStock"
          :key="index"
        >
          <div>
            <h3>{{ Stock.name }}</h3>
          </div>
          <div>
            <h3>TOTAL</h3>
          </div>
          <div>
            <select v-model="Stock.StockSelectioner">
              <option value="Selectioner le stock">Selectioner le stock</option>
              <option
                v-for="(QtStock, n) in Stock.Stock"
                :value="QtStock"
                :key="n"
              >
                {{ QtStock.City }} [{{ QtStock.Disponible }}]
              </option>
            </select>
          </div>
          <div>
            <h3>{{ Stock.quantiteByUnity }}{{ Stock.unity }}</h3>
          </div>
          <div>
            <input
              type="number"
              placeholder="0"
              v-model="Stock.QuantitéSortie"
              @keyup="CalculeTotal(n)"
            />
          </div>
          <div>
            <h3>{{ Stock.unity }}</h3>
          </div>
        </div>
        <div class="TotalSortie">
          <h4>Total en {{ Produit.produitStock[0].unity }} :</h4>
          <input
            type="text"
            value="500 KG"
            disabled
            v-model="Produit.Total"
            style="cursor: no-drop"
          />
        </div>
        <!-- <div class="AutreStock">
                    <button  v-if="Produit.produitStock.length > 1" class="Delete" @click="RemoveStock(n),CalculeTotal(n)"> - Supprimer</button>
                    <button class="Add" @click="NewStock(n,Produit)"> + Ajouter autre stock</button>
                </div> -->
      </div>
      <div class="Btn">
        <button class="Valider" @click="ValideForm()">Valider</button>
        <!-- <button class="Valider" @click="socketEmit()">socketEmit</button> -->
        <button class="Annuler" @click="$emit('Close')">Annuler</button>
      </div>
      <div class="MessageErro" v-if="MesssgaeError">
        <svg
          @click="RemovePopup()"
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#000000"
        >
          <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
          <path
            d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z"
          />
        </svg>
        <h5>Les errors</h5>
        <p v-for="(ErrorMesg, n) in AllErrorMessage" :key="n">
          {{ n + 1 }}- {{ ErrorMesg.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import axios from "axios";
import API_URL from "../../config";
import SOCKETS_URL from "../../configSocketsURL";
import Vue from "vue";

import io from "socket.io-client";

export default {
  props: ["RapportId"],
  emit: ["Close"],
  data() {
    return {
      ProduitHave: [
        // {
        //     produitStock:[
        //         {
        //             Name:' LOREM 1',
        //             Stock:'',
        //         },
        //     ]
        // },
        //             {
        //     produitStock:[
        //         {
        //             Name:' LOREM 2',
        //             Stock:'',
        //         }
        //     ]
        // } ,
        //             {
        //     produitStock:[
        //         {
        //             Name:' LOREM 3',
        //             Stock:'',
        //         }
        //     ]
        // }
      ],
      AllProduitRapport: [],
      ProduitPost: [],
      MesssgaeError: false,
      AllErrorMessage: "",
      NameCode: "",
      OperationMode: "",
      sockets: io.connect(`${SOCKETS_URL}`),

      ID: "",
      FromUser: "",
      HaveBL: false,
      HaveBC: false,
      HaveF: false,
      ES: false,
      EC: false,
      S: false,
      P: false,
      HP: false,
      A: false,
      MP: "",
      MG: [],
      getTheData: false,
    };
  },
  computed: {},
  // sockets: {
  //     connection: () => {
  //         console.log('socket connected with backend')
  //     },
  // },
  methods: {
    getThisRapportSumary() {
      // window.open(`https://www.geeksforgeeks.org/`, '_blank')
      // window.open(`https://www.google.co.uk/`, '_blank')

      let listOfUrl = [];

      axios
        .get(`${API_URL}/rapport/getSumOffQuantityOffOneR/${this.RapportId}`)
        .then((response) => {
          this.HaveBL = response.data.HaveBL;
          this.HaveBC = response.data.HaveBC;
          this.HaveF = response.data.HaveF;
          this.ES = response.data.ES;
          this.EC = response.data.EC;
          this.S = response.data.S;
          this.P = response.data.P;
          this.HP = response.data.HP;
          this.A = response.data.A;
          this.MP = response.data.MP;
          this.MG = response.data.MG;

          this.ID = response.data.ID;
          this.FromUser = response.data.FromUser;

          this.getTheData = true;

          if (this.HaveBC) {
            if (this.ES) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/ES/BC?indexOf=1`
              );
            }
            if (this.EC) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/EC/BC?indexOf=2`
              );
            }
            if (this.S) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/S/BC?indexOf=3`
              );
            }
            if (this.P) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/P/BC?indexOf=4`
              );
            }
            if (this.HP) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/HP/BC?indexOf=5`
              );
            }
            if (this.A) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/V/BC?indexOf=6`
              );
            }
          }
          if (this.HaveBL) {
            this.MG.map((city, index) => {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/${city}/BL?indexOf=${index}`
              );
            });
          }
          // if(this.HaveF) {
          //     if(this.ES) {
          //         listOfUrl.push(`${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/ES/F?indexOf=1`)
          //     }
          //     if(this.EC) {
          //         listOfUrl.push(`${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/EC/F?indexOf=2`)
          //     }
          //     if(this.S) {
          //         listOfUrl.push(`${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/S/F?indexOf=3`)
          //     }
          //     if(this.P) {
          //         listOfUrl.push(`${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/P/F?indexOf=4`)
          //     }
          //     if(this.HP) {
          //         listOfUrl.push(`${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/HP/F?indexOf=5`)
          //     }
          //     if(this.A) {
          //         listOfUrl.push(`${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/V/F?indexOf=6`)
          //     }
          // }
        })
        .then(() => {
          listOfUrl.map((el) => {
            window.open(el, "_blank");
          });
        });
    },

    NewStock(n, Produit) {
      this.ProduitHave[n].produitStock.push({
        name: Produit.produitStock[0].name,
        Stock: Produit.produitStock[0].Stock,
        quantiteByUnity: Produit.produitStock[0].quantiteByUnity,
        QuantitéSortie: 0,
        unity: Produit.produitStock[0].unity,
        StockSelectioner: "Selectioner le stock",
        Code: Produit.produitStock[0].Code,
      });
    },
    RemoveStock(n) {
      this.ProduitHave[n].produitStock.pop();
    },
    GetRapportData() {
      axios
        .get(`${API_URL}/rapport/getOne/${this.RapportId}`)
        .then((response) => {
          this.OperationMode = response.data.ModeOperation;
          this.NameCode = response.data.for.NameCode;
          this.AllProduitRapport = response.data.Rapport;
          if (
            response.data.ModeOperation == "Vente" ||
            response.data.ModeOperation == "Avoir"
          ) {
            for (let x in this.AllProduitRapport) {
              this.AllProduitRapport[x].produits.forEach((element) => {
                element.QuantitéSortie = 0;
                element.StockSelectioner = "Selectioner le stock";
                this.ProduitHave.push({
                  Total: 0,
                  produitStock: [element],
                });
              });
            }
          } else {
            response.data.Rapport.produits.forEach((element) => {
              element.QuantitéSortie = 0;
              element.StockSelectioner = "Selectioner le stock";
              this.ProduitHave.push({
                Total: 0,
                produitStock: [element],
              });
            });
          }
        });
    },
    CalculeTotal(index) {
      let Total = 0;
      // console.log(this.ProduitHave[index].produitStock)
      this.ProduitHave[index].produitStock.forEach((element) => {
        if (element.QuantitéSortie !== "") {
          Total += parseInt(element.QuantitéSortie);
          this.ProduitHave[index].Total = Total;
        }
      });
      return Total;
    },
    ValideForm() {
      this.ProduitPost = [];
      let error = [];

      this.ProduitHave.forEach((Produit) => {
        let x = 1;
        Produit.produitStock.forEach((Stock) => {
          if (Stock.StockSelectioner === "Selectioner le stock") {
            error.push({
              name: `Selelect Dépot pour le produit ${Stock.name} sur  la line ${x}`,
            });
          } else if (Stock.QuantitéSortie == 0 || Stock.QuantitéSortie === "") {
            error.push({
              name: `entrer la quantité pour le produit ${Stock.name} sur  la line ${x}`,
            });
          } else if (
            parseInt(Stock.QuantitéSortie) >
              parseInt(Stock.StockSelectioner.Disponible) &&
            this.OperationMode != "Avoir"
          ) {
            console.log(
              Stock.QuantitéSortie,
              ">",
              Stock.StockSelectioner.Disponible
            );
            error.push({
              name: `La quantité de produit ${Stock.name} sur stock ${Stock.StockSelectioner.City} moins que le quantité sortie dans la méme stock sur la line ${x}`,
            });
          }
          x++;
        });
      });
      if (error.length > 0) {
        this.MesssgaeError = true;
        this.AllErrorMessage = error;
        console.log("Error", error);
      } else {
        let ErrorTotal = [];
        this.ProduitHave.forEach((Produit) => {
          let Total = Produit.Total;
          if (
            parseInt(Total) !=
              parseInt(Produit.produitStock[0].quantiteByUnity) &&
            this.OperationMode != "Avoir"
          ) {
            ErrorTotal.push({
              name: `Total de quantité sortie n'est pas egal de quantité dammandé sur le produit ${Produit.produitStock[0].name}`,
            });
          }
          if (
            parseInt(Total) >
              parseInt(Produit.produitStock[0].quantiteByUnity) &&
            this.OperationMode == "Avoir"
          ) {
            ErrorTotal.push({
              name: `Total de quantité sortie n'est pas egal de quantité dammandé sur le produit ${Produit.produitStock[0].name}`,
            });
          }
        });
        if (ErrorTotal.length > 0) {
          this.MesssgaeError = true;
          this.AllErrorMessage = ErrorTotal;
          console.log("Error total", ErrorTotal);
        } else {
          console.log("all is aright now AXIOS HER");
          console.log(this.ProduitPost);
          let x = 0;
          this.ProduitHave.forEach((element) => {
            this.ProduitPost.push([]);
            element.produitStock.forEach((Produit) => {
              let ObjectProduitNeed = {
                productName: Produit.name,
                city: Produit.StockSelectioner.City,
                unity: Produit.unity,
                quantity: Produit.QuantitéSortie,
                Code: Produit.Code,
              };
              this.ProduitPost[x].push(ObjectProduitNeed);
              // console.log(ObjectProduitNeed)
            });
            x++;
          });

          console.log("--------------------");
          var arrayOftock = this.ProduitPost.flat(1);
          var BlCityList = [];
          var filtredBlCityList;

          arrayOftock.map((data) => {
            BlCityList.push(data.city);
          });

          filtredBlCityList = [...new Set(BlCityList)];

          var selectedDataList = this.ProduitPost.flat(1);
          selectedDataList.map((element) => {
            console.log(element);
          });

          console.log("--------------------");

          let ObjectSendit = {
            NumberOfBls: filtredBlCityList.length,
            EtatBonLivraison: "V",
            EtatMagasinier: "V",
            MagasinStock: this.ProduitPost,
          };

          axios
            .put(`${API_URL}/rapport/updateRD/${this.RapportId}`, ObjectSendit)
            .then((response) => {
              axios
                .put(`${API_URL}/rapport/updateFcreatedT/${this.RapportId}`)
                .then(() => {
                  this.$emit("Close");
                  Vue.$toast.open({
                    message: "time updated",
                    type: "success",
                  });

                //   this.sockets.emit(
                //     "validateRapportMagasinier",
                //     this.RapportId,
                //     "V"
                //   );
                })
                .then(() => {
                  axios
                    .post(
                      `${API_URL}/rapport/clearRedisData?keys=["ProductByDepotDpP"]`
                    )
                    .then(() => {
                      // axios.get(`${API_URL}/rapport/getAllFProductByDepot?selectedDepot=Tout&responsableOP=Tout&Type=Tout&MO=Tout`).then(() => {
                      //     window.location.reload()
                      // })
                    });
                });
              // .then(() => {
              //     // this.getThisRapportSumary()
              //     // this.sockets.emit('openRapportAtValidate')
              // })
              // selectedDataList.map((element) => {
              //     axios.post(`${API_URL}/Stock/updateStock/${element.productName}/${element.city}/${parseInt(element.quantity)}`).then(() => {
              //             this.$emit('Close')
              //             Vue.$toast.open({
              //                 message:"Validé",
              //                 type: "success"
              //             });
              //     })
              // })
            });
        }
      }
    },
    // socketEmit() {
    //     this.$socket.emit('validateRapportMagasinier', 'socket emit btn is clicked')
    // },
    RemovePopup() {
      this.MesssgaeError = false;
    },
  },
  mounted() {
    this.GetRapportData();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.TopPopup {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  input {
    border: 1px solid gray;
    width: 80px;
  }
}
.BalckPage {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.RapportDeVente {
  height: 800px;
  overflow-y: scroll;
  width: 70%;
  left: 15%;
  position: absolute;
  z-index: 100;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 11px -4px;
  text-align: center;
  padding: 20px;
  top: 10%;
  @media (max-width: 1600px) {
    height: 500px;
  }
  h2,
  h1 {
    font-family: $primaryFont;
    font-size: 25px;
    font-weight: 600;
    color: $primary-background-color;
  }
  h2 {
    margin-bottom: 40px;
  }
  p {
    font-size: 25px !important;
    font-weight: 600;
    text-align: start;
  }
  .Produit,
  .HeaderTable {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto;
    background-color: #142048;
    padding: 10px;
    border-radius: 10px;
    div {
      width: 300px;
    }
    h3 {
      color: white;
      font-size: 18px;
      margin: 0px;
      border-radius: 5px;
    }
    select,
    input {
      border: 1px solid white !important;
      color: white !important;
      padding: 5px 10px;
      border-radius: 5px;
      width: 90%;
    }
    option {
      color: black;
    }
    ::placeholder {
      color: white;
    }
  }
  .HeaderTable {
    background-color: rgb(142, 25, 25);
  }
  .AutreStock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      padding: 5px 10px;
      border-radius: 5px;
      width: 20%;
      margin-left: auto;
      margin-right: 30px;
      cursor: pointer;
      background-color: transparent;
      color: white;
      font-weight: 600;
      &:hover {
        background-color: transparent;
      }
    }
    .Add {
      color: green;
    }
    .Delete {
      color: red;
    }
  }
  .Btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0px 180px 0px;
    button {
      padding: 10px 30px;
      margin: 0px 30px;
      border-radius: 5px;
      font-size: 17px;
      font-weight: bold;
      color: white;
    }
    .Valider {
      background-color: green;
      &:hover {
        border: 1px solid green;
        color: green;
        background-color: transparent;
      }
    }
    .Annuler {
      background-color: red;
      &:hover {
        border: 1px solid red;
        color: red;
        background-color: transparent;
      }
    }
  }

  .TotalSortie {
    display: flex !important;
    align-items: center !important;
    justify-content: end;
    width: 98%;
    margin: 20px 0px;
    h4 {
      margin-right: 10px;
      font-family: $primaryFont;
      font-size: 25px;
      font-weight: 600;
      color: $primary-background-color;
    }
    input {
      border: 1px solid black;
      padding: 5px 10px 10px;
      border-radius: 5px;
      font-weight: bold !important;
      color: black;
      font-size: 20px;
    }
  }
  .MessageErro {
    width: 50%;
    position: fixed;
    border-radius: 7px;
    z-index: 1000000;
    background-color: white;
    box-shadow: 0px 0px 10px -1px;
    left: 25%;
    top: 15%;
    height: 600px;
    overflow-y: scroll;
    h5 {
      font-size: 25px;
      font-weight: bold;
      color: #142048;
      padding: 30px 0px;
    }
    p {
      text-align: center;
      font-weight: 400;
      color: red;
      padding: 10px 20px;
    }
    img {
      width: 100px;
      object-fit: cover;
    }
    svg {
      fill: red;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
    }
  }
}
</style>
