<template>
  <div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />

    <div class="dropdownSelect">
      <select class="form-select" @change="testFunc" v-model="selected">
        <option disabled>FILTRER PAR</option>
        <option>DATE</option>
        <option>CLIENT</option>
        <option>PRODUIT</option>
        <option>ETAT</option>
        <option>COMERCIALE</option>
      </select>
    </div>
    <div class="rigthSideContents">
      <v-simple-table style="width: 100%">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">DETAILS</th>
              <th class="text-left">NOM COMMERCIAL</th>
              <th class="text-left">OBJET</th>
              <th class="text-left" style="width:20%">DATE</th>
              <th class="text-left">DOMICILIATION</th>
              <!-- <th class="text-left">PRIX PRÉVISIONNEL</th>
              <th class="text-left">AVANCE</th> -->
              <th class="text-left">ÉTAT D'OPÉRATION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in desserts" :key="index">
              <td class="icone"><i class="far fa-eye fa-2x" @click="redirectFun(item._id)"></i></td>
              <td>{{ item.for.Name }}</td>
              <td>{{ item.InfoGenerale.NomSociete }}</td>
              <td>{{ item.createdAt.substr(0, 10) }}</td>
              <td>{{ item.InfoGenerale.Adress }}</td>
              <!-- <td>{{ item.prix }}.00 dh</td>
              <td>{{ item.avance }}.00 dh</td> -->
              <td class="BtnList">
                <v-btn
                  depressed
                  color="error"
                  v-if="item.Etat == 'A'"
                  @click="selectedUIDFunc(item._id)"
                >
                  Annuler
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  v-if="item.Etat == 'V'"
                  @click="selectedUIDFunc(item._id)"
                >
                  Valider
                </v-btn>
                <v-btn
                  depressed
                  color="warning"
                  v-if="item.Etat == 'N'"
                  @click="selectedUIDFunc(item._id)"
                >
                  En cours
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <div class="modaleBtn">
            <v-btn depressed color="error" @click="updateStatusFunc('A')"> annuler </v-btn>
            <v-btn depressed color="primary" @click="updateStatusFunc('V')"> valider </v-btn>
            <v-btn depressed color="warning" @click="updateStatusFunc('N')"> en cours </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog v-model="etatDialog" width="500">
        <v-card>
          <div class="modaleBtn">
            <v-btn depressed color="error"> annuler </v-btn>
            <v-btn depressed color="primary"> valider </v-btn>
            <v-btn depressed color="warning"> en cours </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog v-model="calendarDialog" width="500px">
        <v-card>
            <v-row justify="center">
    <v-date-picker v-model="picker" style="overflow: hidden"></v-date-picker>
    <v-btn depressed color="primary" class="mb-5 pa-8"> recherecher </v-btn>
  </v-row>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #3f3f41;
$primary-background-color: #b41206;

$primary-color: #fcd309;
$primary-text-color: #242c47;

%connetionAndInscription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 90vh;
  background-image: url("../assets/loginBack.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 5%;
  @media (max-width: 990px) {
    padding-top: 15%;
  }
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: 30px solid white;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: 12px solid white;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  height: 60px;
}

.modaleBtn {
  padding: 3%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.rigthSideContents {
  width: 80%;
  float: left;
  height: 90vh;
  padding-left: 5%;
  overflow: auto;
  padding-top: 2%;
  padding-right: 2%;
  font-family: $primaryFont;
  @media screen and (max-width:  1440px) {
    width:80%;
  }

  .BtnList {
    text-align: center;
    button {
      width: 120px;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0rem 1rem;
    font-size: 12px;
    .icone {
      i {
        cursor: pointer;
        color: gray;
        transition: all 0.4s ease-in-out;
        &:hover {
          color: black;
        }
      }
    }
    td {
      border-top: 0px solid #dee2e6;
    }
    tbody {
      tr {
        font-weight: bold;
        font-family: $primaryFont;
        font-size: 15px;
        height: 90px;
        &:last-of-type {
          height: 65px;
        }
        &:nth-child(odd) {
          background-color: rgba(#058B84, .2);
        }
        &:nth-child(even) {
          background-color: rgba(#B4B5B5, .2);
        }

        .detailButton {
          background-color: transparent;
          color: darkslategray;
          border: unset;
        }
        .time {
          color: $secondary-color;
        }
      }
    }
    thead {
      th {
        border-bottom: 0px solid #dee2e6;
        background-color: #058B84;
        color: white !important;
        border-right: 10px solid white;
        border-left: 10px solid white;
        text-align: center !important;
        &:first-of-type {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        &:last-of-type {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
  }
}

.editeAndLogout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    margin: 0% 2%;
    position: relative;
    top: 10px;
}

.dropdownSelect {
    width: 10%;
    margin-left: 5%;
    margin-top: 4%;
    float: left;
  select {
    box-shadow: unset;
    border: 1px solid #B4B5B5;
    color: #B4B5B5;
  }
}

.v-icon {
  font-size: 52px !important;
}

      
</style>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";

import LogOut from "@/components/Logout"

import axios from "axios";
import API_URL from "../../config";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut
  },
  data() {
    return {
      // select: { state: 'PAR PRODUITS', abbr: 'PP' },
      // items: [
      //   { state: 'DATE', abbr: 'DA' },
      //   { state: 'CLIENT', abbr: 'CL' },
      //   { state: 'PAR PRODUITS', abbr: 'PP' },
      //   { state: 'ETAT', abbr: 'ET' },
      //   { state: 'COMERCIALE', abbr: 'CO' },
      // ],
      selected: "FILTRER PAR",
      dialog: false,
      calendarDialog: false,
      etatDialog: false,
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      desserts: null,
      selectedUID: null
    };
  },
  created() {
    axios.get(`${API_URL}/rapport/allRapport`).then((rapport) => {
      this.desserts = rapport.data
    })
  },
  methods: {
    selectedUIDFunc(val) {
      this.dialog = true
      this.selectedUID = val
    },
    updateStatusFunc(Val) {
      axios.put(`${API_URL}/rapport/updateRapportS/${this.selectedUID}/${Val}`).then(() => {
        axios.get(`${API_URL}/rapport/allRapport`).then((rapport) => {
          this.desserts = rapport.data
        })
      })
    },
    redirectFun(val) {
      this.$router.push(`/operationdetails/${val}`);
    },
    testFunc() {
      if(this.selected == "DATE") {
        this.calendarDialog = true
      }
      if(this.selected == "ETAT") {
        this.etatDialog = true
      }
    }
  },
};
</script>
