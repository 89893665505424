<template>
  <div class="ardmap">
    <IncludedNavBar />

    <l-map style="height: 100vh; z-index: 1;" :zoom="zoom" :center="center">
    <l-tile-layer :url="url"></l-tile-layer>
    <div v-for="(userList, index) in usersList" :key="index">
        <l-marker :lat-lng="userList.MarkerLatLng">
        <l-popup><img :src="userList.Img" class="usersImg" @click="selectedUser(userList.Img, userList.Name, userList.Role, userList.phoneNumber, userList.email, userList.Place)" /></l-popup>
        </l-marker>
    </div>
    </l-map>

    <section class="showSelectedUser" v-if="userImg">
        <img :src="userImg">
        <h1>{{userName}}</h1>
        <h2>{{userRole}}</h2>
        <v-icon
        x-large
        >
        mdi-phone
        </v-icon>
        <h3>{{userPhone}}</h3>
        <v-icon
        x-large
        >
        mdi-email-outline
        </v-icon>
        <h3>{{userEmail}}</h3>
        <v-icon
        x-large
        >
        mdi-map-marker
        </v-icon>
        <h3>{{userPlace}}</h3>
    </section>
  </div>
</template>

<script scoped>
import IncludedNavBar from "@/components/IncludedNavBar"

import {LMap, LTileLayer, LMarker, LPopup} from 'vue2-leaflet';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    IncludedNavBar
  },
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 6,
      center: [27.668949, -10.472005],

      userImg: null,
      userName: null,
      userRole: null,
      userPhone: null,
      userEmail: null,
      userPlace: null,

      usersList: [
        {MarkerLatLng: [35.012604, -5.717552], Img: "./assets/ARDUSER1.png", Name: "NADIR AISSAM", Role: "TECHNICO-COMMERCIAUX", phoneNumber: "00 212 6 66 72 63 98", email: "KHALID.AHMANE@ARDUNIFERT.MA", Place: "OULED TEIMA-ZAGORA"},
        {MarkerLatLng: [33.698623, -3.039187], Img: "http://www.ardunifert.ma/resources/img/personnel/ilili.jpg?v=1.0", Name: "ILILI KHALID", Role: "TECHNICO-COMMERCIAUX", phoneNumber: "00 212 6 66 72 63 98", email: "KHALID.AHMANE@ARDUNIFERT.MA", Place: "OULED TEIMA-ZAGORA"},
        {MarkerLatLng: [33.501488, -7.584231], Img: "http://www.ardunifert.ma/resources/img/personnel/sakina_elh.jpeg?v=1.0", Name: "SAKINA EL HOURRI", Role: "TECHNICO-COMMERCIAUX", phoneNumber: "00 212 6 66 72 63 98", email: "KHALID.AHMANE@ARDUNIFERT.MA", Place: "OULED TEIMA-ZAGORA"},
        {MarkerLatLng: [31.939254, -4.278868], Img: "http://www.ardunifert.ma/resources/img/personnel/PHOTO%20ABOUELBANI.png?v=1.0", Name: "ABOUELBANI EL MEHDI", Role: "TECHNICO-COMMERCIAUX", phoneNumber: "00 212 6 66 72 63 98", email: "KHALID.AHMANE@ARDUNIFERT.MA", Place: "OULED TEIMA-ZAGORA"},
        {MarkerLatLng: [32.280621, -9.227909], Img: "http://www.ardunifert.ma/resources/img/personnel/PHOTO%20AHMED%20CHEBLI.png?v=1.0", Name: "CHEBLI AHMED", Role: "TECHNICO-COMMERCIAUX", phoneNumber: "00 212 6 66 72 63 98", email: "KHALID.AHMANE@ARDUNIFERT.MA", Place: "OULED TEIMA-ZAGORA"},
        {MarkerLatLng: [30.383377, -5.467350], Img: "http://www.ardunifert.ma/resources/img/personnel/PHOTO%20NADIR.png?v=1.0", Name: "KHALID AHMANE", Role: "TECHNICO-COMMERCIAUX", phoneNumber: "00 212 6 66 72 63 98", email: "KHALID.AHMANE@ARDUNIFERT.MA", Place: "OULED TEIMA-ZAGORA"},
        {MarkerLatLng: [29.686921, -9.594238], Img: "http://www.ardunifert.ma/resources/img/personnel/PHOTO%20HICHAM3.png?v=1.0", Name: "LAGHOUAOUTA HICHAM", Role: "TECHNICO-COMMERCIAUX", phoneNumber: "00 212 6 66 72 63 98", email: "KHALID.AHMANE@ARDUNIFERT.MA", Place: "OULED TEIMA-ZAGORA"},
        {MarkerLatLng: [28.308050, -10.718831], Img: "http://www.ardunifert.ma/resources/img/personnel/PHOTO%20BOU%20ABDILLAH.png?v=1.0", Name: "MOULAY ABDELLAH BOU ABDILLAH", Role: "TECHNICO-COMMERCIAUX", phoneNumber: "00 212 6 66 72 63 98", email: "KHALID.AHMANE@ARDUNIFERT.MA", Place: "OULED TEIMA-ZAGORA"},
      ]
    };
  },
  methods: {
      selectedUser(img ,name, role, phone, email, place) {
          this.userImg = img
          this.userName = name,
          this.userRole = role,
          this.userPhone = phone,
          this.userEmail = email,
          this.userPlace = place
      }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
$primary-font: 'Poppins', sans-serif;

.ardmap {
    .usersImg {
        border-radius: 50%;
        width:160px;
        height: 150px;
        cursor: pointer;
    }
    .leaflet-popup-content-wrapper {
        padding: 1px;
        text-align: left;
        border-radius: 12px;
        width: 200px !important;
    }
    .showSelectedUser {
        position: absolute;
        top: 18%;
        left: 5%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 2%;
        border-radius: 20px;
        font-family: $primary-font;
        h1 {
            color: #004d5a;
            font-weight: bold;
            margin-bottom: 3%;
            font-size: 20px;
            @media(max-width: 550px) {
                font-size: 15px;
            }
        }
        h2 {
            color: #7b3f37;
            margin-bottom: 5%;
            font-size: 20px;
            font-weight: 500;
            @media(max-width: 550px) {
                margin-bottom: 0;
            }
        }
        h3 {
            color: #152049;
            margin-bottom: 5%;
            font-size: 15px;
            @media(max-width: 550px) {
                margin-bottom: 0;
            }
        }
        i {
            color: white;
            background-color: #003660;
            border-radius: 50%;
            padding: 3%;
            @media(max-width: 550px) {
                font-size: 20px !important;
            }
        }
        img {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            border: 2px solid gray;
            @media(max-width: 550px) {
                width: 100px;
                height: 100px;
            }
        }
    }
}
</style>
