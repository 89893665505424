<template>
  <div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />
    <div class="Magazin">
      <div class="MagazinContents">
        <h1>La gestion des opérations</h1>
        <div class="HeaderTable">
                    <h3>Magazine</h3>
                    <h3>mail</h3>
                    <h3>Référence</h3>
        </div>
        <div class="bodyTable"  v-for="(usersData, index) in userData" :key="index"  @click="reddirectFun(usersData._id)">
                    <h3>Magasine {{ usersData.Name }}</h3>
                    <h3>{{ usersData.Email }}</h3>
                    <h3>{{ usersData._id }}</h3>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";

import axios from "axios";
import API_URL from "../../config";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
      userData: null,
    };
  },
  created() {
        axios.get(`${API_URL}/rapport/allMagasin`).then((selected) => {
            this.userData = selected.data;
            console.log("OK")
        });
  },
  computed: {},
  methods: {
      reddirectFun(val) {
          this.$router.push(`/stepper/${val}`);
      }
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #D2D6D6;
$primaryColor2: #757575;
$primary-background-color: #00ABC4;

.rigthSideContents {
  width: 80%;
  float: left;
  height: 90vh !important;
  padding: 2% 4%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: $primaryFont;
  overflow: auto;
  .centerContents {
    text-align: center;
    .userName {
      color: #7b3f37;
      font-weight: 600;
      margin-bottom: 30px;
      letter-spacing: 1px;
    }
    .TXT {
      font-weight: bold;
      margin-right: 8px;
    }
    .allTxt {
      line-height: 15px;
    }
    // .email {
    // }
    // .phone {
    // }
    // .zone {
    // }
  }
  .modifyIcone {
    @media (max-width: 990px) {
      display: flex;
      justify-content: space-around;
      align-items: baseline;
    }
    i {
      cursor: pointer;
    }
  }
  .usersImg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .userImg {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
  @media (max-width: 1280px) {
    width: 70%;
  }
  @media (max-width: 989px) {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .v-card {
    width: 50%;
    margin: 0% -2%;
    margin-bottom: 3%;
    @media (max-width: 1400px) {
      width: 100%;
    }
  }
}

.Magazin{
  .MagazinContents {
    background: linear-gradient(180deg, #f3f3f3 50%, #dfdfdf 100%);
    float: right;
    width: 70%;
    margin-right: 5%;
    border-radius: 20px;
    padding: 2% 0%;
    margin-top: 5%;
  }
    h1{
      font-family:$primaryFont ;
      font-size: 30px;
      font-weight:bold;
      color:$primary-background-color;
      text-align:center;
      padding-bottom: 30px;
      letter-spacing: 1px;
    }
      .HeaderTable,.bodyTable{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 90%;
            margin: 0px auto;
          h3{
            font-size: 17px;
            padding:10px 0px;
            background-color:#E1E1E1;
            color:#00ABC4;
            font-weight: 600;
            width:400px;
            line-height: 20px;
            text-align: center;
            border-radius: 20px;
        }
      }
      .bodyTable{
        margin-bottom: 10px;
        background-color:$secondary-color;
        border-radius: 20px;
        h3{
           font-size: 17px;
            padding:10px 0px;
            font-weight: 600;
            width:100%;
            overflow-wrap: break-word;
            background-color:transparent;
            border: none;
            color: #058B84;
            margin: 0px;                  
        }
        &:hover{
            background-color:#d6d5d5;
            cursor: pointer;
        }
      }
}
</style>

