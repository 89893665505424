<template>
  <div>
    <IncludedNavBar />
    <LeftSideNav />

    <LogOut />

    <div class="rigthSideContents">
      <div class="fileInputIcone">
        <input
          type="file"
          class="custom-file-input"
          @change="userImgFun($event)"
        />
      </div>
      <v-row>
        <v-col cols="12" lg="6">
          <form class="addUserFormStyle">
            <select v-model="selectedState" class="selectStyle">
              <option disabled>Select</option>
              <option v-for="(item, index) in items" :key="index" :value="item">{{item}}</option>
            </select>

            <input type="text" placeholder="Nom complet" class="inputStyle" v-model="Name" />
            <input type="text" placeholder="Cin" class="inputStyle" v-model="Cin" />
            <input type="text" placeholder="Téléphone" class="inputStyle" v-model="Phone" />
            <input type="text" placeholder="E-mail" class="inputStyle" v-model="email" required />

            <input type="text" class="inputStyle" @keyup="ChangeCodeClientF" placeholder="Code TCP (ne doit pas dépasser 2 caratéres)" maxlength="2" v-model="eliase" :rules="eliaseRoules" required />

            <input type="text" class="inputStyle" placeholder="Numéro d'immatriculation" v-model="UserVehicul" />

            <select v-model="Zone" class="selectStyle">
              <option disabled value="">Zone</option>
              <option v-for="(zoneItem, index) in zoneItems" :key="index">{{zoneItem}}</option>
            </select>

            <div class="passwordField">
              <input :type="showPassword ? 'text' : 'password'" class="inputStyle" placeholder="Mots de passe" v-model="Password" />
              <v-icon v-if="showPassword" @click="showPassword = !showPassword"> mdi-eye-outline </v-icon>
              <v-icon v-if="!showPassword" @click="showPassword = !showPassword"> mdi-eye-off-outline </v-icon>
            </div>

            <div class="addUserBtn">
              <v-btn @click="UpdateFun"> Valider </v-btn>
            </div>
          </form>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card elevation="2" class="pa-10">
            <v-row>
              <v-col cols="12" sm="4">
                <div class="usersImg">
                  <img src="../assets/logo.png" alt="" class="userImg" />
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="centerContents">
                  <h2 class="userName">{{ Name }}</h2>
                  <p class="email allTxt">
                    <span class="TXT">Email: </span>
                    <span class="TXT2">{{ email }}</span>
                  </p>
                  <p class="phone allTxt">
                    <span class="TXT">Téléphone: </span>
                    <span class="TXT2">{{ Phone }}</span>
                  </p>
                  <p class="zone allTxt">
                    <span class="TXT">Zone: </span>
                    <span class="TXT2">{{ Zone }}</span>
                  </p>
                  <p class="function allTxt">
                    <span class="TXT">Function: </span>
                    <span class="TXT2">{{ selectedState }}</span>
                  </p>
                  <p class="CodeTcp allTxt">
                    <span class="TXT">Code TCP: </span>
                    <span class="CodeTcpStyle">{{ generatedEliase }}</span>
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.editeAndLogout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    margin: 0% 2%;
    position: relative;
    top: 10px;
  h2 {
    font-size: 20px;
    position: relative;
    margin: 3% 8% 0% 0%;
    cursor: pointer;
    top: -8px;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #757575;
    }
  }
}

.rigthSideContents {
  width: 80%;
  float: left;
  height: 90vh !important;
  padding: 2% 4%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: $primaryFont;
  overflow: auto;
  .addUserFormStyle {
    position: relative;
    background: linear-gradient(to bottom, #f2f2f2, #ffffff);
    padding: 18% 10% 3% 10%;
    border-radius: 20px;
    box-shadow: 7px 8px 15px -12px black;
    top: -5%;
    z-index: 1;
    .selectStyle {
      width: 100%;
      border: 1px solid #707070;
      border-radius: 20px;
      padding: 1% 4%;
      margin-bottom: 2%;
      color: #707070;
      &:focus-visible {
        outline: unset;
      }
    }
    .passwordField {
      display: flex;
      align-items: center;
      button {
        font-size: 44px !important;
      }
    }
    .inputStyle {
      width: 100%;
      border: 1px solid #707070;
      border-radius: 20px;
      padding: 1% 4%;
      margin-bottom: 2%;
      color: #707070;
      &:focus-visible {
        outline: unset;
      }
    }
    .addUserBtn {
      display: flex;
      justify-content: center;
      button {
        width: 40%;
        color: red;
      }
    }
  }
  .centerContents {
    text-align: center;
    position: relative;
    &::after {
      content: "";
      background: url("../assets/line.png");
      width: 100px;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      top: 0;
      left: -10%;
    }

    .centerContent {
      text-align: left;
      margin-left: 10%;
    }

    .CodeTcp {
      .CodeTcpStyle {
        margin-left: 3%;
        color: #FFFFFF;
        background-color: #00ABC4;
        padding: .5% 5%;
        border-radius: 20px;
      }
    }

    .userName {
      color: #058b84;
      font-weight: 400;
      margin-bottom: 30px;
      letter-spacing: 1px;
      text-decoration: underline;
      width: 100%;
      word-break: break-word;
      text-align: center;
    }
    .TXT {
      font-weight: 400;
      margin-right: 8px;
      color: #8a8d8d;
      float: left;
      width: 33%;
    }
    .TXT2 {
      margin-left: 3%;
      text-decoration: underline;
      color: #adafaf;
    }
    .allTxt {
      line-height: 15px;
    }
  }
  .modifyIcone {
    @media (max-width: 990px) {
      display: flex;
      justify-content: space-around;
      align-items: baseline;
    }
    i {
      cursor: pointer;
    }
  }
  .usersImg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
  }
  .userImg {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 70%;
  }
  @media (max-width: 989px) {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .v-card {
    width: 100%;
    margin: 22% 5%;
    margin-bottom: 3%;
    background: linear-gradient(to bottom, #f2f2f2, #ffffff);
    @media (max-width: 1400px) {
      width: 100%;
    }
  }
}

.v-icon {
  font-size: 52px !important;
}

.fileInputIcone {
  position: absolute;
  top: 20%;
  left: 39%;
  z-index: 2;
  @media (max-width: 712px) {
    left: 5%;
  }
  button,
  input,
  select,
  textarea {
    width: 100px;
    // @media(max-width: 1800px) {
    //   width: 66px;
    // }
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f007";
    display: inline-block;
    // border: 1px solid #999;
    border-radius: 50%;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 50px;
    color: #8A8D8D;
    background-color: #FFFFFF;
    padding: 10% 29%;
    width: 100%;
    border: 1px solid #8A8D8D;
    // @media(max-width: 1800px) {
    //   font-size: 30px;
    // }
  }
  // .custom-file-input:hover::before {
  //   border-color: black;
  // }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
}
// .centerContents {
//     text-align: center;
//     display: flex;
//     justify-content: flex-start;
//     align-items: flex-start;
//     flex-direction: column;
// }
</style>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout2"

import axios from "axios";
import API_URL from "../../config";
import Vue from "vue";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut
  },
  data() {
    return {
      userIMG: null,
      email: "MOURAD.ENNASSIR@ARDUNIFERT.MA",
      emailRules: [
        (v) => !!v || "cette champ et obligatoir",
        (v) => /.+@.+/.test(v) || "cette e-mail et pas valid",
      ],

      eliase: "AB",
      eliaseRoules: [(v) => !!v || "cette champ et obligatoir"],
      generatedEliase: "",

      passwordRules: [(v) => !!v || "cette champ et obligatoir"],

      items: [
        "Commercial",
        "Morocco",
        "Technical Department",
        "D.Commercial",
        "D.General",
        "Gestionnaire de stocks",
        "Facturation",
        "Magasinier",
        "Achat national",
        "comptabilité",
        "relation client",
        "parc auto"
      ],

      selectedState: "",

      zoneItems: [],
      Zone: "",

      Name: "",
      Cin: "",
      Phone: "",
      Password: "",
      CPassword: "",
      userIMG: null,

      UserVehicul: "",

      showPassword: true
    };
  },
  created() {
    axios.get(`${API_URL}/country/getAllCity`).then((response) => {
      this.zoneItems = response.data
    })
    axios.get(`${API_URL}/user/findOneUser/${this.$route.params.userId}`).then((user) => {
        this.Name = user.data.Name
        this.Cin = user.data.Cin
        this.Phone = user.data.Phone
        this.email = user.data.Email
        this.Zone = user.data.Zone
        this.IMG = user.data.imageProfile
        this.selectedState = user.data.userRole
        this.eliase = user.data.NameCode
        this.UserVehicul = user.data.UserVehicul
        this.generatedEliase = `21${this.eliase}00001`
        this.Password = user.data.Password
    })
    this.ChangeCodeClientF()
  },
  methods: {
    ChangeCodeClientF() {
      if(this.eliase.length > 2)
        this.eliase = ""
      this.generatedEliase = `21${this.eliase}00001`
    },
    redirectFun() {
      this.$router.push("/addUser");
    },
    userImgFun(e) {
      this.userIMG = e.target.files[0];
    },
    UpdateFun() {
      const formData = new FormData();
      formData.append("Name", this.Name);
      formData.append("Cin", this.Cin);
      formData.append("Phone", this.Phone);
      formData.append("Email", this.email);
      formData.append("Zone", this.Zone);
      formData.append("UserVehicul", this.UserVehicul);
      formData.append("userRole", this.selectedState);
      formData.append("imageProfile", this.userIMG);
      formData.append("Password", this.Password);
      formData.append("CPassword", this.Password);
      formData.append("NameCode", this.eliase);
      axios
      .put(`${API_URL}/user/update/${this.$route.params.userId}`, formData)
      .then(() => {
        this.Name = "";
        this.Cin = "";
        this.Phone = "";
        this.email = "";
        this.Zone = "";
        this.Password = "";
        this.$router.push({ path: `/userlists` });
      })
      .catch((e) => {
        if (e.response.data.error) {
          Vue.$toast.open({
            message: e.response.data.error,
            type: "error",
          });
        }
      });
    }
  },
};
</script>
