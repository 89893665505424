<template>
  <div>
      <div class="BalckPage" v-if="OpenClient" @click="RemoveAllPopup"></div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />
    <PopupClinet v-if="OpenClient" :PropsToDepotPopup="PropsToDepotPopup" @Close="CloseFuntion()" />

    <div class="ListClient">
        <div class="OuClientAndBtn">
            <h1>Dépôts</h1>
            <button @click="PropsToDepotPopup.Condition='New', OpenClient = true">Nouveau Dépôt</button>
        </div>
        <div class="Filter">
            <div class="FilterParCatég">
                    <h6>Filtrer par Dépôt :</h6>
                    <input type="text" placeholder="Tout" v-model="DepotFilterd" @keyup="FilterByDepot">
            </div>
            <div class="FilterParCatég">
                    <h6>Filtrer par responsable :</h6>
                    <input type="text" placeholder="Tout" v-model="ResponsableFilterd" @keyup="FilterByResponsable">
            </div>
        </div>
        <div class="HeaderTable">
                <h3>Dépôts</h3>
                <h3>Région</h3>
                <h3>Responsable</h3>
                <h3>Actions</h3>
        </div>
        <div class="Vide" v-if="VideData">
            <h5>Vide</h5>
        </div>
        <div class="TableContentZone">
            <div class="HeaderBody" v-for="Client,n in AllClientHave" :key="n" :style=" n % 2 == 0 ? 'background-color: rgba(5,139,132,0.22)' : 'background-color: rgba(180,181,181,0.22);'" >
                    <h3>{{Client.Name}}</h3>
                    <h3>{{Client.Origin}}</h3>
                    <h3>{{Client.For ? Client.For.Name : ''}}</h3>
                    <h3>
                        <svg   @click="PropsToDepotPopup.Condition='Show', PropsToDepotPopup.Id=Client._id, PropsToDepotPopup.NAME=Client.Name, OpenClient = true" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"/></svg>
                        <svg   @click="PropsToDepotPopup.Condition='Update', PropsToDepotPopup.Id=Client._id, PropsToDepotPopup.NAME=Client.Name, OpenClient = true" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
                        <svg  @click="DeleteFunction(Client._id)" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>
                    </h3>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import PopupClinet from '../components/PopupNewDepot.vue'
import Swal from 'sweetalert2'

import axios from "axios";
import API_URL from "../../config";

export default {
    components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
    PopupClinet
  },
  data() {
    return {
        OpenClient:false,
        PropsToDepotPopup:{
            Condition:"",
            Id:""
        },
        AllClientHave:[],
        AllTCP:[],
        TCPFilterd:'Tout',
        ClientFilterd:'',
        VideData:false,
        DepotFilterd: "",
        ResponsableFilterd: ""
    };
  },
  created() {
;
  },
  computed: {},
  methods: {
      RemoveAllPopup(){
          this.OpenClient = false
      },
      async GetAllClient(){
            axios.get(`${API_URL}/country/getAllCityFullD`)
            .then((response) => {
                this.AllClientHave = response.data
            })
      },
      DeleteFunction(ID){
    Swal.fire({
        position: "center-center",
        text: "Voulez-vous supprimer ce client?",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Supprimer",
      }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${API_URL}/country/deleteOneDepot/${ID}`)
            .then((response) => {
                this.GetAllClient()
            })
        }
      });
      },
    FilterByDepot() {
        if(this.DepotFilterd != ''){
            axios.get(`${API_URL}/country/getFiltredDepot/${this.DepotFilterd}`)
            .then((response) => {
                this.AllClientHave = response.data
                if(this.AllClientHave.length == 0){
                    this.VideData = true
                }
                else{
                    this.VideData = false
                }
            })
          }
          else{
              this.VideData = false
              this.GetAllClient()
          }
    },
    FilterByResponsable() {
        if(this.ResponsableFilterd != ''){
            axios.get(`${API_URL}/country/getFilterByResponsable/${this.ResponsableFilterd}`)
            .then((response) => {
                this.AllClientHave = response.data
                if(this.AllClientHave.length == 0){
                    this.VideData = true
                }
                else{
                    this.VideData = false
                }
                console.log(response);
            })
          }
          else{
              this.VideData = false
              this.GetAllClient()
          }
    },
    CloseFuntion(){
        this.OpenClient=false
        this.GetAllClient()
    }
  },
  mounted(){
       this.GetAllClient()
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #00ABC4;
.BalckPage{
    background-color:rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.ListClient{
        width: 80%;
        float: left;
        margin-top: 4%;
        ::-webkit-scrollbar {
        width: 10px;
        position: relative;
        top: 20px;
        border-radius: 30px;
    }
    /* Track */
    
     ::-webkit-scrollbar-track {
        background-color:  rgb(231, 231, 231);
        border-radius: 10px;
    }
    /* Handle */
    
     ::-webkit-scrollbar-thumb {
        background:  #b41206;
        border-radius: 10px;
    }
    .TableContentZone{
        height:500px;
        overflow-y: scroll;
    }
    .OuClientAndBtn{
        display: flex;
        justify-content: space-between;
        align-items: start;
        width: 80%;
        margin: auto;
        button{
            font-size:17px;
            font-family: $primaryFont;
            font-weight: 500;
            border-radius: 10px;
            padding:6px 30px;
            background-color: #E1E1E1;
            color:#00ABC4;
            &:hover{
                border: 1px solid $primary-background-color;
                background-color:transparent;
                color:$primary-background-color;
            }
        }
    }
    h1,h3{
        font-family:$primaryFont ;
        font-size: 40px;
        font-weight:bold;
        color:$primary-background-color;
        text-align:center;
        padding-bottom: 30px;
    }

    .HeaderTable,.HeaderBody{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: 0px auto;

        h3{
            font-size: 17px;
            border: 1px solid white;
            padding:10px 0px;
            background-color:#058B84;
            color:white;
            font-weight: 600;
            width:400px;
            line-height: 20px;
            &:first-of-type {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }
            &:last-of-type {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }
    }
    .HeaderBody{
        margin-bottom: 10px;
        border-radius: 20px;
        h3{
           font-size: 17px;
            padding:10px 0px;
            color:white;
            font-weight: 600;
            width:200px;
            overflow-wrap: break-word;
            background-color:transparent;
            border: none;
            color: #058B84;
            margin: 0px;    
        }
        svg{
            fill: #058B84;
            width:30px;
            height:30px;
            margin: 0px 10px;
            &:hover{
                fill: $primary-background-color;
                cursor: pointer;
            }
        }
    }
        .Filter{
        display:flex;
        justify-content:space-around;
        align-items:center;
        width:80%;
        margin: 1% auto;
        .FilterParCatég {
            h6 {
                color: $primaryColor;
            }
        }
        h6{
        font-family:$primaryFont ;
        font-size: 18px;
        font-weight:bold;
        color:#142048;
        padding-bottom: 5px;
        }
        select,Input{
                border: 1px solid #035a7f;
                width:100%;
                padding:6px  ;
                border-radius: 6px;
                cursor: pointer;
        }
    }
    .Vide{
        width:80%;
        margin:30px  auto;
        background-color: #fcd309;
        padding:10px 0px;
        text-align: center;
        h5{
            font-size: 20px;
            font-weight: 600;
            color:#035a7f;
        }
    }
}
</style>

