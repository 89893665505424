<template>
  <div class="PopupNewClient productsDetailsPop">
    <div style="display: flex; align-items: baseline">
      <h4 v-if="PropsToCompanyFeesPopup.Condition == 'Show'">
        <p v-if="PropsToCompanyFeesPopup.Type == 'CV'">Charges véhicules</p>
        <p v-if="PropsToCompanyFeesPopup.Type == 'FD'">Frais de déplacement</p>
        <p v-if="PropsToCompanyFeesPopup.Type == 'AT'">Frais de transport local</p>
      </h4>

      <h4 v-if="PropsToCompanyFeesPopup.Condition == 'Update'">
        <p v-if="PropsToCompanyFeesPopup.Type == 'CV'">Modifier Charges véhicules</p>
        <p v-if="PropsToCompanyFeesPopup.Type == 'FD'">Modifier Frais de déplacement</p>
        <p v-if="PropsToCompanyFeesPopup.Type == 'AT'">Modifier Frais de transport local</p>
      </h4>
    </div>

    <div class="ListClient">
      <div class="addNewFeesForm">
        <div class="feesForm" v-if="PropsToCompanyFeesPopup.Type == 'CV'">
          <div class="colOne">
            <div class="FilterParCatég">
              <h6>Détails :</h6>
              <select v-model="Details">
                <option value="Charges véhicules">Charges véhicules</option>
                <option value="Maintenance">Maintenance</option>
                <option value="Gasoil">Gasoil</option>
                <option value="Jawaz">Jawaz</option>
              </select>
            </div>

            <div class="FilterParCatég">
              <h6>Date :</h6>
              <input type="date" placeholder="Date" v-model="CurrentDate" />
            </div>

            <div class="FilterParCatég">
              <h6>Responsable :</h6>
              <select v-model="Responsable" @change="getVehiculName">
                <option :value="listOfResponsable._id" v-for="(listOfResponsable, index) in listOfResponsables" :key="index">{{ listOfResponsable.Name }}</option>
              </select>
            </div>

            <div class="FilterParCatég">
              <h6>Véhicule :</h6>
              <input type="text" placeholder="Nom de vehicul" v-model="Vehicul" disabled />
            </div>

            <div class="FilterParCatég">
              <h6>Montant :</h6>
              <input type="number" placeholder="Montant" v-model="Montant" />
            </div>
          </div>
          <div class="colTwo">
            <div class="FilterParCatég">
              <h6>Description :</h6>
              <textarea placeholder="Description" rows="5" v-model="Description"></textarea>
            </div>

            <v-radio-group v-model="isJustify">
              <v-radio label="Justifier" value="true"></v-radio>
              <v-radio label="Non Justifier" value="false"></v-radio>
            </v-radio-group>

            <v-file-input
                @change="userFileFunc($event)"
                label="Télécharger le document"
            ></v-file-input>

            <v-btn v-if="PropsToCompanyFeesPopup.Condition == 'Show'" @click="addNewCompanyFees('CV')" depressed>
                Ajouter
            </v-btn>

            <v-btn v-if="PropsToCompanyFeesPopup.Condition == 'Update'" @click="updateCompanyFees('CV')" depressed>
              Modifier
            </v-btn>
          </div>
        </div>

        <div class="feesForm" v-if="PropsToCompanyFeesPopup.Type == 'FD'">
            <div class="colOne">
                <div class="FilterParCatég">
                    <h6>Détails :</h6>
                    <select v-model="Details">
                      <option value="Hôtel">Hôtel</option>
                      <option value="Restaurant">Restaurant</option>
                    </select>
                </div>

                <div class="FilterParCatég">
                    <h6>Date :</h6>
                    <input type="date" placeholder="Date" v-model="CurrentDate" />
                </div>

            <div class="FilterParCatég">
              <h6>Responsable :</h6>
              <select v-model="Responsable">
                <option :value="listOfResponsable._id" v-for="(listOfResponsable, index) in listOfResponsables" :key="index">{{ listOfResponsable.Name }}</option>
              </select>
            </div>

                <div class="FilterParCatég">
                    <h6>Montant :</h6>
                    <input type="number" placeholder="Montant" v-model="Montant" />
                </div>
            </div>
            <div class="colTwo">
                <div class="FilterParCatég">
                    <h6>Description :</h6>
                    <textarea placeholder="Description" rows="5" v-model="Description"></textarea>
                </div>

            <v-radio-group v-model="isJustify">
              <v-radio label="Justifier" value="true"></v-radio>
              <v-radio label="Non Justifier" value="false"></v-radio>
            </v-radio-group>

                <v-file-input
                    @change="userFileFunc($event)"
                    label="Télécharger le document"
                ></v-file-input>

                <v-btn v-if="PropsToCompanyFeesPopup.Condition == 'Show'" @click="addNewCompanyFees('FD')" depressed>
                    Ajouter
                </v-btn>

                <v-btn v-if="PropsToCompanyFeesPopup.Condition == 'Update'" @click="updateCompanyFees('FD')" depressed>
                  Modifier
                </v-btn>
            </div>
        </div>

        <div class="feesForm" v-if="PropsToCompanyFeesPopup.Type == 'AT'">
          <div class="colOne">
            <div class="FilterParCatég">
              <h6>Date :</h6>
              <input type="date" placeholder="Date" v-model="CurrentDate" />
            </div>

            <div class="FilterParCatég">
              <h6>Responsable :</h6>
              <select v-model="Responsable">
                <option :value="listOfResponsable._id" v-for="(listOfResponsable, index) in listOfResponsables" :key="index">{{ listOfResponsable.Name }}</option>
              </select>
            </div>

            <div class="FilterParCatég">
              <h6>Montant :</h6>
              <input type="Number" placeholder="Montant" v-model="Montant" />
            </div>

            <div class="FilterParCatég">
              <h6>Point de départ :</h6>
              <select v-model="StartedPos">
                <option :value="listOfCitie.ville" v-for="(listOfCitie, index) in listOfCities" :key="index">{{ listOfCitie.ville }}</option>
              </select>

              <v-btn
                    color="success"
                    @click="showNewCityForm = !showNewCityForm"
                    fab
                    small
                    dark
                    class="addBtn"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>

                <template v-if="showNewCityForm">
                    <v-form @submit.prevent>
                      <v-text-field
                        v-model="newCityName"
                        label="ville"
                      ></v-text-field>
                      <v-btn type="submit" block class="mt-2" @click="addNewCity">Ajouter</v-btn>
                    </v-form>
                </template>

            </div>

            <div class="FilterParCatég">
              <h6> Point d'arrivée :</h6>
              <select v-model="EndPos">
                <option :value="listOfCitie.ville" v-for="(listOfCitie, index) in listOfCities" :key="index">{{ listOfCitie.ville }}</option>
              </select>
            </div>
          </div>
          <div class="colTwo">
            <div class="FilterParCatég">
              <h6>Description :</h6>
              <textarea placeholder="Description" rows="5" v-model="Description"></textarea>
            </div>

            <v-radio-group v-model="isJustify">
              <v-radio label="Justifier" value="true"></v-radio>
              <v-radio label="Non Justifier" value="false"></v-radio>
            </v-radio-group>

            <v-file-input
                @change="userFileFunc($event)"
                label="Télécharger le document"
            ></v-file-input>

            <v-btn v-if="PropsToCompanyFeesPopup.Condition == 'Show'" @click="addNewCompanyFees('TL')" depressed>
                Ajouter
            </v-btn>

            <v-btn v-if="PropsToCompanyFeesPopup.Condition == 'Update'" @click="updateCompanyFees('TL')" depressed>
                Modifier
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../config";
import SOCKETS_URL from "../../../configSocketsURL";
import html2pdf from "html2pdf.js";

export default {
  emit: ["Close"],
  props: ["PropsToCompanyFeesPopup"],
  components: {},
  data() {
    return {
      // CurrentDate: new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate(),
      CurrentDate: "",
      Details: "",
      Vehicul: "",
      Responsable: "",
      Montant: 0,
      Description: "",
      BonFile: null,
      StartedPos: "",
      EndPos: "",
      isJustify: "false",

      listOfResponsables: null,
      listOfCities: null,

      dialog: false,

      showNewCityForm: false,
      newCityName: ""
    };
  },
  created() {
    this.getListOfResponsables(),
    this.getListOfCities(),
    this.getOneCompanyFeeData()
  },
  computed: {},
  methods: {
    async fetchFileContents(url) {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch file');
      }
      return await response.blob();
    },

    async getOneCompanyFeeData() {
      if(this.PropsToCompanyFeesPopup.Condition == 'Update') {
        this.Montant = this.PropsToCompanyFeesPopup.Data.montant
        this.CurrentDate = this.PropsToCompanyFeesPopup.Data.date
        this.Details = this.PropsToCompanyFeesPopup.Data.details
        this.Responsable = this.PropsToCompanyFeesPopup.Data.responsable._id
        this.Vehicul = this.PropsToCompanyFeesPopup.Data.responsable.UserVehicul
        this.Description = this.PropsToCompanyFeesPopup.Data.description
        this.isJustify = (this.PropsToCompanyFeesPopup.Data.isJustify).toString()
        this.StartedPos = this.PropsToCompanyFeesPopup.Data.startedPoint
        this.EndPos = this.PropsToCompanyFeesPopup.Data.endPoint

        const blobFile = await this.fetchFileContents(`${SOCKETS_URL}/${this.PropsToCompanyFeesPopup.Data.bonFile}`);
        this.BonFile = blobFile
      }
    },
    getListOfCities() {
      axios.get(`${API_URL}/country/getAllCity2/`).
      then(response => {
        this.listOfCities = response.data
      })
    },
    addNewCity() {
      const formData = {
        "ville": this.newCityName
      }
      axios.post(`${API_URL}/country/addCity2/`, formData).
      then(async() => {
        await this.$swal("cette ville a été ajouté!", "", "success");
        await this.getListOfCities()

        this.showNewCityForm = false
        this.this.newCityName = ""
      })
      .catch(err => {
        this.$swal(err.response.data.error, "", "error");
      })
    },
    getListOfResponsables() {
      axios.get(`${API_URL}/user/getUserWithVehicul/`).
      then(response => {
        this.listOfResponsables = response.data
      })
    },
    getVehiculName() {
      this.Vehicul = this.listOfResponsables.find(el => el._id == this.Responsable).UserVehicul
    },
    userFileFunc(e) {
      this.BonFile = e;
    },
    updateCompanyFees(typeOfOp) {
      const formData = new FormData()
      formData.append("date", this.CurrentDate)
      formData.append("responsable", this.Responsable)
      formData.append("montant", this.Montant)
      formData.append("description", this.Description)
      formData.append("bonFile", this.BonFile)

      formData.append("typeOfOp", typeOfOp)
      formData.append("isJustify", this.isJustify == "true" ? true : false )

      if(typeOfOp == "CV" || typeOfOp == "FD")
        formData.append("details", this.Details)

      if(typeOfOp == "CV")
        formData.append("vehicule", this.Vehicul)

      if(typeOfOp == "TL") {
        formData.append("startedPoint", this.StartedPos)
        formData.append("endPoint", this.EndPos)
      }

      axios.put(`${API_URL}/companyFees/updateCompanyFeeData/${this.PropsToCompanyFeesPopup.Data._id}`, formData)
      .then(() => {
        this.$swal("cette operation a été modifée!", "", "success");
        this.$emit("Close")
      })
      .catch(err => {
        this.$swal(err.response.data.error, "", "error");
      })
    },
    addNewCompanyFees(typeOfOp) {
      const formData = new FormData()
      formData.append("date", this.CurrentDate)
      formData.append("responsable", this.Responsable)
      formData.append("montant", this.Montant)
      formData.append("description", this.Description)
      formData.append("bonFile", this.BonFile)
      formData.append("typeOfOp", typeOfOp)
      formData.append("isJustify", this.isJustify == "true" ? true : false )

      if(typeOfOp == "CV" || typeOfOp == "FD")
        formData.append("details", this.Details)

      if(typeOfOp == "CV")
        formData.append("vehicule", this.Vehicul)

      if(typeOfOp == "TL") {
        formData.append("startedPoint", this.StartedPos)
        formData.append("endPoint", this.EndPos)
      }

      axios.post(`${API_URL}/companyFees/`, formData).
      then(() => {
        this.$swal("cette operation a été ajouté!", "", "success");
        this.$emit("Close")
      })
      .catch(err => {
        this.$swal(err.response.data.error, "", "error");
      })

      // console.log(this.Details, this.Date, this.Vehicul, this.Responsable, this.Montant, this.Description, this.BonFile)
    }
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.ZoneTable {
  margin-bottom: 40px;
  p {
    color: #058b84;
  }
}
table {
  width: 100%;
  th {
    background-color: #058b84;
    font-size: 20px;
    color: white;
    padding: 10px 0px;
    text-align: center;
    width: 10%;
  }
  td {
    font-size: 20px;
    color: #058b84;
    padding: 10px 0px;
    border: 1px solid #058b84;
    font-weight: bold;
    text-align: center;
  }
}

.productsDetailsPop {
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .sumary {
    font-weight: bold;
    color: #757575;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    h5 {
      display: flex;
      span {
        width: 200px;
      }
    }
  }
  .myCard {
    margin-bottom: 20px;
  }
  .productsTitle {
    font-size: 25px;
  }
  .productsDetails {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    span {
      font-weight: bold;
      font-size: 15px;
      margin-right: 30px;
      width: 100px;
      color: black;
    }
  }
}

.PopupNewClient {
  position: absolute;
  width: 80%;
  padding: 40px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 11px -4px black;
  left: 10%;
  top: 10%;
  z-index: 2;
  overflow: auto;
  height: 80%;
  h4 {
    font-family: $primaryFont;
    font-size: 30px;
    font-weight: bold;
    color: $primary-background-color;
    padding-bottom: 30px;
  }
  .Form {
    display: flex;
    align-items: start;
    justify-content: space-between;
    .LeftInputs,
    .RightInputs {
      width: 100%;
      .InputZone {
        margin: 20px 0px;
        h6 {
          font-family: $primaryFont;
          font-size: 20px;
          font-weight: 700;
          color: #142048;
        }
      }
      .InputFaild {
        border: 1px solid #035a7f;
        width: 90%;
        padding: 6px;
        border-radius: 6px;
        display: flex;
        svg {
          margin-right: 5px;
        }
        input {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
  .TCP {
    margin-bottom: 20px;
    h6 {
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048;
    }

    select {
      border: 1px solid #035a7f;
      width: 95%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .BtnPopUp {
    width: 95%;
    text-align: end;
  }
  .Personne {
    display: flex;
    align-items: center;
    p {
      margin-right: 50px;
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048 !important;
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #142048;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @media screen and (max-width: 1440px) {
    h4 {
      font-size: 20px;
      padding-bottom: 10px !important;
    }
    h6 {
      font-size: 15px !important;
    }
    p {
      font-size: 15px !important;
    }
    input,
    select,
    textarea {
      padding: 3px !important;
    }
    .InputZone {
      padding: 3px;
      margin: 10px 0px !important;
    }
  }
}

.ListClient {
  width: 80%;
  float: left;
  margin-top: 1%;
  ::-webkit-scrollbar {
    width: 10px;
    position: relative;
    top: 20px;
    border-radius: 30px;
  }
  /* Track */

  ::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b41206;
    border-radius: 10px;
  }
  .TableContentZone {
    height: 500px;
    overflow-y: scroll;
  }
  .OuClientAndBtn {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 80%;
    margin: auto;
    button {
      font-size: 17px;
      font-family: $primaryFont;
      font-weight: 500;
      border-radius: 10px;
      padding: 6px 30px;
      background-color: #e1e1e1;
      color: #00abc4;
      &:hover {
        border: 1px solid $primary-background-color;
        background-color: transparent;
        color: $primary-background-color;
      }
    }
  }
  h1,
  h3 {
    font-family: $primaryFont;
    font-size: 40px;
    font-weight: bold;
    color: $primary-background-color;
    text-align: center;
    padding-bottom: 30px;
  }

  .HeaderTable,
  .HeaderBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0px auto;

    h3 {
      font-size: 17px;
      border: 1px solid white;
      padding: 10px 0px;
      background-color: #058b84;
      color: white;
      font-weight: 600;
      width: 400px;
      line-height: 20px;
      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
  .HeaderBody {
    margin-bottom: 10px;
    border-radius: 20px;
    h3 {
      font-size: 17px;
      padding: 10px 0px;
      color: white;
      font-weight: 600;
      width: 200px;
      overflow-wrap: break-word;
      background-color: transparent;
      border: none;
      color: #058b84;
      margin: 0px;
    }
    svg {
      fill: #058b84;
      width: 30px;
      height: 30px;
      margin: 0px 10px;
      &:hover {
        fill: $primary-background-color;
        cursor: pointer;
      }
    }
  }
  .addNewFeesForm {
    .feesForm {
      width: 100%;
      display: flex;
      .colOne {
        width: 50%;
      }
      .colTwo {
        width: 50%;
      }
      .FilterParCatég {
        width: 50%;
        margin-bottom: 2%;
        .addBtn {
          margin-left: 40%;
          margin-top: 2%;
        }
        h6 {
          color: $primaryColor;
        }
      }
      h6 {
        font-family: $primaryFont;
        font-size: 18px;
        font-weight: bold;
        color: #142048;
        padding-bottom: 5px;
      }
      select,
      Input,
      textarea {
        border: 1px solid #035a7f;
        width: 100%;
        padding: 6px;
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }
  .Vide {
    width: 80%;
    margin: 30px auto;
    background-color: #fcd309;
    padding: 10px 0px;
    text-align: center;
    h5 {
      font-size: 20px;
      font-weight: 600;
      color: #035a7f;
    }
  }
}
</style>
