<template>
    <div class="PopupNewClient">
            <h4 v-if="PropsToProductPopup.Condition == 'New'">Nouveau produit</h4>
            <h4 v-else-if="PropsToProductPopup.Condition == 'Update'">Mettre à jour un produit</h4>
            <h4 v-else-if="PropsToProductPopup.Condition == 'Show'">Aperçu du produit</h4>

            <div class="TCP">
                    <div class="InputZone">
                            <h6>Catégorie</h6>
                                <select v-model="CategoryChose" 
                                :disabled="PropsToProductPopup.Condition == 'Show'"
                                 :style="CategoryChose === 'Séléctioner une catégorie'
                                        && SubmitForm === true  
                                        && NewCatégory === ''
                                        ? 'border: 2px solid red' : ''"
                                >
                                    <option value="Séléctioner une catégorie">Séléctioner une catégorie</option>
                                    <option  v-for="Categ,n in Cattegory" :key="n" :value="Categ">{{Categ}}</option>
                                </select>
                    </div>
                    <!-- <span @click="AddNewCatég = true"  v-if="PropsToProductPopup.Condition == 'New'"> + Autre Catégorie</span> -->
            </div>
            <div class="NewCateg"  v-if="AddNewCatég">
                    <input type="text" Placeholder="Nouvelle Catégorie" v-model="NewCatégory">
            </div>
            <div class="Form">
                <div class="LeftInputs">
                    <div class="InputZone">
                        <h6>Code du produit</h6>
                        <div class="InputFaild" :style="CodeProduit === '' && SubmitForm  === true ?  'border: 2px solid red' : ''">
                              <input type="number" placeholder="Code du produit" v-model="CodeProduit"
                              :disabled="PropsToProductPopup.Condition == 'Show'"
                              >
                        </div>
                    </div>
                    <div class="InputZone">
                            <h6>Unité</h6>
                            <select v-model="UnitéChose"
                            :style="
                            UnitéChose === 'Select un unité' 
                            && SubmitForm  === true
                            && NewUnité === ''
                            ?  'border: 2px solid red' : ''"
                            :disabled="PropsToProductPopup.Condition == 'Show'"
                            >
                                    <option value="Select un unité">Séléctioner l'unité</option>
                                    <option  v-for="Uni,n in Unité" :key='n' :value="Uni">{{Uni}}</option>
                            </select>
                            <div class="AutreUnite"  style="display:block" v-if="PropsToProductPopup.Condition == 'New'">
                                    <span @click='AutreUnité = true' >+ Autre Unité</span>
                                    <input type="text" placeholder="Nouvelle unité" v-if="AutreUnité" v-model="NewUnité">
                            </div>
                    </div>
                   <div class="InputZone">
                            <h6>Prix HT : en DH</h6>
                            <div class="InputFaild"  :style="PriceHT === ''  && SubmitForm  === true ?  'border: 2px solid red' : ''">
                                <input type="number"  v-model="PriceHT" @keyup="CalculePrixTTC()" @change="CalculePrixTTC()" :disabled="PropsToProductPopup.Condition == 'Show'">
                        </div>
                    </div>
                    <div class="InputZone">
                            <h6>Prix TTC</h6>
                            <div class="InputFaild">
                            <input type="number"  disabled v-model="PriceTTC"  style="cursor:no-drop" >
                            </div>
                            <span v-if="UnitéChose != 'Select un unité'">{{PriceTTC}} dh par {{UnitéChose}}</span>
                    </div>

                    <div class="InputZone">
                            <h6>Code comptable de produit</h6>
                            <div class="InputFaild">
                            <input type="text" placeholder="Code comptable de produit" v-model="CodeProduitComptable" :disabled="PropsToProductPopup.Condition == 'Show'">
                            </div>
                    </div>
                </div>
                <div class="RightInputs">
                    <div class="InputZone">
                            <h6>Produit</h6>
                                <div class="InputFaild"  :style="Produit === '' && SubmitForm  === true ?  'border: 2px solid red' : ''">
                                <input type="text" placeholder="Produit" v-model="Produit" :disabled="PropsToProductPopup.Condition == 'Show'">
                                </div>
                    </div>
                    <div class="InputZone">
                            <h6>Emballage</h6>
                                <div class="InputFaild"   :style="(Emballage === '' || Emballage === 0) && SubmitForm  === true ?  'border: 2px solid red' : ''">
                                <input type="number" placeholder="Emballage" v-model="Emballage" :disabled="PropsToProductPopup.Condition == 'Show'">
                                </div>
                                <span v-if="Emballage !== '' && Emballage !== 0 && UnitéChose !== '' && UnitéChose !== 'Select un unité' ">{{Emballage}} {{UnitéChose}}</span>
                            </div>
                     <div class="InputZone">
                        <h6>TVA %</h6>
                        <div class="InputFaild">
                            <input type="number" placeholder="TVA %" v-model="Tva"  @keyup="CalculePrixTTC()" @change="CalculePrixTTC()" :disabled="PropsToProductPopup.Condition == 'Show'">
                        </div>
                    </div>
                    <div class="InputZone">
                            <h6>Remise %</h6>
                            <div class="InputFaild">
                            <input type="number" placeholder="Remise %" v-model="Remise" :disabled="PropsToProductPopup.Condition == 'Show'">
                        </div>
                    </div>
                    <div class="InputZone">
                            <h6>Date experation</h6>
                            <div class="InputFaild">
                            <input type="date" placeholder="Date experation" v-model="ExperationDate" :disabled="PropsToProductPopup.Condition == 'Show'">
                        </div>
                    </div>
                    <div class="InputZone">
                            <h6>Numéro de lot</h6>
                            <div class="InputFaild">
                            <input type="text" placeholder="Numéro de lot" v-model="LotNumber" :disabled="PropsToProductPopup.Condition == 'Show'">
                        </div>
                    </div>
                </div>
            </div>
            <h6>Dépôt</h6>
            <div class="LesDepot">
                <div class="SingleDépot" v-for="Dép,n in Dépot" :key="n">
                    <h6>{{Dép.City}}</h6>
                    <input type="number" v-model="Dép.Disponible" disabled>

                    <button class="minusBtn" @click="showEditeStockInput(n, 'M')" v-if="PropsToProductPopup.Condition == 'Update'">
                        <v-icon dark>
                            mdi-minus
                        </v-icon>
                    </button>

                    <button class="plusBtn" @click="showEditeStockInput(n, 'P')" v-if="PropsToProductPopup.Condition == 'Update'">
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                    </button>

                    <div v-if="showEditeInpute && addOrMinus == 'M' && formIndex == n">
                        <input type="number" v-model="quantityAdded">

                        <v-progress-circular
                            :size="20"
                            :width="5"
                            color="purple"
                            indeterminate
                            v-if="ActiveSpinner"
                        ></v-progress-circular>

                        <button class="minusBtn" @click="minusAddFromStockFunc(Produit, Dép.City, Dép.Disponible, 'S')" v-if="!ActiveSpinner">
                            <v-icon dark>
                                mdi-minus
                            </v-icon>
                        </button>
                    </div>
                    <div v-if="showEditeInpute && addOrMinus == 'P' && formIndex == n">
                        <input type="number" v-model="quantityAdded">

                        <v-progress-circular
                            :size="20"
                            :width="5"
                            color="purple"
                            indeterminate
                            v-if="ActiveSpinner"
                        ></v-progress-circular>

                        <button class="plusBtn" @click="minusAddFromStockFunc(Produit, Dép.City, Dép.Disponible, 'E')" v-if="!ActiveSpinner">
                            <v-icon dark>
                                mdi-plus
                            </v-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="AutreDepot" v-if="PropsToProductPopup.Condition == 'New'">
                    <span @click="NewDepotOpen = true">+ Autre dépôt</span>
                    <div class="ZoneAddDépot">
                        <input type="text" placeholder="Nouveau dépot" v-if="NewDepotOpen" v-model="NewDepot.Name">
                        <button v-if="NewDepotOpen" @click="NewDépot()">Valider dépot</button>
                    </div>
            </div>
            <div class="BtnPopUp">
            <button v-if="PropsToProductPopup.Condition == 'New'" @click="AddNewProduct()">Valider</button>
            <button v-else-if="PropsToProductPopup.Condition == 'Update'"  @click="UpdateProduct()">Mettre à jour</button>

        </div>
    </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";

import axios from "axios";
import API_URL from "../../config";
import Vue from "vue";

import Swal from 'sweetalert2'

export default {
    emit:['close'],
    props:['PropsToProductPopup'],
    components: {
        IncludedNavBar,
        LeftSideNav,
        LogOut,
  },
  data() {
    return {
        SubmitForm : false,
        NewTCP : false,
        AddNewCatég:false,
        PriceTTC:0,
        AutreUnité:false,
        Cattegory:[],
        CategoryChose:'Séléctioner une catégorie',
        NewCatégory:'',
        CodeProduit:'',
        Produit:'',
        Unité:['KG','L','Unit'],
        UnitéChose:'Select un unité',
        NewUnité:'',
        PriceHT:0,
        Tva:0,
        Remise:0,
        Emballage:0,
        NewDepot:{
            Name:'',
        },
        NewDepotOpen:false,
        Dépot:[],

        showEditeInpute: false,
        addOrMinus: "",
        formIndex: 0,
        quantityAdded: 0,
        sumOfProductAtStock: 0,

        ExperationDate: "",
        LotNumber: "",

        ActiveSpinner: false
    };
  },
  created() {},
  computed: {},
  methods: {
    minusAddFromStockFunc(productName, Depot, disponibleByDepot, type) {
        this.ActiveSpinner = true
        let FocDisponible = parseFloat(this.Dépot.filter(el => el.City == "FOC")[0].Disponible)
        this.quantityAdded = parseFloat(this.quantityAdded)

        let restEnStockByDepot = type == "S" ? (parseFloat(disponibleByDepot) - parseFloat(this.quantityAdded)) : (parseFloat(disponibleByDepot) + parseFloat(this.quantityAdded))

        let Object = {
            checkData: {
                quantityDisponibleByDepot: disponibleByDepot,
                quantityAdded: this.quantityAdded
            },
            Category: this.CategoryChose,
            Produit: productName,
            Quantity: disponibleByDepot,
            Depot: Depot,
            restEnStock: type == "S" ? this.sumOfProductAtStock - this.quantityAdded : this.sumOfProductAtStock + this.quantityAdded,
            restEnStockByDepot: restEnStockByDepot,
            selectedDepotDetails: {
                    productName: productName,
                    city: Depot,
                    unity: this.UnitéChose,
                    quantity: this.quantityAdded,
                    Code: this.CodeProduit
            },
            restEnStockForThisProduts: {
                product: productName,
                category: this.CategoryChose,
                restOneStock: this.sumOfProductAtStock
            },
            type: type
        }

        let productObject = {
            selectedProducts: [
                {
                    Name: productName,
                    City: Depot,
                    quantity: this.quantityAdded
                }
            ]
        }
        axios.all([
            axios.post(`${API_URL}/stock/addStockEnterDepotByProduct`, Object),
            axios.post(`${API_URL}/rapport/clearRedisData?keys=["ProductByDepotDpP"]`),
            axios.get(`${API_URL}/rapport/getAllFProductByDepot?selectedDepot=Tout&responsableOP=Tout&Type=Tout&MO=Tout`),
            type == "S" ? axios.post(`${API_URL}/Stock/updateStock`, productObject) : axios.post(`${API_URL}/Stock/updateStockAvoir`, productObject)
        ])
        .then(axios.spread((...response) => {
            Swal.fire('Modifiée', '','success')
            this.$emit('close')
        }))
        .catch(err => {
            if(err.response.data.error)
                Swal.fire(err.response.data.error, '','error')
        })
        .finally(() => {
            this.ActiveSpinner = false
        })
    },
    showEditeStockInput(formIndex, addOrMinus) {
        this.showEditeInpute = true
        this.formIndex = formIndex
        this.addOrMinus = addOrMinus
        this.quantityAdded = 0
    },
      GetConditionProps(){
          console.log(this.PropsToProductPopup)
      },
      CalculePrixTTC(){
          if(this.PriceHT !== "" && this.PriceHT !== 0){
                    let PrixWithoutRemise =  parseFloat(this.PriceHT) + parseFloat(this.PriceHT * (this.Tva/100))
                    this.PriceTTC = parseFloat(PrixWithoutRemise).toFixed(2)
          }
          else{
              this.PriceTTC = 0
          }
      },
      GetAllCategoryHave(){
                axios.get(`${API_URL}/products/GetAllProductsCategory`).then((response) => {
                    this.Cattegory = response.data
                    this.Dépot.map(el => this.sumOfProductAtStock += parseFloat(el.Disponible))
            });
      },
      NewDépot(){
            this.Dépot = []
            axios.post(`${API_URL}/country/addCity`,this.NewDepot)
                .then((response) => {
                        axios.get(`${API_URL}/country/getAllCity`).then((response) => {
                            response.data.forEach(element => {
                                this.Dépot.push({
                                            City:element,
                                            Disponible:0
                                        }
                                    )
                            });
                                console.log(response)
                        });
                })
      },
      AddNewProduct(){
            this.SubmitForm = true
          if(
                this.CodeProduit !== ''
                && this.PriceHT !== ''
                && this.Produit !== ''
                && this.Emballage !== '' && this.Emballage !== 0
          ){
            
            if(this.CategoryChose  === 'Séléctioner une catégorie' && this.NewCatégory  === ''){
                Vue.$toast.open({
                    message:"les données saisies sont incorrectes",
                    type: "error"
                });
            }
            else if(this.CategoryChose  !== 'Séléctioner une catégorie' && this.NewCatégory  === '')
            {
                let unité = ''
                if(this.UnitéChose == 'Select un unité' && this.NewUnité == ''){
                        Vue.$toast.open({
                            message:"les données saisies sont incorrectes",
                            type: "error"
                        });
                }
                else if(this.UnitéChose != 'Select un unité' && this.NewUnité == ''){
                        unité = this.UnitéChose
                        let formData = {
                            Code : this.CodeProduit, ///
                            Price : this.PriceHT, ////
                            ProductName : this.Produit , ////
                            Packaging : this.Emballage, ////
                            Unity : unité, ////
                            Stock: this.Dépot, /////
                            TVA : this.Tva ,////
                            PriceTTC : this.PriceTTC ,/////
                            Remise : this.Remise , /////
                            CodeProduitComptable: this.CodeProduitComptable,

                            ExperationDate: this.ExperationDate,
                            LotNumber: this.LotNumber
                        }
                            axios.put(`${API_URL}/products/addProducts/${this.CategoryChose}`,formData)
                            .then((response) => {
                                console.log(response);
                                        Vue.$toast.open({
                                            message:"Validé",
                                            type: "success"
                                        });
                                        this.$emit('close')
                            })
                            .catch((err) => {
                                console.log(err.response.data);
                                        Vue.$toast.open({
                                            message:err.response.data.error,
                                            type: "error"
                                        });
                            })
                            console.log(this.CategoryChose)
                }
                else if(this.NewUnité !== ''){
                    unité = this.NewUnité
                        let formData = {
                            Code : this.CodeProduit, ///
                            Price : this.PriceHT, ////
                            ProductName : this.Produit , ////
                            Packaging : this.Emballage, ////
                            Unity : unité, ////
                            Stock: this.Dépot, /////
                            TVA : this.Tva ,////
                            PriceTTC : this.PriceTTC ,/////
                            Remise : this.Remise , /////
                            CodeProduitComptable: this.CodeProduitComptable,

                            ExperationDate: this.ExperationDate,
                            LotNumber: this.LotNumber
                        }
                            axios.put(`${API_URL}/products/addProducts/${this.CategoryChose}`,formData)
                            .then((response) => {
                                console.log(response);
                                        Vue.$toast.open({
                                            message:"Validé",
                                            type: "success"
                                        });
                                        this.$emit('close')
                            })
                            .catch((err) => {
                                console.log(err.response.data);
                                        Vue.$toast.open({
                                            message:err.response.data.error,
                                            type: "error"
                                        });

                            })
                            console.log(this.CategoryChose)
                }
            }
            else if(this.NewCatégory  !== ''){
                 let unité = ''
                if(this.UnitéChose == 'Select un unité' && this.NewUnité == ''){
                        Vue.$toast.open({
                            message:"les données saisies sont incorrectes",
                            type: "error"
                        });
                }
                else if(this.UnitéChose != 'Select un unité' && this.NewUnité == ''){
                        unité = this.UnitéChose
                        let NewId = ''
                        axios.get(`${API_URL}/products/getMaxProductIdValue`)
                        .then((response) =>{
                            NewId = response.data
                            let formData = {
                           "title": this.NewCatégory ,
                            "data":[
                                {
                                    Code : this.CodeProduit, ///
                                    Categorie:this.NewCatégory,
                                    Price : this.PriceHT, ////
                                    Name : this.Produit , ////
                                    Packaging : this.Emballage, ////
                                    Unity : unité, ////
                                    Stock: this.Dépot, /////
                                    TVA : this.Tva ,////
                                    PriceTTC : this.PriceTTC ,/////
                                    Remise : this.Remise , /////
                                    id:NewId,
                                    CodeProduitComptable: this.CodeProduitComptable,
                                    ExperationDate: this.ExperationDate,
                                    LotNumber: this.LotNumber
                                }
                            ]
                        }
                            console.log(formData)
                            axios.post(`${API_URL}/products/ADD`,formData)
                            .then((response) => {
                                console.log(response);
                                        Vue.$toast.open({
                                            message:"Validé",
                                            type: "success"
                                        });
                                        this.$emit('close')
                            })
                            .catch((err) => {
                                console.log(err.response.data);
                                        Vue.$toast.open({
                                            message:err.response.data.error,
                                            type: "error"
                                        });

                            })
                            
                            console.log(this.CategoryChose)
                        })

                }
                else if(this.NewUnité !== ''){
                    
                    unité = this.NewUnité
                        let NewId = ''
                        axios.get(`${API_URL}/products/getMaxProductIdValue`)
                        .then((response) =>{
                            NewId = response.data
                                                    let formData = {
                           "title": this.NewCatégory ,
                            "data":[
                                {
                                    Code : this.CodeProduit, ///
                                    Categorie:this.NewCatégory,
                                    Price : this.PriceHT, ////
                                    Name : this.Produit , ////
                                    Packaging : this.Emballage, ////
                                    Unity : unité, ////
                                    Stock: this.Dépot, /////
                                    TVA : this.Tva ,////
                                    PriceTTC : this.PriceTTC ,/////
                                    Remise : this.Remise , /////
                                    id:NewId,
                                    CodeProduitComptable: this.CodeProduitComptable,

                                    ExperationDate: this.ExperationDate,
                                    LotNumber: this.LotNumber
                                }
                            ]
                        }
                            axios.post(`${API_URL}/products/ADD`,formData)
                            .then((response) => {
                                console.log(response);
                                        Vue.$toast.open({
                                            message:"Validé",
                                            type: "success"
                                        });
                                        this.$emit('close')
                            })
                            .catch((err) => {
                                console.log(err.response.data);
                                        Vue.$toast.open({
                                            message:err.response.data.error,
                                            type: "error"
                                        });

                            })
                            
                            console.log(this.CategoryChose)
                        })

                }
            }
          }
          else{
              console.log("Somthing is wrong")
                            Vue.$toast.open({
                                message:"les données saisies sont incorrectes",
                                type: "error"
                            });
          }
      },

      UpdateProduct(){
            this.SubmitForm = true
            this.NewCatégory != '' ? this.CategoryChose = this.NewCatégory : ''
            this.NewUnité != '' ? this.UnitéChose = this.NewUnité: ''
          if(
                this.CategoryChose  !== 'Séléctioner une catégorie'
                && this.CodeProduit !== ''
                && this.PriceHT !== ''
                && this.Produit !== ''
                && this.Emballage !== '' && this.Emballage !== 0
                && this.UnitéChose !== 'Select un unité'
          ){
              let formData = {
                Code : this.CodeProduit, ///
                Price : this.PriceHT, ////
                ProductName : this.Produit , ////
                Packaging : this.Emballage, ////
                Unity : this.UnitéChose, ////
                Stock: this.Dépot, /////
                TVA : this.Tva ,////
                PriceTTC : this.PriceTTC ,/////
                Remise : this.Remise , /////
                CodeProduitComptable: this.CodeProduitComptable,
                ExperationDate: this.ExperationDate,
                LotNumber: this.LotNumber
              }
                axios.put(`${API_URL}/products/modifyProducts/${this.CategoryChose}/${this.PropsToProductPopup.Id}`,formData)
                .then((response) => {
                    console.log(response);
                            Vue.$toast.open({
                                message:"Validé",
                                type: "success"
                            });
                            this.$emit('close')
                })
                .catch((err) => {
                       console.log(err.response.data);
                            Vue.$toast.open({
                                message:err.response.data.error,
                                type: "error"
                            });
                })
                console.log(this.CategoryChose)
          }
          else{
              console.log("Somthing is wrong")
                            Vue.$toast.open({
                                message:"les données saisies sont incorrectes",
                                type: "error"
                            });
          }
      },
      GetDataByDefault(){
          if(this.PropsToProductPopup.Condition != "New"){
                axios.get(`${API_URL}/products/GetSingleProductDetails/${this.PropsToProductPopup.Catgégory}/${this.PropsToProductPopup.Id}`)
                .then((response) => {
                    this.Unité.includes(response.data.Unity) ? '' : this.Unité.push(response.data.Unity)
                    this.CategoryChose = response.data.Categorie
                    this.CodeProduit = response.data.Code
                    this.Emballage = response.data.Packaging
                    this.UnitéChose = response.data.Unity
                    this.Tva = response.data.HaveTVA
                    this.PriceHT = response.data.Price
                    this.Remise = response.data.Remise
                    this.PriceTTC = parseFloat(response.data.PriceTTC).toFixed(2)
                    this.Dépot = response.data.Stock.sort((a, b) => a.City.localeCompare(b.City))
                    this.Produit = response.data.Name
                    this.CodeProduitComptable = response.data.CodeProduitComptable

                    this.ExperationDate = response.data.ExperationDate
                    this.LotNumber = response.data.LotNumber

                    console.log('product detail', response.data)
                })
          }
          else{
            axios.get(`${API_URL}/country/getAllCity`).then((response) => {
                response.data.forEach(element => {
                    this.Dépot.push({
                                City:element,
                                Disponible:0
                            }
                        )
                });
                    console.log(response)
            });
          }

      },

  },
  mounted(){
      this.GetConditionProps()
      this.GetDataByDefault()
      this.GetAllCategoryHave()
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.PopupNewClient{
    position:fixed;
    width:40%;
    padding:40px;
    border-radius: 10px;
    background-color:white;
    box-shadow: 0px 0px 11px -4px black;
    left: 30%;
    top: 5%;
    z-index: 1000;
    height: 80%;
    overflow-y: scroll;
    h4{
        font-family:$primaryFont ;
        font-size: 30px;
        font-weight:bold;
        color:$primary-background-color;
        padding-bottom: 30px;
    }
    button{
            font-size:17px;
            font-family: $primaryFont;
            font-weight:700;
            border-radius: 10px;
            padding:6px 30px;
            background-color:$primary-background-color;
            color:white;
            &:hover{
                border: 1px solid $primary-background-color;
                background-color:transparent;
                color:$primary-background-color;
            }
    }
    .Form{
        display:flex;
        align-items: start;
        justify-content: space-between;
        .LeftInputs,.RightInputs{
            width:100%;
            .InputZone{
                margin: 20px 0px;
                h6{
                    font-family:$primaryFont ;
                    font-size:20px;
                    font-weight:700;
                    color:#142048;
                }
            }
            
            .InputFaild,select{
                border: 1px solid #035a7f;
                width:90%;
                padding:6px  ;
                border-radius: 6px;
                display: flex;
                svg{
                    margin-right: 5px;
         
                }
                input{
                    width:100%;
                    background-color:transparent;
                    border: none;
                    outline: none;
                }
            }
        }
    }
    select{
                border: 1px solid #035a7f;
                width:95%;
                padding:6px  ;
                border-radius: 6px;
                cursor: pointer;
        }
    .TCP{
        margin-bottom: 20px;
        display: flex;
        align-items: end;
        justify-content: space-between;
        h6{
            font-family:$primaryFont ;
            font-size:20px;
            font-weight:700;
            color:#142048;
        }
        .InputZone{
            width:80%;
        }

        span{
            width:20%;
            font-family:$primaryFont ;
            font-size:15px;
            font-weight:700;
            color:#142048;
            cursor: pointer;
            &:hover{
                color:#b41206;
            }
        }
    }
    .BtnPopUp{
        width:95%;
        text-align: end;
    }
    .NewCateg{
        display: flex;
        align-items: center;
        input{
                border: 1px solid #035a7f;
                width:95%;
                padding:6px  ;
                border-radius: 6px;
                display: flex;
        }
    }
    h6{
                     font-family:$primaryFont ;
                    font-size:20px;
                    font-weight:700;
                    color:#142048;
    }
    .LesDepot{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        .SingleDépot{
            width:220px;
            padding: 6px 0px;
            h6{
                font-family:$primaryFont ;
                font-size: 12px;
                font-weight:bold;
               color:#142048;
;
            }
            input{
                border: 1px solid #035a7f;
                width:95%;
                padding:6px  ;
                border-radius: 6px;
                display: flex;
            }
            .minusBtn {
                background-color: red;
                padding: 1% 2%;
                margin-right: 4%;
                margin-top: 3%;
                border-radius: 20px;
                i {
                    font-size: 15px;
                }
                &:hover {
                    border: unset;
                }
            }
            .plusBtn {
                background-color: green;
                padding: 1% 2%;
                margin-right: 4%;
                margin-top: 3%;
                border-radius: 20px;
                i {
                    font-size: 15px;
                }
                &:hover {
                    border: unset;
                }
            }
        }
    }
    .AutreUnite{
        padding-top: 10px;
        display: flex;
        align-items: center;

        span{
            font-family:$primaryFont ;
            font-size:15px;
            font-weight:700;
            color:#142048;
            margin-right: 10px;
            width: 30%;;
            &:hover{
                cursor: pointer;
                color:#b41206;
            }
        }
        input{
                width:70%;
                border: 1px solid #035a7f;
                padding:6px  ;
                border-radius: 6px;
        }
    }
    .AutreDepot{
        // display: flex;
        align-items: center;
        padding-bottom: 30px;
        justify-content: space-between;

        span{
            font-family:$primaryFont ;
            font-size:15px;
            font-weight:700;
            color:#142048;
            margin-right: 10px;
            width: 20%;;
            &:hover{
                cursor: pointer;
                color:#b41206;
            }
        }
        input{
                width:30%;
                border: 1px solid #035a7f;
                padding:6px  ;
                border-radius: 6px;
        }
        .ZoneAddDépot{
                display: flex;
                align-items: center;
                padding-top: 10px;
                justify-content: space-between;
                input{
                    margin: 0px 20px;
                    width: 30%;
                }
                button{
                    font-size:15px;
                    border-radius: 4px;
                    
                }
        }
    }
}
</style>

