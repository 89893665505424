<template>
    <div class="PopupNewClient">
            <h4 v-if="PropsToDepotPopup.Condition == 'New'">Nouveau Dépôts</h4>
            <h4 v-else-if="PropsToDepotPopup.Condition == 'Update'">Mettre à jour un Dépôts</h4>
            <h4 v-else-if="PropsToDepotPopup.Condition == 'Show'">Aperçu un  Dépôts</h4>
            <div class="TCP">
                <div class="InputZone">
                    <h6>Dépôts</h6>
                    <div class="InputFaild">
                        <input type="text" placeholder="Dépôts" v-model="Depot" :disabled="PropsToDepotPopup.Condition === 'Show'">
                    </div>
                </div>

                <div class="InputZone">
                    <h6>Région</h6>
                    <div class="InputFaild">
                        <input type="text" placeholder="Région" v-model="Region" :disabled="PropsToDepotPopup.Condition === 'Show'">
                    </div>
                </div>
                    <div class="InputZone">
                        <h6>Responsable</h6>
                        <div class="InputFaild">
                                <select v-model="TCPchoose" :style="TCPchoose == 'Select un TCP'
                                             && Submit == true 
                                             ? 'border:2px solid red' : ''"
                                             :disabled="PropsToDepotPopup.Condition === 'Show'"
                                             > 
                                    <option value="Select un TCP">Select un Responsable</option>
                                    <option  v-for="TCP,n in AllTCP" :key="n" :value="TCP.Id">{{TCP.Name}}</option>
                                </select>
                        </div>
                    </div>

                    <!-- <div class="InputZone" v-if="PropsToDepotPopup.Condition === 'Show'">
                        <h6>Quantity Par Ville</h6>
                        <div v-for="StockInfo,n in FullOneDepotData.OneCityStock" :key="n">
                            <p><span style="font-weight: bold;">{{StockInfo.City}} : </span>{{StockInfo.Disponible}} </p>
                            <p></p>
                        </div>
                    </div> -->

                    <div class="InputZone" v-if="PropsToDepotPopup.Condition === 'Show'">
                        <h6>Quantity Totale : </h6>
                        <h6>{{FullOneDepotData.SumOfVal}}</h6>
                    </div>
            </div>

            <div class="BtnPopUp">

            <button v-if="PropsToDepotPopup.Condition == 'New'" @click="AddNewClient()" >Valider</button>
            <button v-else-if="PropsToDepotPopup.Condition == 'Update'"  @click="UpdateClient()">Mettre à jour</button>

        </div>
    </div>

</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import Vue from "vue";
import axios from "axios";
import API_URL from "../../config";

export default {
    emit:['Close'],
    props:['PropsToDepotPopup'],
    components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
        Submit:false,
        NewTCP : false,
        AllTCP:[],
        TCPchoose:'Select un TCP',
        Depot: "",
        Region: "",
        OneDepotData: [],
        FullOneDepotData: []
    };
  },
  created() {},
  computed: {},
  methods: {
      AddNewClient(){
          let countryData = {
                Name: this.Depot,
	            Origin: this.Region,
	            For: this.TCPchoose
          }

          console.log(countryData)
        axios.post(`${API_URL}/country/addCity`, countryData)
                            .then((response) => {
                        this.$emit('Close')
                                Vue.$toast.open({
                                message:"Validé",
                                type: "success"
                                });

                            this.Depot = ""
                            this.Region = ""
                            this.TCPchoose = ""
                    })
                    .catch((err) => {
                       console.log(err.response.data);
                            Vue.$toast.open({
                                message:err.response.data.error,
                                type: "error"
                            });

                    })
      },
    UpdateClient(){
            this.Submit = true

            let countryData = {
                Name: this.Depot,
	            Origin: this.Region,
	            For: this.TCPchoose
          }
        axios.put(`${API_URL}/country/modifyDepot/${this.PropsToDepotPopup.Id}`, countryData)
            .then((response) => {
                this.$emit('Close')
                        Vue.$toast.open({
                        message:"Modifié",
                        type: "success"
                        });
            })
            .catch((err) => {
                console.log(err.response.data);
                    Vue.$toast.open({
                        message:err.response.data.error,
                        type: "error"
                    });

            })
        },
      GetAllTpc(){
        axios.get(`${API_URL}/codeClient/getAllTCP`).then((response) => {
            response.data.forEach(element => {
                this.AllTCP.push({Name:element.Name,Id:element._id})
            });
        });
      },
      GetOneDepotData(){
        axios.get(`${API_URL}/country/getOneCity/${this.PropsToDepotPopup.Id}`).then((response) => {
            this.OneDepotData = response.data
        });

        axios.get(`${API_URL}/products/getAllProductFromOneCity/${this.PropsToDepotPopup.NAME}`).then((response) => {
            this.FullOneDepotData = response.data
        });
      },

      GetOneCityData() {
        axios.get(`${API_URL}/country/getOneCity/${this.PropsToDepotPopup.Id}`).then((response) => {
                if(this.PropsToDepotPopup.Condition == 'New'){
                this.Depot = "",
	            this.Region = "",
	            this.TCPchoose = ""
                }
                else {
                this.Depot = response.data.Name,
	            this.Region = response.data.Origin,
	            this.TCPchoose = response.data.For._id
                }
        });
      },
  },
  mounted(){
      this.GetOneCityData()
      this.GetAllTpc()
      this.GetOneDepotData()
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.PopupNewClient{
    position:fixed;
    width:40%;
    padding:40px;
    border-radius: 10px;
    background-color:white;
    box-shadow: 0px 0px 11px -4px black;
    left: 30%;
    top: 10%;
    z-index: 100000;
    height: 80%;
    h4{
        font-family:$primaryFont ;
        font-size: 30px;
        font-weight:bold;
        color:$primary-background-color;
        padding-bottom: 30px;
    }
    button{
            font-size:17px;
            font-family: $primaryFont;
            font-weight:700;
            border-radius: 10px;
            padding:6px 30px;
            background-color:$primary-background-color;
            color:white;
            &:hover{
                border: 1px solid $primary-background-color;
                background-color:transparent;
                color:$primary-background-color;
            }
    }
    .Form{
        display:flex;
        align-items: start;
        justify-content: space-between;
        .LeftInputs,.RightInputs{
            width:100%;
            .InputZone{
                margin: 20px 0px;
                h6{
                    font-family:$primaryFont ;
                    font-size:20px;
                    font-weight:700;
                    color:#142048;
                }
            }
            .InputFaild{
                border: 1px solid #035a7f;
                width:90%;
                padding:6px  ;
                border-radius: 6px;
                display: flex;
                svg{
                    margin-right: 5px;
         
                }
                input{
                    width:100%;
                    background-color:transparent;
                    border: none;
                    outline: none;
                }
            }
        }
    }
    .TCP{
        margin-bottom: 20px;
        h6{
            font-family:$primaryFont ;
            font-size:20px;
            font-weight:700;
            color:#142048;
        }

        select, input{
                border: 1px solid #035a7f;
                width:95%;
                padding:6px  ;
                border-radius: 6px;
                cursor: pointer;
                margin-bottom: 40px
        }
    }
    .BtnPopUp{
        width:95%;
        text-align: end;
    }
    .Personne{
        display: flex;
        align-items: center;
        p{
                    margin-right: 50px;
                    font-family:$primaryFont ;
                    font-size:20px;
                    font-weight:700;
                    color:#142048 !important;
        }
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #142048;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
    }
    @media screen and (max-width:  1840px) {

        }
    @media screen and (max-width:  1640px) {
        padding:20px 40px !important;
        width:60%;
        top:2%;
        left:20%;
      }
    @media screen and (max-width:  1440px) {
        h4{
            font-size:20px;
            padding-bottom: 10px !important;
        }
        h6{
            font-size:15px !important;
        }
        p{
            font-size:15px !important;
        }
        input,select,textarea{
            padding:3px !important;
        }
        .InputZone{
            padding: 3px ;
            margin: 10px 0px !important;
        }
      }

}
</style>

