<template>
  <div class="PopupNewClient productsDetailsPop">
    <div style="display: flex; align-items: baseline">
      <h4 v-if="PropsToInvetairePopup.Condition == 'Show'">
        List des invetaires
      </h4>

      <v-btn class="mx-2" fab dark large color="purple" @click="generatePDF">
        <v-icon dark> mdi-printer-outline </v-icon>
      </v-btn>
    </div>

    <div class="ListClient">
      <div class="Filter">
        <div class="FilterParCatég">
          <h6>Filtrer par produit :</h6>
          <input
            type="text"
            placeholder="Tout"
            v-model="ProduitFiltred"
            @keyup="FilterByProduct"
          />
        </div>
        <div class="FilterParCatég">
          <h6>Filtrer par category :</h6>
          <select v-model="CatégoryFilterd" @change="FilterByCatégory()">
            <option value="Tout">Tout</option>
            <option v-for="(Catég, n) in Catégory" :key="n" :value="Catég">
              {{ Catég }}
            </option>
          </select>
        </div>
        <div class="FilterParCatég">
          <h6>Filtrer par dépôt :</h6>
          <select v-model="DepotFilterd" @change="FiltredByDepot()">
            <option value="Tout">Tout</option>
            <option v-for="(Depot, n) in AllDepot" :key="n" :value="Depot">
              {{ Depot }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="ZoneTable">
      <table ref="document">
        <tr>
          <th>Code produit</th>
          <th>Produit</th>
          <th>Unité</th>
          <th>Dépot</th>
          <th>Disponible</th>
        </tr>

        <template>
          <tr v-for="(invetaire, index) in invetairesData" :key="index">
            <td>{{ invetaire.CodeProduct }}</td>
            <td>{{ invetaire.Product }}</td>
            <td>
              <p>{{ invetaire.Unite }}</p>
            </td>
            <td>
              <template v-if="DepotFilterd == 'Tout'">
                <p
                  v-for="(Depot, depotIndex) in invetaire.Depot"
                  :key="depotIndex"
                  style="margin-bottom: 0"
                >
                  {{ Depot }}
                </p>
                <hr style="opacity: 0" v-if="invetaire.Dispo.length > 1" />
                <span style="opacity: 0" v-if="invetaire.Dispo.length > 1"
                  >100</span
                >
              </template>
              <template v-else>
                {{ invetaire.Depot }}
              </template>
            </td>
            <td>
              <template v-if="DepotFilterd == 'Tout'">
                <p
                  v-for="(Dispo, dispoIndex) in invetaire.Dispo"
                  :key="dispoIndex"
                  style="margin-bottom: 0"
                >
                  {{ Dispo }}
                </p>
                <hr v-if="invetaire.Dispo.length > 1" />
                <span v-if="invetaire.Dispo.length > 1">{{
                  invetaire.SumOfStockDisponible
                }}</span>
              </template>

              <template v-else>
                {{ invetaire.Dispo }}
              </template>
            </td>
          </tr>
        </template>
      </table>

      <div v-if="!dataIsLoaded">
        <div class="spinner">
          <v-progress-circular
            :size="100"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>

      <div class="Vide" v-if="VideData">
        <h5>Vide</h5>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../config";
import html2pdf from "html2pdf.js";

export default {
  emit: ["Close"],
  props: ["PropsToInvetairePopup"],
  components: {},
  data() {
    return {
      getTheData: false,
      invetairesData: [],
      CatégoryFilterd: "Tout",
      Catégory: [],

      AllDepot: [],
      DepotFilterd: "Tout",

      ProduitFiltred: "",

      VideData: false,
      dataIsLoaded: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    FilterByProduct() {
      this.getAllInvetaires();
      // .then(el => {
      //   this.invetairesData = el.filter(el => el.Product.includes(this.ProduitFiltred))
      // })
    },
    FilterByCatégory() {
      this.getAllInvetaires();
    },
    FiltredByDepot() {
      this.getAllInvetaires();
      // if(this.DepotFilterd == "Tous")
      //   this.getAllInvetaires()
      // else {
      //   this.getAllInvetaires().then(el => {
      //     this.invetairesData = el.filter(el => el.Depot.includes(this.DepotFilterd))
      //   })
      // }
    },
    GetAllElementFiltred() {
      axios.get(`${API_URL}/country/getAllCity`).then((response) => {
        response.data.forEach((element) => {
          this.AllDepot.push(element);
        });
      });
    },
    GetAllCategoryHave() {
      axios
        .get(`${API_URL}/products/GetAllProductsCategory`)
        .then((response) => {
          this.Catégory = response.data;
        });
    },
    generatePDF() {
      window.open(`${API_URL}/products/productInvetaireListPDF?Product=${this.ProduitFiltred.toLocaleUpperCase()}&category=${this.CatégoryFilterd}&Depot=${this.DepotFilterd}`, '_blank')
      // html2pdf(this.$refs.document, {
      //   margin: [5, 10, 8, 10],
      //   filename: "invetaire.pdf",
      //   image: { type: "jpeg", quality: 0.98 },
      //   html2canvas: { scale: 1 },
      //   pagebreak: { mode: "avoid-all", after: ".avoidThisRow" },
      //   // jsPDF: { unit: "mm", format: "A4", orientation: "landscape" },
      //   jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
      // });
    },
    getAllInvetaires() {
      this.dataIsLoaded = false;
      this.VideData = false;
      return axios
        .get(
          `${API_URL}/products/productInvetaireList?Product=${this.ProduitFiltred.toLocaleUpperCase()}&category=${
            this.CatégoryFilterd
          }&Depot=${this.DepotFilterd}`
        )
        .then((res) => {
          if (res.data.length == 0) this.VideData = true;

          this.dataIsLoaded = true;
          this.invetairesData = res.data;
        });
    },
  },
  mounted() {
    this.getAllInvetaires();
    this.GetAllCategoryHave();
    this.GetAllElementFiltred();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.ZoneTable {
  margin-bottom: 40px;
  p {
    color: #058b84;
  }
}
table {
  width: 100%;
  th {
    background-color: #058b84;
    font-size: 20px;
    color: white;
    padding: 10px 0px;
    text-align: center;
    width: 10%;
  }
  td {
    font-size: 20px;
    color: #058b84;
    padding: 10px 0px;
    border: 1px solid #058b84;
    font-weight: bold;
    text-align: center;
  }
}

.productsDetailsPop {
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .sumary {
    font-weight: bold;
    color: #757575;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    h5 {
      display: flex;
      span {
        width: 200px;
      }
    }
  }
  .myCard {
    margin-bottom: 20px;
  }
  .productsTitle {
    font-size: 25px;
  }
  .productsDetails {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    span {
      font-weight: bold;
      font-size: 15px;
      margin-right: 30px;
      width: 100px;
      color: black;
    }
  }
}

.PopupNewClient {
  position: absolute;
  width: 80%;
  padding: 40px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 11px -4px black;
  left: 10%;
  top: 10%;
  z-index: 100000;
  overflow: auto;
  height: 80%;
  h4 {
    font-family: $primaryFont;
    font-size: 30px;
    font-weight: bold;
    color: $primary-background-color;
    padding-bottom: 30px;
  }
  button {
    font-size: 17px;
    font-family: $primaryFont;
    font-weight: 700;
    border-radius: 10px;
    padding: 6px 30px;
    background-color: $primary-background-color;
    color: white;
    &:hover {
      border: 1px solid $primary-background-color;
      background-color: transparent;
      color: $primary-background-color;
    }
  }
  .Form {
    display: flex;
    align-items: start;
    justify-content: space-between;
    .LeftInputs,
    .RightInputs {
      width: 100%;
      .InputZone {
        margin: 20px 0px;
        h6 {
          font-family: $primaryFont;
          font-size: 20px;
          font-weight: 700;
          color: #142048;
        }
      }
      .InputFaild {
        border: 1px solid #035a7f;
        width: 90%;
        padding: 6px;
        border-radius: 6px;
        display: flex;
        svg {
          margin-right: 5px;
        }
        input {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
  .TCP {
    margin-bottom: 20px;
    h6 {
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048;
    }

    select {
      border: 1px solid #035a7f;
      width: 95%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .BtnPopUp {
    width: 95%;
    text-align: end;
  }
  .Personne {
    display: flex;
    align-items: center;
    p {
      margin-right: 50px;
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048 !important;
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #142048;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @media screen and (max-width: 1440px) {
    h4 {
      font-size: 20px;
      padding-bottom: 10px !important;
    }
    h6 {
      font-size: 15px !important;
    }
    p {
      font-size: 15px !important;
    }
    input,
    select,
    textarea {
      padding: 3px !important;
    }
    .InputZone {
      padding: 3px;
      margin: 10px 0px !important;
    }
  }
}

.ListClient {
  width: 80%;
  float: left;
  margin-top: 1%;
  ::-webkit-scrollbar {
    width: 10px;
    position: relative;
    top: 20px;
    border-radius: 30px;
  }
  /* Track */

  ::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b41206;
    border-radius: 10px;
  }
  .TableContentZone {
    height: 500px;
    overflow-y: scroll;
  }
  .OuClientAndBtn {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 80%;
    margin: auto;
    button {
      font-size: 17px;
      font-family: $primaryFont;
      font-weight: 500;
      border-radius: 10px;
      padding: 6px 30px;
      background-color: #e1e1e1;
      color: #00abc4;
      &:hover {
        border: 1px solid $primary-background-color;
        background-color: transparent;
        color: $primary-background-color;
      }
    }
  }
  h1,
  h3 {
    font-family: $primaryFont;
    font-size: 40px;
    font-weight: bold;
    color: $primary-background-color;
    text-align: center;
    padding-bottom: 30px;
  }

  .HeaderTable,
  .HeaderBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0px auto;

    h3 {
      font-size: 17px;
      border: 1px solid white;
      padding: 10px 0px;
      background-color: #058b84;
      color: white;
      font-weight: 600;
      width: 400px;
      line-height: 20px;
      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
  .HeaderBody {
    margin-bottom: 10px;
    border-radius: 20px;
    h3 {
      font-size: 17px;
      padding: 10px 0px;
      color: white;
      font-weight: 600;
      width: 200px;
      overflow-wrap: break-word;
      background-color: transparent;
      border: none;
      color: #058b84;
      margin: 0px;
    }
    svg {
      fill: #058b84;
      width: 30px;
      height: 30px;
      margin: 0px 10px;
      &:hover {
        fill: $primary-background-color;
        cursor: pointer;
      }
    }
  }
  .Filter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 30px auto;
    .FilterParCatég {
      h6 {
        color: $primaryColor;
      }
    }
    h6 {
      font-family: $primaryFont;
      font-size: 18px;
      font-weight: bold;
      color: #142048;
      padding-bottom: 5px;
    }
    select,
    Input {
      border: 1px solid #035a7f;
      width: 100%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .Vide {
    width: 80%;
    margin: 30px auto;
    background-color: #fcd309;
    padding: 10px 0px;
    text-align: center;
    h5 {
      font-size: 20px;
      font-weight: 600;
      color: #035a7f;
    }
  }
}
</style>
