<template>
  <div>
    <div
      class="BalckPage"
      v-if="OpenRapportVe"
      @click="ClosePopUp"
    ></div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />
    <RapportVe
      v-if="OpenRapportVe"
      :RapportId="RapportId"
      @Close="ClosePopUp()"
    />

    <div class="GestionStock">
      <div class="inputList"></div>

      <div class="GestionStock__Btn"></div>
      <div class="BeforeFacture">
        <div v-for="(Rapport, n) in GetAllRapportBeforeFacturation" :key="n">
          <div
            class="SingleFacturation"
            :style="n % 2 ? 'background: linear-gradient(180deg, #f3f3f3 50%, #dfdfdf 100%);' : ''"
          >
            <div
              class="Informatation"
              @click="(RapportId = Rapport._id), (OpenRapportVe = true)"
            >
              <h2>{{ Rapport.Rapport.InfoVisiteEssay ? Rapport.Rapport.InfoVisiteEssay.ClientName : '' }}</h2>
              <p>{{ Rapport.ModeOperation }} / {{ Rapport.for.Name }}</p>
              <p>{{ Rapport.Rapport.InfoVisiteEssay ? Rapport.Rapport.InfoVisiteEssay.Adress : '' }}</p>
              <p>{{ Rapport.CurrentDate }} - {{ Rapport.CurrentTime }}</p>
            </div>
            <div class="Btn" v-if="Rapport.EtatMagasinier == 'N'">
              <button
                class="Valider"
                @click="ValiderRapportBeforeFacture(Rapport._id, Rapport.Rapport.InfoVisiteEssay.Variete, Rapport.Rapport.InfoVisiteEssay.Quantity)"
              >
                Valider
              </button>
              <button
                class="Annuler"
                @click="AnnulerRapportBeforeFacture(Rapport._id)"
              >
                Annuler
              </button>
            </div>
            <div class="Btn" v-if="Rapport.EtatMagasinier == 'A'">
              <button class="ValiderBtn">Annulée</button>
            </div>
            <div class="Btn" v-if="Rapport.EtatMagasinier == 'V'">
              <button class="ValiderBtn">Validée</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import RapportVe from "../components/VePopup/VeInfo.vue";

import Swal from "sweetalert2";

import axios from "axios";
import API_URL from "../../config";
import SOCKETS_URL from "../../configSocketsURL";
import io from 'socket.io-client';
import Vue from "vue";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
    RapportVe,
  },
  data() {
    return {
        GetAllRapportBeforeFacturation: [],
      RapportId: "",
      sockets: io.connect(`${SOCKETS_URL}`),
      getTheData: false,
      clientName: "",

      OpenRapportVe: false
    };
  },
  created() {
    this.FunctionToGetAllRapportBeforeFacture()
  },
  computed: {},

  methods: {
    FunctionToGetAllRapportBeforeFacture() {
        axios.get(`${API_URL}/rapport/visiteEssayRapport_GS`).then((response) => {
          this.GetAllRapportBeforeFacturation = response.data;
        });
    },
    async ValiderRapportBeforeFacture(Id, Product, Quantity) {
      const ProductObject = {
        selectedProducts: [
          {
              Name: Product,
              City: "ESS",
              quantity: Quantity
          }
        ]
      }

      try {
        await Promise.all([
          axios.put(`${API_URL}/rapport/changeState/${Id}/Magasinier/A`),
          axios.post(`${API_URL}/Stock/updateStock`, ProductObject),
        ]);

        this.FunctionToGetAllRapportBeforeFacture();
      } catch (err) {
        if (err.response.data) {
          this.$swal(err.response.data.error, "", "error");
        }
      }
    },
    AnnulerRapportBeforeFacture(Id) {
      axios
        .put(`${API_URL}/rapport/changeState/${Id}/Magasinier/A`)
        .then((response) => {
          this.FunctionToGetAllRapportBeforeFacture();
        });
    },

    ClosePopUp() {
      this.OpenRapportVe = false;
      this.FunctionToGetAllRapportBeforeFacture();
    },

  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;
.BalckPage {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 2%;
  .leftArrow {
    button {
      width: 40px;
      height: 40px;
    }
  }
  .rigthArrow {
    button {
      width: 40px;
      height: 40px;
    }
  }
  .paginationBtn {
  background-color: $primaryColor;
  color: white;
  font-size: 20px;
  padding: 3px;
  border-radius: 50%;
  width: 40px;
  margin: 0px 4px;
  text-align: center;
}
.activePagination {
  background-color: $primaryColor2 !important;
}
}
.GestionStock {
  .inputList {
    display: flex;
    div {
      padding: 5%;
    }
  }
    width: 70%;
    float: left;
    margin-left: 5%;
    padding: 2% 0% 0%;
    border-radius: 20px;
    background-color: #F2F2F2;
  ::-webkit-scrollbar {
    width: 5px;
    position: relative;
    top: 20px;
    border-radius: 30px;
  }
  /* Track */

  ::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b41206;
    border-radius: 10px;
  }
  .GestionStock__Btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 3px solid rgb(205, 205, 205);
    padding: 0% 7%;
    h2 {
      width: 100%;
      text-align: center;
      padding: 10px 0px;
      cursor: pointer;
      margin: 0px;
      font-size: 20px;
      background-color: #D1E6E5;
      transition: all .2s ease-in-out;
      color: #058B84;
      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      &:hover {
        background-color: #afc5c4;
        color: white;
      }
    }

    .Active {
      background-color: #058B84;
      color: white;
    }
  }
  .BeforeFacture,
  .BL,
  .AfterFacturation,
  .BR {
    margin-top: 50px;
    height: 600px;
    overflow-y: scroll;
    margin: 0px auto;
    margin-top: 50px;
    width: 100%;
    .Informatation {
      width: 100%;
      h2 {
        text-transform: uppercase;
      }
    }
    .SingleFacturation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 30px;
      box-shadow: 5px 5px 11px -8px black;
      width: 90%;
      margin: 20px auto;
      background: linear-gradient(180deg, #f3f3f3 50%, #dfdfdf 100%);
      h2 {
        font-size: 25px;
        color: #058B84;
        font-weight: 400;
        padding-bottom: 15px;
      }
      p {
        line-height: 10px;
        font-weight: 400;
        line-height: 30px;
        color: #8A8D8D;
      }
      .Btn {
        display: flex;
        align-items: center;
        button {
          padding: 10px 30px;
          margin: 0px 30px;
          border-radius: 5px;
          font-size: 17px;
          font-weight: bold;
          color: white;
        }
        .ValiderBtn {
          border: 1px solid gray;
          background-color: white;
          color: gray;
        }
        .Valider {
          background-color: green;
          &:hover {
            border: 1px solid green;
            color: green;
            background-color: transparent;
          }
        }
        .Annuler {
          background-color: red;
          &:hover {
            border: 1px solid red;
            color: red;
            background-color: transparent;
          }
        }
      }
    }
  }
  .ValideBL {
    &:hover {
      background-color: green !important;
      color: white !important;
      border: none !important;
    }
  }
  .AnnulerBtn {
    &:hover {
      background-color: red !important;
      color: white !important;
      border: none !important;
    }
  }
  // .ValiderBtn{
  //     &:hover{
  //         background-color:green !important;
  //         color: white !important;
  //         border: none !important;
  //     }
  // }
}
</style>
