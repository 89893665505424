<template>
  <div class="tableContents">
    <div class="Filter">
      <div class="FilterParCatég">
        <h6>Filtrer par date :</h6>
        <!-- <select v-model="selectedYear" @change="filtredYearFunc()">
                <option :value="listOfYear" v-for="(listOfYear, index) in listOfYears" :key="index">{{ listOfYear }}</option>
              </select> -->

        <!-- <v-dialog transition="dialog-bottom-transition" max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="#1cb6b6"
              style="color: white"
            >
              Choisir une Période
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-card-text>
                <div class="text-h2 pa-12">
                  <v-row justify="center">
                    <v-date-picker v-model="pickerDateOne" range>
                    </v-date-picker>

                    <v-btn
                      color="#1cb6b6"
                      style="color: white"
                      @click="getTableDetailsBySelectedDate"
                      >valider</v-btn
                    >
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-dialog> -->

        <v-row>
          <v-col cols="12">
            <v-menu
              ref="dateMenu1"
              v-model="dateMenu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fromDate"
                  label="Depuis"
                  prepend-icon="mdi-calendar"
                  readonly
                  @blur="getTcpTableEC"
                  v-on="on"
                  clearable
                  @click:clear="fromDate = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-menu
              ref="dateMenu2"
              v-model="dateMenu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="toDate"
                  label="à"
                  prepend-icon="mdi-calendar"
                  readonly
                  @blur="getTcpTableEC"
                  v-on="on"
                  clearable
                  @click:clear="toDate = null"
                ></v-text-field>
              </template>
              <v-date-picker v-model="toDate" no-title scrollable>
                <!-- <v-btn text color="primary" @click="getTcpTableEC">
                  OK
                </v-btn> -->
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>

      <div class="FilterParCatég">
        <h6>Filtrer par TCP :</h6>
        <select v-model="TCPFilterd" @change="filtredTCPFunc()">
          <option value="all">Tout</option>
          <option v-for="(TCP, n) in AllTCP" :key="n" :value="TCP.Name">
            {{ TCP.Name }}
          </option>
        </select>
      </div>

      <div class="FilterParCatég">
        <h6>Filtrer par client :</h6>
        <v-autocomplete
          v-model="ClientFilterd"
          :items="listClientItems"
          @change="filtredClientFunc"
        ></v-autocomplete>
      </div>
    </div>

    <div class="Filter">
      <div class="FilterParCatég">
        <v-btn
          @click="downloadExcelFile"
          v-if="!tcpTableEcSpinner"
          color="success"
          fab
          small
          dark
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th>Category</th>
          <th>Product</th>
          <th>Qty</th>
          <th>Montant</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="tcpTableEcSpinner">
          <tr>
            <td colspan="4">
              <v-progress-circular
                color="dark-blue"
                indeterminate
                :size="90"
              ></v-progress-circular>
            </td>
          </tr>
        </template>
        <template
          v-if="
            tcpFiltredDataEC.tcpTableData &&
            tcpFiltredDataEC.tcpTableData.data &&
            !tcpTableEcSpinner
          "
        >
          <template v-for="(value, key) in tcpFiltredDataEC.tcpTableData.data">
            <tr v-for="(product, productKey) in value" :key="productKey">
              <td>{{ key }}</td>
              <td>{{ productKey }}</td>
              <td>{{ product[product.length - 1].sumOfQuantity }}</td>
              <td>{{ product[product.length - 1].sumOfAmount.toFixed(2) }}</td>
            </tr>
            <tr class="finalCategoryRow" :key="key">
              <td colspan="2"></td>
              <td>
                {{ getSumOfAmountForOneCategory(key).sumOfOneCategoryQuantity }}
              </td>
              <td>
                {{
                  getSumOfAmountForOneCategory(
                    key
                  ).sumOfOneCategoryAmount.toFixed(2)
                }}
              </td>
            </tr>
          </template>

          <tr class="finalCategoryRow2">
            <td colspan="2"></td>
            <td>{{ tcpFiltredDataEC.tcpTableData.sumOfQuantity }}</td>
            <td>{{ tcpFiltredDataEC.tcpTableData.sumOfAmount.toFixed(2) }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";

import LogOut from "@/components/Logout";

import axios from "axios";
import API_URL from "@/../config";
import SOCKETS_URL from "@/../configSocketsURL";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
      AllTCP: [],
      TCPFilterd: "all",
      selectedYear: new Date().getFullYear(),
      listOfYears: [],

      ClientFilterd: "Tout",
      listClientItems: null,

      fromDate: null,
      toDate: null,

      dateMenu1: false,
      dateMenu2: false,

      pickerDateOne: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],

      tcpTableEcSpinner: true,

      tcpFiltredDataEC: {
        tcpTableData: null,
        selectedCategory: "",
      },

      sumOfAllC: {
        sumOfAllQuantity: 0,
        sumOfAllAmount: 0,
      },
    };
  },
  computed: {},
  async created() {
    this.tcpTableEcSpinner = true;
    await this.getTcpTableEC();
    this.tcpTableEcSpinner = false;

    await this.sumOfAllFunc();
    await this.GetAllElementFiltred();
    await this.getListOfPrevious5Year();
    await this.getListOfAllClient();
  },
  methods: {
    async downloadExcelFile() {
      this.tcpTableEcSpinner = true;
      try {
        let response = null;
        if (this.fromDate && this.toDate) {
          response = await axios.get(
            `${API_URL}/dashboard/TcpTableByProductCategoryToExcel?TCP=${
              this.TCPFilterd
            }&Client=${this.ClientFilterd}&filtredDate=["${this.fromDate}", "${
              this.toDate
            }"]&filterBy=${
              this.TCPFilterd == "Tout" ? "all" : this.TCPFilterd
            }_${this.ClientFilterd == "Tout" ? "all" : this.ClientFilterd}`
          );
        } else {
          response = await axios.get(
            `${API_URL}/dashboard/TcpTableByProductCategoryToExcel?TCP=${
              this.TCPFilterd
            }&Client=${this.ClientFilterd}&filterBy=${
              this.TCPFilterd == "Tout" ? "all" : this.TCPFilterd
            }_${this.ClientFilterd == "Tout" ? "all" : this.ClientFilterd}`
          );
        }
        this.tcpTableEcSpinner = false;

        if (response)
          window.open(
            `${SOCKETS_URL}/${response.data}?token=ALDtIanysTac2023`,
            "_blank"
          );
      } catch (error) {
        console.error(error);
        this.tcpTableEcSpinner = false;
      }
    },

    getSumOfAmountForOneCategory(CategoryName) {
      const tcpData = this.tcpFiltredDataEC.tcpTableData.data[CategoryName];
      if (tcpData) {
        const keys = Object.keys(tcpData);

        if (keys.length > 0) {
          const lastKey = keys[keys.length - 1];
          const lastProducts = tcpData[lastKey];

          if (Array.isArray(lastProducts) && lastProducts.length > 0) {
            const lastProduct = lastProducts[lastProducts.length - 1];
            return lastProduct;
          }
        }
      }
      return null;
    },

    async getTableDetailsBySelectedDate() {
      if (this.pickerDateOne.length != 2)
        return this.$swal("Selectionner une période", "", "error");

      await this.getAllTcpTableData();
    },
    async getListOfAllClient() {
      await axios.get(`${API_URL}/dashboard/AllClientName`).then((response) => {
        this.listClientItems = response.data;
        this.listClientItems.unshift("Tout");
      });
    },
    async getListOfPrevious5Year() {
      let currentYear = new Date().getFullYear();
      // listOfYears
      for (let i = 0; i < 5; i++) {
        this.listOfYears.push(currentYear - i);
      }
    },
    async filtredClientFunc() {
      await this.getAllTcpTableData();
    },
    async getAllTcpTableData() {
      this.tcpTableEcSpinner = true;
      await this.getTcpTableEC();
      this.tcpTableEcSpinner = false;

      await this.sumOfAllFunc();
    },
    async filtredTCPFunc() {
      await this.getAllTcpTableData();
    },
    async filtredYearFunc() {
      await this.getAllTcpTableData();
    },
    async GetAllElementFiltred() {
      await axios.get(`${API_URL}/codeClient/getAllTCP`).then((response) => {
        response.data.forEach((element) => {
          this.AllTCP.push({ Name: element.Name, Id: element._id });
        });
      });
    },

    async getTcpTableEC() {
      try {
        this.tcpTableEcSpinner = true;
        let response;
        if (this.fromDate && this.toDate) {
          response = await axios.get(
            `${API_URL}/dashboard/TcpTableByProductCategory?TCP=${this.TCPFilterd}&Client=${this.ClientFilterd}&filtredDate=["${this.fromDate}", "${this.toDate}"]`
          );
        } else {
          response = await axios.get(
            `${API_URL}/dashboard/TcpTableByProductCategory?TCP=${this.TCPFilterd}&Client=${this.ClientFilterd}`
          );
        }
        this.tcpFiltredDataEC.tcpTableData = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.tcpTableEcSpinner = false;
      }
    },
    async sumOfAllFunc() {
      this.sumOfAllC.sumOfAllQuantity =
        this.tcpFiltredDataEC.tcpTableData.sumOfQuantity;

      this.sumOfAllC.sumOfAllAmount =
        this.tcpFiltredDataEC.tcpTableData.sumOfAmount;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.5);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

$table-background-color: #f2f2f2;
$table-header-background-color: #1cb6b6;
$table-header-color: white;
$table-row-even-background-color: #f9f9f9;
$table-row-hover-background-color: #ddd;

.tableContents {
  width: 100%;
  margin-top: 5%;

  .Filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px auto;
    .FilterParCatég {
      h6 {
        color: $primaryColor;
      }
    }
    h6 {
      font-family: $primaryFont;
      font-size: 18px;
      font-weight: bold;
      color: #142048;
      padding-bottom: 5px;
    }
    select,
    Input {
      border: 1px solid #035a7f;
      width: 100%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
      margin-right: 2%;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: $table-background-color;

      th {
        background-color: $table-header-background-color;
        color: $table-header-color;
      }
    }

    tbody {
      tr:nth-child(even) {
        background-color: $table-row-even-background-color;
      }

      tr:hover {
        background-color: $table-row-hover-background-color;
      }

      .finalCategoryRow {
        background-color: $primaryBgColor2 !important;
        color: black;
        font-weight: bold;
        font-family: $primaryFont;
      }

      .finalCategoryRow2 {
        background-color: $primaryColor !important;
        color: black;
        font-weight: bold;
        font-family: $primaryFont;
        height: 100px;
        font-size: 22px;
      }
    }

    th,
    td {
      padding: 8px;
      text-align: center;
    }
  }
}
</style>
