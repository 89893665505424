var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableContentsProducts"},[_c('div',{staticClass:"Filter"},[_c('div',{staticClass:"FilterParCatég"},[_c('h6',[_vm._v("Filtrer par date :")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"dateMenu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Depuis","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"blur":_vm.getTcpTableEC,"click:clear":function($event){_vm.fromDate = null}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},on))]}}]),model:{value:(_vm.dateMenu1),callback:function ($$v) {_vm.dateMenu1=$$v},expression:"dateMenu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"dateMenu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"à","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"blur":_vm.getTcpTableEC,"click:clear":function($event){_vm.toDate = null}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},on))]}}]),model:{value:(_vm.dateMenu2),callback:function ($$v) {_vm.dateMenu2=$$v},expression:"dateMenu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1)],1)],1)]),_c('div',{staticClass:"Filter"},[_c('div',{staticClass:"FilterParCatég"},[(!_vm.tcpTableEcSpinner)?_c('v-btn',{attrs:{"color":"success","fab":"","small":"","dark":""},on:{"click":_vm.downloadExcelFile}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()],1)]),_c('table',[_vm._m(0),_c('tbody',[(_vm.tcpTableEcSpinner)?[_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('v-progress-circular',{attrs:{"color":"dark-blue","indeterminate":"","size":90}})],1)])]:_vm._e(),(
          _vm.tcpFiltredDataEC.tcpTableData &&
          _vm.tcpFiltredDataEC.tcpTableData.data &&
          !_vm.tcpTableEcSpinner
        )?[_vm._l((_vm.tcpFiltredDataEC.tcpTableData.data),function(value,key){return [_vm._l((value),function(product,productKey){return _c('tr',{key:productKey},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(productKey))]),_c('td',[_vm._v(_vm._s(product[0].Category))]),_c('td',[_vm._v(_vm._s(product[product.length - 1].sumOfQuantity))]),_c('td',[_vm._v(_vm._s(product[product.length - 1].sumOfAmount.toFixed(2)))])])}),_c('tr',{key:key,staticClass:"finalCategoryRow"},[_c('td',{attrs:{"colspan":"3"}}),_c('td',[_vm._v(_vm._s(_vm.getSumOfAmountForOneTCP(key).sumOfOneTcpQuantity))]),_c('td',[_vm._v(" "+_vm._s(_vm.getSumOfAmountForOneTCP(key).sumOfOneTcpAmount.toFixed(2))+" ")])])]}),_c('tr',{staticClass:"finalCategoryRow2"},[_c('td',{attrs:{"colspan":"3"}}),_c('td',[_vm._v(_vm._s(_vm.tcpFiltredDataEC.tcpTableData.sumOfQuantity))]),_c('td',[_vm._v(_vm._s(_vm.tcpFiltredDataEC.tcpTableData.sumOfAmount.toFixed(2)))])])]:_vm._e()],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("TCP")]),_c('th',[_vm._v("Produit")]),_c('th',[_vm._v("Category")]),_c('th',[_vm._v("Quantity")]),_c('th',[_vm._v("Montant")])])])}]

export { render, staticRenderFns }