<template>
  <div>
    <IncludedNavBar />
    <LeftSideNav />

<!-- pa-10 pink text-h4 theme--light v-chip v-chip--label v-size--default w-25 white--text -->
    <div class="addRole">
        <div class="title">
            <h2> Rôles d'utilisateur</h2>
        </div>

        <div class="roleList">
            <v-combobox
                v-model="chips"
                :items="items"
                chips
                label="Ajouter des roles pour les lists d'utilisateurs"
                multiple
                prepend-icon="mdi-filter-variant"
                solo
            >
                <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="remove(item)"
                >
                    <strong class="roleName">{{ item }}</strong>&nbsp;
                </v-chip>
                </template>
            </v-combobox>
        </div>

        <div class="saveRoleBtn">
            <v-btn large>
                Modifier
            </v-btn>
        </div>
    </div>

    <div class="addAccess">
        <div class="title">
            <h2>Accès d'utilisateurs</h2>
        </div>

        <div class="selectWithAccess">
            <div class="selectDropDown">
                <div class="contents">
                    <v-select
                        v-model="userRolesValue"
                        :items="userRoles"
                        chips
                        label="Chips"
                        multiple
                        solo
                    ></v-select>

                    <v-btn block>
                        Modifier
                    </v-btn>
                </div>
            </div>
            <div class="accessList">
                <div class="contents"> 
                    <div class="content" v-for="(accessList, index) in accessList" :key="index">
                        <v-checkbox
                            v-model="selectedAccess"
                            :label="accessList.label"
                            :value="accessList.value"
                        ></v-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";

import axios from "axios";
import API_URL from "../../config";
import Vue from "vue";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
    data() {
        return {
            chips: [],
            items: [],

            userRoles: ['foo', 'bar', 'fizz', 'buzz'],
            userRolesValue: ['foo', 'bar', 'fizz', 'buzz'],

            accessList: [
              { label: 'Tableau de bord', value: "Tableau de bord"},
              { label: 'Gestion des opérateurs', value: "Gestion des opérateurs"},
              { label: 'Evaluation', value: "Evaluation"},
              { label: 'Timeline', value: "Timeline"},
              { label: 'Liste des clients', value: "Liste des clients"},
              { label: 'Liste des produits', value: "Liste des produits"},
              { label: 'Gestion de stock', value: "Gestion de stock"},
              { label: 'Facturation', value: "Facturation"},
              { label: 'Recouvrement', value: "Recouvrement"},
              { label: 'Dépôt', value: "Dépôt"},
              { label: 'Gestion des opérations', value: "Gestion des opérations"},
              { label: 'Transfert de stock', value: "Transfert de stock"},
              { label: 'Mouvement des dépôts', value: "Mouvement des dépôts"},
              { label: 'Mouvement des produits', value: "Mouvement des produits"},
              { label: 'Liste des ventes journalières', value: "Liste des ventes journalières"},
              { label: 'Journal comptable des ventes', value: "Journal comptable des ventes"},
              { label: 'Etat des encaissements', value: "Etat des encaissements"},
              { label: 'Entrée de stock', value: "Entrée de stock"},
              { label: 'Frais de société', value: "Frais de société"},
            ],
            selectedAccess: [],
        }
    },
    created() {

    },
    methods: {
        remove (item) {
            this.chips.splice(this.chips.indexOf(item), 1)
        },
    },
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.addRole {
    width: 80%;
    float: left;
    margin-top: 4%;
    padding: 0% 5%;
    .title {
        display: flex;
        justify-content: space-between;
        align-items: start;
        width: 80%;
        margin: auto;
        padding-bottom: 30px;
        h2 {
            color: $primaryColor;
            font-size: 40px;
            font-weight: bold;
            text-align: center;
        }
    }
    .roleList {
        .roleName {
            font-size: 25px;
            color: $primaryColor2;
        }
    }
    .saveRoleBtn {
        display: flex;
        justify-content: center;
    }
}
.addAccess {
    width: 80%;
    float: left;
    margin-top: 1%;
    padding: 0% 5%;
    position: relative;
    .selectWithAccess {
        display: flex;
        justify-content: center;
        .selectDropDown {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            .contents {
                position: absolute;
            }
        }
        .accessList {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            .contents {
                display: flex;
                flex-wrap: wrap;
                .content {
                    padding: 0% 5% 0% 0%;
                    width: 30%;
                    font-size: 25px;
                    color: $primaryColor2;
                }
            }
        }
    }
    .title {
        display: flex;
        justify-content: space-between;
        align-items: start;
        width: 80%;
        margin: auto;
        padding-bottom: 30px;
        h2 {
            color: $primaryColor;
            font-size: 40px;
            font-weight: bold;
            text-align: center;
        }
    }
}
</style>
