<template>
  <div>
    <div class="BalckPage" v-if="OpenClient" @click="RemoveAllPopup"></div>
    <div class="BalckPage" v-if="OpenClientTwo" @click="RemoveAllPopup"></div>
    <div class="BalckPage" v-if="OpenClientTree" @click="RemoveAllPopup"></div>
    <div class="BalckPage" v-if="OpenClientFour" @click="RemoveAllPopup"></div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />
    <PopupClinet
      v-if="OpenClient"
      :PropsToClientPopup="PropsToClientPopup"
      @Close="CloseFuntion()"
    />
    <PopupPDF
      v-if="OpenClientTwo"
      :PropsToPDFPopup="PropsToPDFPopup"
      @Close="CloseFuntion()"
    />
    <PopupTransfer
      v-if="OpenClientTree"
      :PropsTransfer="PropsTransfer"
      @Close="CloseFuntion()"
    />
    <PopupCrud
      v-if="OpenClientFour"
      :PropsCrud="PropsCrud"
      @Close="CloseFuntion()"
    />

      <div class="TransferDeStock">
      <div class="OuClientAndBtn">
        <h1>Transfert de stock</h1>
        <button @click="PropsTransfer.Condition='New',OpenClientTree = true">Nouveau transfert</button>
      </div>
      <div class="Filter">
        <!-- <div class="FilterParCatég">
          <h6>Filtrer par TCP :</h6>
          <select v-model="TCPFilterd" @change='FiltredByTCP()'>
              <option value="Tout">Tout</option>
              <option v-for="TCP,n in AllTCP" :key="n" :value="TCP.Id" >{{TCP.Name}}</option>
          </select>
        </div>
        <div class="FilterParCatég">
                <h6>Filtrer par Depot :</h6>
                <input type="text" placeholder="Tout" v-model="ClientFilterd" @keyup="FilterByClient">
        </div> -->
      </div>
      <div class="HeaderTable">
        <h3>Date</h3>
        <h3>Dépôt de sortie</h3>
        <h3>Dépôt d'entrée</h3>
        <h3>Produit</h3>
        <h3>Quantité</h3>
        <h3>Télécharger</h3>
        <h3>Supprimer</h3>
      </div>
      <div class="Vide" v-if="VideData">
        <h5>Vide</h5>
      </div>
      <div class="TableContentZone">
        <div
          class="HeaderBody"
          v-for="(Operation, index) in AllOperationHave"
          :key="index"
          :style=" index % 2 == 0 ? 'background-color: rgba(5,139,132,0.22)' : 'background-color: rgba(180,181,181,0.22);'"
        >
          <h3>{{Operation.Date}}</h3>
          <h3>{{ Operation.From }}</h3>
          <h3>{{ Operation.To }}</h3>
          <h3>{{ Operation.Product }}</h3>
          <h3>{{ Operation.Quantity }}</h3>
          <!-- <h3>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 293.142 293.142"
              style="enable-background: new 0 0 293.142 293.142"
              xml:space="preserve"
              @click="
                (PropsToPDFPopup.Condition = 'Show'),
                  (PropsToPDFPopup.Id = Operation._id),
                  (OpenClientTwo = true)
              "
            >
              <g>
                <g>
                  <polygon
                    style="fill: #f9ba48"
                    points="132.508,79.688 141.883,220.313 113.758,220.313 170.008,285.938 226.258,220.313 
			198.133,220.313 207.508,79.688 		"
                  />
                  <path
                    style="fill: #333333"
                    d="M245.008,42.188V0H1.258v215.625c0,15.511,12.614,28.125,28.125,28.125h98.292l42.333,49.392
			l42.333-49.392h51.417c15.511,0,28.125-12.614,28.125-28.125V42.188H245.008z M10.633,215.625V9.375h225v32.813H130.717
			l-9.375-18.75H48.133v192.188c0,10.341-8.409,18.75-18.75,18.75S10.633,225.966,10.633,215.625z M282.509,215.625
			c0,10.341-8.409,18.75-18.75,18.75H220.38l16.073-18.75h-33.305l9.042-135.628l-9.352-0.623L193.122,225h22.945l-46.059,53.733
			L123.948,225h22.945l-9.708-145.622l-9.352,0.623l9.037,135.623h-33.305l16.073,18.75H50.289
			c4.472-4.983,7.219-11.541,7.219-18.75V32.813h58.041l9.375,18.75h157.584V215.625z"
                  />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </h3> -->

          <h3>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 293.142 293.142"
              style="enable-background: new 0 0 293.142 293.142"
              xml:space="preserve"
              @click="DownloadPDF(Operation.id, Operation.From, Operation.Index)"
            >
              <g>
                <g>
                  <polygon
                    style="fill: #f9ba48"
                    points="132.508,79.688 141.883,220.313 113.758,220.313 170.008,285.938 226.258,220.313 
			198.133,220.313 207.508,79.688 		"
                  />
                  <path
                    style="fill: #333333"
                    d="M245.008,42.188V0H1.258v215.625c0,15.511,12.614,28.125,28.125,28.125h98.292l42.333,49.392
			l42.333-49.392h51.417c15.511,0,28.125-12.614,28.125-28.125V42.188H245.008z M10.633,215.625V9.375h225v32.813H130.717
			l-9.375-18.75H48.133v192.188c0,10.341-8.409,18.75-18.75,18.75S10.633,225.966,10.633,215.625z M282.509,215.625
			c0,10.341-8.409,18.75-18.75,18.75H220.38l16.073-18.75h-33.305l9.042-135.628l-9.352-0.623L193.122,225h22.945l-46.059,53.733
			L123.948,225h22.945l-9.708-145.622l-9.352,0.623l9.037,135.623h-33.305l16.073,18.75H50.289
			c4.472-4.983,7.219-11.541,7.219-18.75V32.813h58.041l9.375,18.75h157.584V215.625z"
                  />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </h3>

          <h3>
            <svg  @click="DeleteFunction(Operation.id)" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import PopupClinet from "@/components/TransferDeStockPopup/products";
import PopupPDF from "@/components/TransferDeStockPopup/rapportPDF";
import PopupTransfer from "@/components/TransferDeStockPopup/Transfers";
import PopupCrud from "@/components/TransferDeStockPopup/Crud"
import Swal from 'sweetalert2'

import axios from "axios";
import API_URL from "../../config";
import Vue from "vue";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
    PopupClinet,
    PopupPDF,
    PopupTransfer,
    PopupCrud
  },
  data() {
    return {
      OpenClient: false,
      OpenClientTwo: false,
      OpenClientTree: false,
      OpenClientFour: false,
      PropsToClientPopup: {
        Condition: "",
        Id: "",
      },
      PropsToPDFPopup: {
        Condition: "",
        Id: "",
      },
      PropsTransfer: {
        Condition: "",
        Id: "",
      },
      PropsCrud: {
        Condition: "",
        Id: "",
      },
      Quantity: 0,
      AllOperationHave: [],
      VideData: false,
    };
  },
  created() {},
  computed: {
  },
  methods: {
    getAllTrandsfers() {
      axios.get(`${API_URL}/Stock/getAllStocks`).then((response) => {
        this.AllOperationHave = response.data
      })
    },
    DownloadPDF(ID, Depot, index) {
      window.open(`${API_URL}/Stock/getStocksPDF/${ID}/${Depot}/${index}/TS`, '_blank')
    },
    RemoveAllPopup() {
      this.OpenClient = false;
      this.OpenClientTwo = false;
      this.OpenClientTree = false;
      this.OpenClientFour = false
    },
    CloseFuntion() {
      this.OpenClient = false;
      this.OpenClientTwo = false
      this.OpenClientTree = false
      this.OpenClientFour = false

      this.getAllTrandsfers()
    },
      async DeleteFunction(ID){
        let selectedProducts = {
          selectedProducts: null
        }

        await axios.get(`${API_URL}/Stock/oneStockDetails/${ID}`)
        .then(res => {
          selectedProducts.selectedProducts = res.data.FromD
        })
        .catch(err => {
          if(err.response.data.error)
            Vue.$toast.open({message: err.response.data.error, type: "error"});
        })

        Swal.fire({
          position: "center-center",
          text: "Voulez-vous supprimer cette tranfer",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Supprimer",
        }).then(async result => {
          if (result.isConfirmed) {
            await axios.post(`${API_URL}/Stock/updateStockAvoir`, selectedProducts)
            await axios.delete(`${API_URL}/Stock/deleteStockTransfer/${ID}`)
            .then(() => {
              this.getAllTrandsfers()
            })
            .catch(err => {
              if(err.response.data.error)
                Vue.$toast.open({message: err.response.data.error, type: "error"});
            })
          }
        });
      },
  },
  mounted() {
    this.getAllTrandsfers()
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #00ABC4;
.BalckPage{
    background-color:rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.TransferDeStock{
        width: 80%;
        float: left;
        margin-top: 4%;
        ::-webkit-scrollbar {
        width: 10px;
        position: relative;
        top: 20px;
        border-radius: 30px;
    }
    /* Track */
    
     ::-webkit-scrollbar-track {
        background-color:  rgb(231, 231, 231);
        border-radius: 10px;
    }
    /* Handle */
    
     ::-webkit-scrollbar-thumb {
        background:  #b41206;
        border-radius: 10px;
    }
    .TableContentZone{
        height:500px;
        overflow-y: scroll;
    }
    .OuClientAndBtn{
        display: flex;
        justify-content: space-between;
        align-items: start;
        width: 80%;
        margin: auto;
        button{
            font-size:17px;
            font-family: $primaryFont;
            font-weight: 500;
            border-radius: 10px;
            padding:6px 30px;
            background-color: #E1E1E1;
            color:#00ABC4;
            &:hover{
                border: 1px solid $primary-background-color;
                background-color:transparent;
                color:$primary-background-color;
            }
        }
    }
    h1,h3{
        font-family:$primaryFont ;
        font-size: 40px;
        font-weight:bold;
        color:$primary-background-color;
        text-align:center;
        padding-bottom: 30px;
    }

    .HeaderTable,.HeaderBody{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: 0px auto;

        h3{
            font-size: 17px;
            border: 1px solid white;
            padding:10px 0px;
            background-color:#058B84;
            color:white;
            font-weight: 600;
            width:400px;
            line-height: 20px;
            &:first-of-type {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }
            &:last-of-type {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }
    }
    .HeaderBody{
        margin-bottom: 10px;
        border-radius: 20px;
        h3{
           font-size: 17px;
            padding:10px 0px;
            color:white;
            font-weight: 600;
            width:200px;
            overflow-wrap: break-word;
            background-color:transparent;
            border: none;
            color: #058B84;
            margin: 0px;    
        }
        svg{
            fill: #058B84;
            width:30px;
            height:30px;
            margin: 0px 10px;
            &:hover{
                fill: $primary-background-color;
                cursor: pointer;
            }
        }
    }
        .Filter{
        display:flex;
        justify-content:space-around;
        align-items:center;
        width:80%;
        margin: 0px auto;
        .FilterParCatég {
            h6 {
                color: $primaryColor;
            }
        }
        h6{
        font-family:$primaryFont ;
        font-size: 18px;
        font-weight:bold;
        color:#142048;
        padding-bottom: 5px;
        }
        select,Input{
                border: 1px solid #035a7f;
                width:100%;
                padding:6px  ;
                border-radius: 6px;
                cursor: pointer;
        }
    }
    .Vide{
        width:80%;
        margin:30px  auto;
        background-color: #fcd309;
        padding:10px 0px;
        text-align: center;
        h5{
            font-size: 20px;
            font-weight: 600;
            color:#035a7f;
        }
    }
}
</style>
