<template>
  <div class="PopupNewClient pdfDetailsPop">
    <h4 v-if="PropsToPDFPopup.Condition == 'New'">Nouveau</h4>
    <h4 v-else-if="PropsToPDFPopup.Condition == 'Update'">Mettre à jour</h4>
    <h4 v-else-if="PropsToPDFPopup.Condition == 'Show'">Aperçu pdf details</h4>

    <div class="spinner" v-if="!getTheData">
      <v-progress-circular
        :size="100"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </div>

    <div v-if="HaveBC">
      <v-alert border="bottom" color="green lighten-1" dark class="text-center">
        BC
      </v-alert>
      <div class="BCList">
        <v-btn depressed v-if="ES" @click="downloadPdfRapport('ES', 'BC', 1)"> Engrais speciaux </v-btn>
        <v-btn depressed v-if="EC" @click="downloadPdfRapport('EC', 'BC', 2)"> Engrais classiques </v-btn>
        <v-btn depressed v-if="S" @click="downloadPdfRapport('S', 'BC', 3)"> Semences </v-btn>
        <v-btn depressed v-if="P" @click="downloadPdfRapport('P', 'BC', 4)"> Pesticides </v-btn>
        <v-btn depressed v-if="HP" @click="downloadPdfRapport('HP', 'BC', 5)"> Hygiènepublique </v-btn>
        <v-btn depressed v-if="A" @click="downloadPdfRapport('A', 'BC', 6)"> Additifs </v-btn>
      </div>
    </div>

    <div v-if="HaveBL">
      <v-alert border="bottom" color="green lighten-1" dark class="text-center">
        BL
      </v-alert>

      <div class="BLList" v-for="(city, index) in MG" :key="index">
        <v-btn depressed @click="downloadPdfRapport(city, 'BL', index)">
          {{ city }}
        </v-btn>
      </div>
    </div>

    <div v-if="HaveF">
      <v-alert border="bottom" color="green lighten-1" dark class="text-center">
        F
      </v-alert>

      <div class="FList">
        <v-btn depressed v-if="ES" @click="downloadPdfRapport('ES', 'F', 1)"> Engrais speciaux </v-btn>
        <v-btn depressed v-if="EC" @click="downloadPdfRapport('EC', 'F', 2)"> Engrais classiques </v-btn>
        <v-btn depressed v-if="S" @click="downloadPdfRapport('S', 'F', 3)"> Semences </v-btn>
        <v-btn depressed v-if="P" @click="downloadPdfRapport('P', 'F', 4)"> Pesticides </v-btn>
        <v-btn depressed v-if="HP" @click="downloadPdfRapport('HP', 'F', 5)"> Hygiènepublique </v-btn>
        <v-btn depressed v-if="A" @click="downloadPdfRapport('V', 'F', 6)"> Additifs </v-btn>
      </div>
    </div>
    <button v-if="PropsToPDFPopup.Condition == 'New'">Valider</button>
    <button v-else-if="PropsToPDFPopup.Condition == 'Update'">
      Mettre à jour
    </button>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import Vue from "vue";
import axios from "axios";
import API_URL from "@/../config";

export default {
  emit: ["Close"],
  props: ["PropsToPDFPopup"],
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
      ID: "",
      FromUser: "",
      HaveBL: false,
      HaveBC: false,
      HaveF: false,
      ES: false,
      EC: false,
      S: false,
      P: false,
      HP: false,
      A: false,
      MP: "",
      MG: [],
      getTheData: false
    };
  },
  created() {},
  computed: {},
  methods: {
    downloadPdfRapport(productsType, rapportType, indexOfThisR) {
      window.open(`${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/${productsType}/${rapportType}?indexOf=${indexOfThisR}`, '_blank')
    },
    getThisRapportSumary() {
      axios
        .get(
          `${API_URL}/rapport/getSumOffQuantityOffOneR/${this.PropsToPDFPopup.Id}`
        )
        .then((response) => {
          this.HaveBL = response.data.HaveBL;
          this.HaveBC = response.data.HaveBC;
          this.HaveF = response.data.HaveF;
          this.ES = response.data.ES;
          this.EC = response.data.EC;
          this.S = response.data.S;
          this.P = response.data.P;
          this.HP = response.data.HP;
          this.A = response.data.A;
          this.MP = response.data.MP;
          this.MG = response.data.MG;

          this.ID = response.data.ID;
          this.FromUser = response.data.FromUser

          this.getTheData = true

          // console.log(response.data.ID);
          // console.log(response.data.FromUser);

          console.log(response.data)
        });
    },
  },
  mounted() {
    this.getThisRapportSumary();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.pdfDetailsPop {
  button {
    margin-bottom: 20px;
  }
  .BCList {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .BCList {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .FList {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.PopupNewClient {
  position: absolute;
  width: 40%;
  padding: 40px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 11px -4px black;
  left: 30%;
  top: 10%;
  z-index: 100000;
  overflow: auto;
  height: 80%;
  h4 {
    font-family: $primaryFont;
    font-size: 30px;
    font-weight: bold;
    color: $primary-background-color;
    padding-bottom: 30px;
  }
  .Form {
    display: flex;
    align-items: start;
    justify-content: space-between;
    .LeftInputs,
    .RightInputs {
      width: 100%;
      .InputZone {
        margin: 20px 0px;
        h6 {
          font-family: $primaryFont;
          font-size: 20px;
          font-weight: 700;
          color: #142048;
        }
      }
      .InputFaild {
        border: 1px solid #035a7f;
        width: 90%;
        padding: 6px;
        border-radius: 6px;
        display: flex;
        svg {
          margin-right: 5px;
        }
        input {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
  .TCP {
    margin-bottom: 20px;
    h6 {
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048;
    }

    select {
      border: 1px solid #035a7f;
      width: 95%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .BtnPopUp {
    width: 95%;
    text-align: end;
  }
  .Personne {
    display: flex;
    align-items: center;
    p {
      margin-right: 50px;
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048 !important;
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #142048;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @media screen and (max-width: 1840px) {
  }
  @media screen and (max-width: 1640px) {
    padding: 20px 40px !important;
    width: 60%;
    top: 2%;
    left: 20%;
  }
  @media screen and (max-width: 1440px) {
    h4 {
      font-size: 20px;
      padding-bottom: 10px !important;
    }
    h6 {
      font-size: 15px !important;
    }
    p {
      font-size: 15px !important;
    }
    input,
    select,
    textarea {
      padding: 3px !important;
    }
    .InputZone {
      padding: 3px;
      margin: 10px 0px !important;
    }
  }
}
</style>
