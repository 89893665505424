<template>
  <div>
    <div
      class="BalckPage"
      v-if="OpenRapportVehiculFee"
      @click="RemoveAllPopup"
    ></div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />

    <DGFees :RapportId="RapportId" :OneOfCV="OneOfCV" @closePopup="OpenRapportVehiculFee=false" v-if="OpenRapportVehiculFee" />

    <div class="GestionStock">
      <div class="inputList">
        <v-select
          :items="filterByItems"
          label="Filtrer par"
          solo
          style="width: 20%"
          v-model="selectedFilterByItems"
          @change="getAllCV"
        ></v-select>

        <v-select
          :items="allTcpByName"
          label="Filtrer par"
          solo
          style="width: 20%"
          v-model="selectedFilterTCP"
          @change="getAllCV"
        ></v-select>
      </div>

      <div class="GestionStock__Btn">
        <h2>
          Frais de société (validation DG)
        </h2>
      </div>
      <div class="BeforeFacture">
        <div
          class="SingleFacturation"
          v-for="(listOfCV, index) in listOfCV" :key="index"
        >
          <div 
            class="Informatation"
            @click="OpenRapportVehiculFee = true, OneOfCV = listOfCV"
          >
            <h2>{{ listOfCV.responsable.Name }}</h2>
            <p>{{ listOfCV.date }}</p>
            <p>{{ listOfCV.montant }} DH</p>
            <p v-if="listOfCV.typeOfOp == 'TL'"> {{ listOfCV.startedPoint }} - {{ listOfCV.endPoint }} </p>
            <p v-if="listOfCV.typeOfOp == 'CV' || listOfCV.typeOfOp == 'FD'"> {{ listOfCV.details }} </p>
            <p>{{ listOfCV.typeOfOp == "CV" ? "Charge vehicule" : listOfCV.typeOfOp == "FD" ? "Frais de déplacement" : "Frais de transport local" }}</p>
          </div>
          <div class="Btn">
            <button class="Valider" v-if="listOfCV.dgValidationStatus == 'N'" @click="changeCvStatus(listOfCV._id, 'V', listOfCV.typeOfOp)">Valider</button>
            <button class="Annuler" v-if="listOfCV.dgValidationStatus == 'N'" @click="changeCvStatus(listOfCV._id, 'A', listOfCV.typeOfOp)">Annuler</button>
            <button class="ValiderBtn" v-else-if="listOfCV.dgValidationStatus == 'V'">Validée</button>
            <button class="ValiderBtn" v-else-if="listOfCV.dgValidationStatus == 'A'">Annulée</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";

import DGFees from "@/components/CompanyFeesPopup/DGFees"

import axios from "axios";
import API_URL from "../../config";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
    DGFees
  },
  data() {
    return {
        OpenRapportVehiculFee: false,
        RapportId: "",
        listOfCV: null,
        OneOfCV: null,

        filterByItems: ["Tous", "Annulé", "Validé", "En cours"],
        selectedFilterByItems: "Tous",

        allTcpByName: ["Tous"],
        selectedFilterTCP: "Tous",

        allTcpData: [],
    };
  },
  created() {
    this.getAllCV(),
    this.getAllTcpByName()
  },
  computed: {},
  methods: {
    RemoveAllPopup() {
      this.OpenRapportVehiculFee = false;
    },
    getAllTcpByName() {
      axios.get(`${API_URL}/user/getUserNameWithID/all`).then((response) => {
        return response.data.map((element) => {
          this.allTcpByName.push(element.Name);
          this.allTcpData.push(element)
        });
      });
    },
    getAllCV() {
      let selectedFilter = "Tous"
      let selectedFilterTCP = "Tous"

      if(this.selectedFilterByItems === "Annulé")
        selectedFilter = "A"
      if(this.selectedFilterByItems === "Validé")
        selectedFilter = "V"
      if(this.selectedFilterByItems === "En cours")
        selectedFilter = "N"

      if(this.selectedFilterTCP != "Tous") {
        selectedFilterTCP = this.allTcpData.find(el => el.Name === this.selectedFilterTCP)._id
      }

      axios.get(`${API_URL}/companyFees/companiesFees/DgCompaniesFees?dgValidationStatus=${selectedFilter}&responsable=${selectedFilterTCP}`)
      .then(res => {
          this.listOfCV = res.data
      })
    },
    changeCvStatus(id, status, type) {
        this.$swal({
            title: `Voulez vous ${status == "V" ? "validée" : "annulée"}`,
            confirmButtonText: `${status == "V" ? "validée" : "annulée"}`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`${API_URL}/companyFees/changeCompanyFeeStatusDG/${id}/${status}/${type}`)
                .then(() => this.$swal(`Cette operation à été ${status == "V" ? "validée" : "annulée"}`, "", "success"))
                .then(() => this.getAllCV())
                .catch(err => {
                    this.$swal(`${err.response.data.error}`, "", "error")
                })
            }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;
.BalckPage {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 2%;
  .leftArrow {
    button {
      width: 40px;
      height: 40px;
    }
  }
  .rigthArrow {
    button {
      width: 40px;
      height: 40px;
    }
  }
  .paginationBtn {
  background-color: $primaryColor;
  color: white;
  font-size: 20px;
  padding: 3px;
  border-radius: 50%;
  width: 40px;
  margin: 0px 4px;
  text-align: center;
}
.activePagination {
  background-color: $primaryColor2 !important;
}
}
.GestionStock {
  .inputList {
    display: flex;
    div {
      padding: 5%;
    }
  }
    width: 70%;
    float: left;
    margin-left: 5%;
    padding: 2% 0% 0%;
    border-radius: 20px;
    background-color: #F2F2F2;
  ::-webkit-scrollbar {
    width: 5px;
    position: relative;
    top: 20px;
    border-radius: 30px;
  }
  /* Track */

  ::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b41206;
    border-radius: 10px;
  }
  .GestionStock__Btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 3px solid rgb(205, 205, 205);
    padding: 0% 7%;
    h2 {
      width: 100%;
      text-align: center;
      padding: 10px 0px;
      cursor: pointer;
      margin: 0px;
      font-size: 20px;
      background-color: #D1E6E5;
      transition: all .2s ease-in-out;
      color: #058B84;
      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      &:hover {
        background-color: #afc5c4;
        color: white;
      }
    }

    .Active {
      background-color: #058B84;
      color: white;
    }
  }
  .BeforeFacture,
  .BL,
  .AfterFacturation,
  .BR {
    margin-top: 50px;
    height: 600px;
    overflow-y: scroll;
    margin: 0px auto;
    margin-top: 50px;
    width: 100%;
    .Informatation {
      width: 100%;
      h2 {
        text-transform: uppercase;
      }
    }
    .SingleFacturation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 30px;
      box-shadow: 5px 5px 11px -8px black;
      width: 90%;
      margin: 20px auto;
      background: linear-gradient(180deg, #f3f3f3 50%, #dfdfdf 100%);
      h2 {
        font-size: 25px;
        color: #058B84;
        font-weight: 400;
        padding-bottom: 15px;
      }
      p {
        line-height: 10px;
        font-weight: 400;
        line-height: 30px;
        color: #8A8D8D;
      }
      .Btn {
        display: flex;
        align-items: center;
        button {
          padding: 10px 30px;
          margin: 0px 30px;
          border-radius: 5px;
          font-size: 17px;
          font-weight: bold;
          color: white;
        }
        .ValiderBtn {
          border: 1px solid gray;
          background-color: white;
          color: gray;
        }
        .Valider {
          background-color: green;
          &:hover {
            border: 1px solid green;
            color: green;
            background-color: transparent;
          }
        }
        .Annuler {
          background-color: red;
          &:hover {
            border: 1px solid red;
            color: red;
            background-color: transparent;
          }
        }
      }
    }
  }
  .ValideBL {
    &:hover {
      background-color: green !important;
      color: white !important;
      border: none !important;
    }
  }
  .AnnulerBtn {
    &:hover {
      background-color: red !important;
      color: white !important;
      border: none !important;
    }
  }
}
</style>
