<template>
  <div>
    <div class="BalckPage" v-if="OpenStockEnterPopup" @click="RemoveAllPopup"></div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />

    <div class="ListClient">
        <PopupStockEnter v-if="OpenStockEnterPopup" :PropsToProductPopup="PropsToProductPopup" @Close="CloseFuntion()" />
      <!-- <PopupNewProduct v-if="OpenProduct" :PropsToProductPopup="PropsToProductPopup" @close='RefleshFuntion()' /> -->
      <div class="Filter">
        <div class="FilterParCatég">
          <h6>Date :</h6>
          <input type="date" v-model="SelectedDate" />
        </div>
        <div class="FilterParCatég">
          <h6>Catégorie de produit :</h6>
          <select v-model="CatégoryFilterd" @change="FilterByCatégory()">
            <option v-for="(Catég, n) in Catégory" :key="n" :value="Catég">
              {{ Catég }}
            </option>
          </select>
        </div>
        <div class="FilterParCatég">
          <h6>Produit :</h6>
          <select v-model="ProductFilterd" @change="FilterByProducts()">
            <option
              v-for="(Product, n) in Products"
              :key="n"
              :value="Product.Name"
            >
              {{ Product.Name }}
            </option>
          </select>
        </div>
        <div class="FilterParCatég">
          <h6>Dépôt :</h6>
          <select v-model="DepotFilterd">
            <option v-for="(Depot, n) in AllDepot" :key="n" :value="Depot">
              {{ Depot }}
            </option>
          </select>
        </div>
      </div>

      <div class="Filter">
        <div class="FilterParCatég">
          <h6>Quantité :</h6>
          <input type="number" placeholder="Tout" v-model="SelectedQuantity" />
        </div>
        <div class="FilterParCatég">
          <v-autocomplete
            v-if="lotListNumbers.length != 0"
            label="Numero de lot :"
            :items="lotListNumbers"
            v-model="LotNumber"
          ></v-autocomplete>

          <div class="FilterParCatég" v-if="lotListNumbers.length == 0">
            <h6>Numero de lot :</h6>
            <input
              type="text"
              placeholder="Numero de lot"
              v-model="LotNumber"
            />
          </div>
        </div>
        <div class="FilterParCatég">
          <h6>Date d'éxperation :</h6>
          <input type="date" v-model="ExperationDate" />
        </div>
        <v-btn
          class="mx-2"
          fab
          dark
          large
          color="purple"
          @click="addToStockFunc"
        >
          <v-icon dark> mdi-plus-circle-outline </v-icon>
        </v-btn>
      </div>

      <hr />

      <div class="Filter">
        <div class="FilterParCatég">
          <h6>Produit :</h6>
          <input type="text" v-model="filterdProduct" @keyup="GetAllTransfer" />
        </div>

        <div class="FilterParCatég">
          <h6>Numero de lot :</h6>
          <input
            type="text"
            v-model="filterdLotNumber"
            @keyup="GetAllTransfer"
          />
        </div>
      </div>

      <div class="HeaderTable">
        <h3>Date d'éxperation</h3>
        <h3>Date</h3>
        <h3>Catégorie</h3>
        <h3>Produit</h3>
        <h3>Emballage</h3>
        <h3>Quantité</h3>
        <h3>Dépôt</h3>
        <h3>Numero de lot</h3>
        <h3>Actions</h3>
      </div>
      <div class="Vide" v-if="VideData">
        <h5>Vide</h5>
      </div>
      <div class="TableContentZone">
        <div
          v-for="(allTransfer, index) in allTransfer"
          :key="index"
          :style="
            index % 2 == 0
              ? 'background-color: rgba(5,139,132,0.22)'
              : 'background-color: rgba(180,181,181,0.22);'
          "
          class="HeaderBody"
        >
          <h3>{{ allTransfer.ExperationDate }}</h3>
          <h3>{{ allTransfer.SelectedDate }}</h3>
          <h3>{{ allTransfer.Category }}</h3>
          <h3>{{ allTransfer.Produit }}</h3>
          <h3>{{ allTransfer.ProductDetails[0].Unity }}</h3>
          <h3>{{ allTransfer.Quantity }}</h3>
          <h3>{{ allTransfer.Depot }}</h3>
          <h3>{{ allTransfer.LotNumber }}</h3>
          <h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
              @click="(PropsToProductPopup.Condition = 'Update'), (PropsToProductPopup.Details = allTransfer), (OpenStockEnterPopup = true)"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
              @click="handleDeleteStock(allTransfer._id, allTransfer.Produit, allTransfer.Depot, allTransfer.Quantity)"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"
              />
            </svg>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import PopupNewProduct from "@/components/PopupNewProduct";
import PopupStockEnter from "@/components/StockPopup/StockEnter";
import axios from "axios";
import API_URL from "../../config";
import Swal from "sweetalert2";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
    PopupNewProduct,
    PopupStockEnter
  },
  data() {
    return {
      OpenProduct: false,
      OpenStockEnterPopup: false,
      PropsToProductPopup: {
        Condition: "",
        Details: ""
      },
      CatégoryFilterd: "",
      ProductFilterd: "",
      DepotFilterd: "",
      SelectedDate: "",
      SelectedQuantity: 0,
      Catégory: [],
      Products: [],
      AllDepot: [],
      OneProdDetails: "",
      VideData: false,

      allTransfer: [],

      ExperationDate: "",
      LotNumber: "",

      filterdProduct: "",
      filterdLotNumber: "",

      lotListNumbers: [],
    };
  },
  created() {},
  computed: {},
  methods: {
    handleDeleteStock(transfertId, product, depot, quantity) {
      Swal.fire({
        position: "center-center",
        text: "Voulez-vous supprimer ce client?",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Supprimer",
      }).then(async (result) => {
        if (result.isConfirmed) {
            await axios.post(`${API_URL}/Stock/updateStockAvoir`, {
                selectedProducts: [
                    {
                        Name: product,
                        City: depot,
                        quantity: parseFloat(quantity)
                    }
                ]
            })
            await axios.delete(`${API_URL}/Stock/deleteStockEnter/${transfertId}`)

            this.GetAllTransfer();
            Swal.fire("Suprimée", "", "success");
        }
      });
    },
    addToStockFunc() {
      let ObjectData = {
        Category: this.CatégoryFilterd,
        Produit: this.ProductFilterd,
        ProductDetails: this.OneProdDetails,
        Quantity: this.SelectedQuantity,
        Depot: this.DepotFilterd,
        ExperationDate: this.ExperationDate,
        LotNumber: this.LotNumber,
        SelectedDate: this.SelectedDate,
      };

      let ProductsObjectData = [
        {
          Name: this.ProductFilterd,
          City: this.DepotFilterd,
          quantity: this.SelectedQuantity,
        },
      ];

      axios
        .all([
          axios.post(`${API_URL}/stock/addStockEnterToDB`, ObjectData),
          axios.post(`${API_URL}/Stock/updateStockAvoir`, {
            selectedProducts: ProductsObjectData,
          }),
          axios.post(
            `${API_URL}/rapport/clearRedisData?keys=["ProductByDepotDpP"]`
          ),
          // axios.get(`${API_URL}/rapport/getAllFProductByDepot?selectedDepot=Tout&responsableOP=Tout&Type=Tout&MO=Tout`)
        ])
        .then(
          axios.spread((...response) => {
            // console.log(response[0])
            // console.log(response[1])
            this.$swal("Ajoutée!", "", "success");

            this.CatégoryFilterd = "";
            this.ProductFilterd = "";
            this.SelectedQuantity = 0;
            this.DepotFilterd = "";
            this.LotNumber = "";
          })
        )
        .then(() => {
          this.GetAllTransfer();
        })
        .catch((err) => {
          if (err.response.data.error) {
            this.$swal(`${err.response.data.error}`, "", "error");
          }
        });
    },
    GetOneCategoryLotNumber() {
      this.lotListNumbers;
      axios
        .get(
          `${API_URL}/products/allCategoryLotNumber?productsCategory=${this.CatégoryFilterd}`
        )
        .then((res) => {
          this.lotListNumbers = res.data;
        });
    },
    GetOneProductLotNumber() {
      this.lotListNumbers = [];
      axios
        .get(
          `${API_URL}/products/allProductLotNumber?productName=${this.ProductFilterd}`
        )
        .then((res) => {
          this.lotListNumbers = res.data;
        });
    },
    FilterByCatégory() {
      this.GetProductByCategory();
      this.GetOneCategoryLotNumber();
    },
    async FilterByProducts() {
      this.GetAllDepot();
      this.GetOneProductDetails();
      this.GetOneProductLotNumber();
    },
    RemoveAllPopup() {
      this.OpenProduct = false;
      this.OpenStockEnterPopup = false
    },
    GetAllCategoryHave() {
      axios
        .get(`${API_URL}/products/GetAllProductsCategory`)
        .then((response) => {
          this.Catégory = response.data;
        });
    },
    GetProductByCategory() {
      axios
        .get(`${API_URL}/products/getAllProductsAlphabitacllyOrder`)
        .then((response) => {
          this.Products = response.data.filter(
            (el) => el.Categorie == this.CatégoryFilterd
          );
        });
    },
    GetAllDepot() {
      axios.get(`${API_URL}/country/getAllCity`).then((response) => {
        response.data.forEach((element) => {
          this.AllDepot.push(element);
        });
      });
    },
    GetOneProductDetails() {
      axios
        .get(`${API_URL}/products/productFilterByName/${this.ProductFilterd}`)
        .then((res) => {
          this.OneProdDetails = res.data;
        });
    },
    GetAllTransfer() {
      axios
        .get(
          `${API_URL}/stock/getAllStockEnterData?product=${this.filterdProduct}&lotNumber=${this.filterdLotNumber}`
        )
        .then((response) => {
          this.allTransfer = response.data;
        });
    },
    CloseFuntion() {
        this.GetAllTransfer();
        this.OpenStockEnterPopup = false
    }
  },
  mounted() {
    this.GetAllCategoryHave();
    this.GetAllTransfer();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #00abc4;
.BalckPage {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.ListClient {
  width: 80%;
  float: left;
  margin-top: 4%;
  ::-webkit-scrollbar {
    width: 10px;
    position: relative;
    top: 20px;
    border-radius: 30px;
  }
  /* Track */

  ::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b41206;
    border-radius: 10px;
  }
  .TableContentZone {
    height: 500px;
    overflow-y: scroll;
  }
  .OuClientAndBtn {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 80%;
    margin: auto;
    button {
      font-size: 17px;
      font-family: $primaryFont;
      font-weight: 500;
      border-radius: 10px;
      padding: 6px 30px;
      background-color: #e1e1e1;
      color: #00abc4;
      &:hover {
        border: 1px solid $primary-background-color;
        background-color: transparent;
        color: $primary-background-color;
      }
    }
  }
  h1,
  h3 {
    font-family: $primaryFont;
    font-size: 40px;
    font-weight: bold;
    color: $primary-background-color;
    text-align: center;
    padding-bottom: 30px;
  }

  .HeaderTable,
  .HeaderBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0px auto;

    h3 {
      font-size: 17px;
      border: 1px solid white;
      padding: 10px 0px;
      background-color: #058b84;
      color: white;
      font-weight: 600;
      width: 400px;
      line-height: 20px;
      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
  .HeaderBody {
    margin-bottom: 10px;
    border-radius: 20px;
    h3 {
      font-size: 17px;
      padding: 10px 0px;
      color: white;
      font-weight: 600;
      width: 200px;
      overflow-wrap: break-word;
      background-color: transparent;
      border: none;
      color: #058b84;
      margin: 0px;
    }
    svg {
      fill: #058b84;
      width: 30px;
      height: 30px;
      margin: 0px 10px;
      &:hover {
        fill: $primary-background-color;
        cursor: pointer;
      }
    }
  }
  .Filter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 30px auto;
    .FilterParCatég {
      h6 {
        color: $primaryColor;
      }
    }
    h6 {
      font-family: $primaryFont;
      font-size: 18px;
      font-weight: bold;
      color: #142048;
      padding-bottom: 5px;
    }
    select,
    Input {
      border: 1px solid #035a7f;
      width: 100%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .Vide {
    width: 80%;
    margin: 30px auto;
    background-color: #fcd309;
    padding: 10px 0px;
    text-align: center;
    h5 {
      font-size: 20px;
      font-weight: 600;
      color: #035a7f;
    }
  }
}
</style>
