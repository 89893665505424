<template>
  <div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />

    <div class="rigthSideContents">

    <div class="Filter">
        <div class="FilterParCatég">
          <h6>Année :</h6>
          <input type="number" min="1900" max="2099" step="1" v-model="currentYear" @change="getAllStepperData" />
        </div>
    </div>

      <div class="isLoading" v-if="isLoading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular>
      </div>

      <template v-if="!isLoading">
        <div class="steper" v-for="(usersData, index) in userData" :key="index">
          <v-row>
            <v-col cols="12" lg="3">
              <div
                class="magasineName"
                @click="reddirectToRapportFunc(usersData)"
              >
                <!-- <p class="magasinID">
                  21{{ usersData.for.NameCode
                  }}{{ ("0000" + (index + 1)).slice(-5) }}
                </p> -->
                <p class="magasinSubName">
                  Référence: <br />
                  {{ usersData.RF }}
                </p>
                <p class="magasinSubNameTwo">
                  {{ usersData.InfoGenerale.NomSociete }}
                </p>
                <p class="magasinSubNameTree">
                  {{ usersData.for.Name }}
                  {{
                    usersData.ModeOperation != "false"
                      ? " / " + usersData.ModeOperation
                      : ""
                  }}
                </p>
              </div>
            </v-col>
            <v-col cols="12" lg="9">
              <div>
                <v-stepper alt-labels>
                  <v-stepper-header>
                    <v-stepper-step
                      class="step"
                      step="3"
                      complete
                      v-if="
                        usersData.EtatDgeneral == 'V' ||
                        usersData.Dcommercial == 'V'
                      "
                      @click="usersData.selectDGModal = !usersData.selectDGModal"
                    >
                      <div class="Operation">
                        Validation administration <br />
                      </div>
                    </v-stepper-step>

                    <v-stepper-step
                      class="step"
                      :rules="[() => false]"
                      step="3"
                      v-else-if="
                        usersData.EtatDgeneral == 'A' ||
                        usersData.Dcommercial == 'A'
                      "
                      @click="usersData.selectDGModal = !usersData.selectDGModal"
                    >
                      <div class="Operation">Validation administration</div>
                      <small>Cette operation a été annuler</small>
                    </v-stepper-step>

                    <v-stepper-step
                      class="step"
                      step="3"
                      v-else-if="
                        usersData.EtatDgeneral == 'N' ||
                        usersData.Dcommercial == 'N'
                      "
                      @click="usersData.selectDGModal = !usersData.selectDGModal"
                    >
                      <div class="Operation">Validation administration<br /></div>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                      class="step"
                      step="4"
                      complete
                      v-if="
                        usersData.EtatMagasinier == 'V' &&
                        usersData.ModeOperation != 'Recouvrement'
                      "
                      @click="usersData.ModalOne = !usersData.ModalOne"
                    >
                      <div class="Operation">Validation magasinier <br /></div>
                    </v-stepper-step>

                    <v-stepper-step
                      class="step"
                      :rules="[() => false]"
                      step="4"
                      v-else-if="
                        usersData.EtatMagasinier == 'A' &&
                        usersData.ModeOperation != 'Recouvrement'
                      "
                      @click="usersData.ModalOne = !usersData.ModalOne"
                    >
                      <div class="Operation">Validation magasinier</div>
                      <small>Cette operation a été annuler</small>
                    </v-stepper-step>

                    <v-stepper-step
                      class="step"
                      step="4"
                      v-else-if="
                        usersData.EtatMagasinier == 'N' &&
                        usersData.ModeOperation != 'Recouvrement'
                      "
                      @click="usersData.ModalOne = !usersData.ModalOne"
                    >
                      <div class="Operation">Validation magasinier<br /></div>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                      class="step"
                      step="5"
                      complete
                      v-if="usersData.EtatFacturation == 'V'"
                      @click="usersData.ModalTwo = !usersData.ModalTwo"
                    >
                      <div class="Operation">V.R.Facturation <br /></div>
                    </v-stepper-step>

                    <v-stepper-step
                      class="step"
                      :rules="[() => false]"
                      step="5"
                      v-else-if="usersData.EtatFacturation == 'A'"
                      @click="usersData.ModalTwo = !usersData.ModalTwo"
                    >
                      <div class="Operation">V.R.Facturation</div>
                      <small>Cette operation a été annuler</small>
                    </v-stepper-step>

                    <v-stepper-step
                      class="step"
                      step="5"
                      v-else-if="usersData.EtatFacturation == 'N'"
                      @click="usersData.ModalTwo = !usersData.ModalTwo"
                    >
                      <div class="Operation">V.R.Facturation<br /></div>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                      class="step"
                      step="6"
                      complete
                      v-if="
                        usersData.EtatMagasinierTwo == 'V' &&
                        usersData.ModeOperation != 'Recouvrement'
                      "
                      @click="usersData.ModalTree = !usersData.ModalTree"
                    >
                      <div class="Operation">Validation magasinier <br /></div>
                    </v-stepper-step>

                    <v-stepper-step
                      class="step"
                      :rules="[() => false]"
                      step="6"
                      v-else-if="
                        usersData.EtatMagasinierTwo == 'A' &&
                        usersData.ModeOperation != 'Recouvrement'
                      "
                      @click="usersData.ModalTree = !usersData.ModalTree"
                    >
                      <div class="Operation">Validation magasinier</div>
                      <small>Cette operation a été annuler</small>
                    </v-stepper-step>

                    <v-stepper-step
                      class="step"
                      step="6"
                      v-else-if="
                        usersData.EtatMagasinierTwo == 'N' &&
                        usersData.ModeOperation != 'Recouvrement'
                      "
                      @click="usersData.ModalTree = !usersData.ModalTree"
                    >
                      <div class="Operation">Validation magasinier<br /></div>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                      class="step"
                      step="7"
                      complete
                      v-if="
                        usersData.EtatSuccess == 'V' &&
                        usersData.ModeOperation != 'Recouvrement'
                      "
                      @click="usersData.ModalFour = !usersData.ModalFour"
                    >
                      <div class="Operation">Livré <br /></div>
                    </v-stepper-step>

                    <v-stepper-step
                      class="step"
                      :rules="[() => false]"
                      step="7"
                      v-else-if="
                        usersData.EtatSuccess == 'A' &&
                        usersData.ModeOperation != 'Recouvrement'
                      "
                      @click="usersData.ModalFour = !usersData.ModalFour"
                    >
                      <div class="Operation">Livré</div>
                      <small>Cette operation a été annuler</small>
                    </v-stepper-step>

                    <v-stepper-step
                      class="step"
                      step="7"
                      v-else-if="
                        usersData.EtatSuccess == 'N' &&
                        usersData.ModeOperation != 'Recouvrement'
                      "
                      @click="usersData.ModalFour = !usersData.ModalFour"
                    >
                      <div class="Operation">Livré<br /></div>
                    </v-stepper-step>
                  </v-stepper-header>
                </v-stepper>
              </div>

              <div class="text-center">
                <v-dialog v-model="usersData.selectDGModal" width="500">
                  <v-card>
                    <v-alert text type="info">D.generale</v-alert>
                    <div class="pa-8 d-flex justify-content-around">
                      <v-btn
                        depressed
                        color="success"
                        @click="changeStateFun(usersData._id, 'V', 'D.General')"
                      >
                        VALIDER
                      </v-btn>
                      <v-btn
                        depressed
                        color="error"
                        @click="changeStateFun(usersData._id, 'A', 'D.General')"
                      >
                        ANNULER
                      </v-btn>
                      <v-btn
                        depressed
                        color="warning"
                        @click="changeStateFun(usersData._id, 'N', 'D.General')"
                      >
                        NULL
                      </v-btn>
                    </div>

                    <v-divider></v-divider>
                    <v-alert text type="info">D.commercial</v-alert>
                    <div class="pa-8 d-flex justify-content-around">
                      <v-btn
                        depressed
                        color="success"
                        @click="
                          changeStateFun(usersData._id, 'V', 'D.Commercial')
                        "
                      >
                        VALIDER
                      </v-btn>
                      <v-btn
                        depressed
                        color="error"
                        @click="
                          changeStateFun(usersData._id, 'A', 'D.Commercial')
                        "
                      >
                        ANNULER
                      </v-btn>
                      <v-btn
                        depressed
                        color="warning"
                        @click="
                          changeStateFun(usersData._id, 'N', 'D.Commercial')
                        "
                      >
                        NULL
                      </v-btn>
                    </div>
                  </v-card>
                </v-dialog>
              </div>

              <div class="text-center">
                <v-dialog v-model="usersData.ModalOne" width="500">
                  <v-card>
                    <v-alert text type="info">Magasinier</v-alert>
                    <div class="pa-8 d-flex justify-content-around">
                      <v-btn
                        depressed
                        color="success"
                        @click="changeStateFun(usersData._id, 'V', 'Magasinier')"
                      >
                        VALIDER
                      </v-btn>
                      <v-btn
                        depressed
                        color="error"
                        @click="changeStateFun(usersData._id, 'A', 'Magasinier')"
                      >
                        ANNULER
                      </v-btn>
                      <v-btn
                        depressed
                        color="warning"
                        @click="changeStateFun(usersData._id, 'N', 'Magasinier')"
                      >
                        NULL
                      </v-btn>
                    </div>
                  </v-card>
                </v-dialog>
              </div>

              <div class="text-center">
                <v-dialog v-model="usersData.ModalTwo" width="500">
                  <v-card>
                    <v-alert text type="info">Facturation</v-alert>
                    <div class="pa-8 d-flex justify-content-around">
                      <v-btn
                        depressed
                        color="success"
                        @click="changeStateFun(usersData._id, 'V', 'Facturation')"
                      >
                        VALIDER
                      </v-btn>
                      <v-btn
                        depressed
                        color="error"
                        @click="changeStateFun(usersData._id, 'A', 'Facturation')"
                      >
                        ANNULER
                      </v-btn>
                    </div>
                  </v-card>
                </v-dialog>
              </div>

              <div class="text-center">
                <v-dialog v-model="usersData.ModalTree" width="500">
                  <v-card>
                    <v-alert text type="info">Magasinier</v-alert>
                    <div class="pa-8 d-flex justify-content-around">
                      <v-btn
                        depressed
                        color="success"
                        @click="changeStateFun(usersData._id, 'V', 'Magasinier2')"
                      >
                        VALIDER
                      </v-btn>
                      <v-btn
                        depressed
                        color="error"
                        @click="changeStateFun(usersData._id, 'A', 'Magasinier2')"
                      >
                        ANNULER
                      </v-btn>
                      <v-btn
                        depressed
                        color="warning"
                        @click="changeStateFun(usersData._id, 'N', 'Magasinier2')"
                      >
                        NULL
                      </v-btn>
                    </div>
                  </v-card>
                </v-dialog>
              </div>

              <div class="text-center">
                <v-dialog v-model="usersData.ModalFour" width="500">
                  <v-card>
                    <v-alert text type="info">Livraison</v-alert>
                    <div class="pa-8 d-flex justify-content-around">
                      <v-btn
                        depressed
                        color="success"
                        @click="changeStateFun(usersData._id, 'V', 'Success')"
                      >
                        VALIDER
                      </v-btn>
                      <v-btn
                        depressed
                        color="error"
                        @click="changeStateFun(usersData._id, 'A', 'Success')"
                      >
                        ANNULER
                      </v-btn>
                      <v-btn
                        depressed
                        color="warning"
                        @click="changeStateFun(usersData._id, 'N', 'Success')"
                      >
                        NULL
                      </v-btn>
                    </div>
                  </v-card>
                </v-dialog>
              </div>

              <br />
            </v-col>
          </v-row>
        </div>
      </template>
    </div>

    <div class="refreshBtn" @click="getAllStepperData">
      <v-icon large color="blue darken-2"> mdi-refresh </v-icon>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";

import axios from "axios";
import API_URL from "../../config";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      userData: null,
      alert: false,

      isLoading: true
    };
  },
  created() {
    this.getAllStepperData()
  },
  computed: {},
  methods: {
    getAllStepperData() {
      this.isLoading = true

      axios
      .get(`${API_URL}/rapport/rapportByOneMagasin/${this.$route.params.MagasinFor}?year=${this.currentYear}`)
      .then((selected) => {
        this.userData = selected.data;
        this.isLoading = false
      })
    },
    reddirectToRapportFunc(value) {
      if (value.ModeOperation == "Vente") {
        if((value.EtatDgeneral == "V" || value.EtatDgeneral == "V") && value.EtatFacturation != "V") {
          if (value.Rapport.Engraisclassiques)
            window.open(`${API_URL}/PDF/DownloadRapportFromBack/${value._id}/${value.for._id}/EC/BC`,"_blank");
          else if (value.Rapport.Engraisspeciaux)
            window.open(`${API_URL}/PDF/DownloadRapportFromBack/${value._id}/${value.for._id}/ES/BC`,"_blank");
          else if (value.Rapport.Semences)
            window.open(`${API_URL}/PDF/DownloadRapportFromBack/${value._id}/${value.for._id}/S/BC`,"_blank");
          else if (value.Rapport.Pesticides)
            window.open(`${API_URL}/PDF/DownloadRapportFromBack/${value._id}/${value.for._id}/P/BC`,"_blank");
          else if (value.Rapport.Hygiènepublique)
            window.open(`${API_URL}/PDF/DownloadRapportFromBack/${value._id}/${value.for._id}/HP/BC`,"_blank");
          else if (value.Rapport.Additifs)
            window.open(`${API_URL}/PDF/DownloadRapportFromBack/${value._id}/${value.for._id}/V/BC`,"_blank");
        }
        else if(value.EtatFacturation == "V") {
          if (value.Rapport.Engraisclassiques)
            window.open(`${API_URL}/PDF/DownloadRapportFromBack/${value._id}/${value.for._id}/EC/F`,"_blank");
          else if (value.Rapport.Engraisspeciaux)
            window.open(`${API_URL}/PDF/DownloadRapportFromBack/${value._id}/${value.for._id}/ES/F`,"_blank");
          else if (value.Rapport.Semences)
            window.open(`${API_URL}/PDF/DownloadRapportFromBack/${value._id}/${value.for._id}/S/F`,"_blank");
          else if (value.Rapport.Pesticides)
            window.open(`${API_URL}/PDF/DownloadRapportFromBack/${value._id}/${value.for._id}/P/F`,"_blank");
          else if (value.Rapport.Hygiènepublique)
            window.open(`${API_URL}/PDF/DownloadRapportFromBack/${value._id}/${value.for._id}/HP/F`,"_blank");
          else if (value.Rapport.Additifs)
            window.open(`${API_URL}/PDF/DownloadRapportFromBack/${value._id}/${value.for._id}/V/F`,"_blank");
        }
      }
      else {
        // /DownloadRapportFromBackRecouvrement/:rapportID/:for/:typeOf/F
        window.open(`${API_URL}/PDF/DownloadRapportFromBackRecouvrement/${value._id}/${value.for._id}/V/F`,"_blank");
      }
    },
    changeStateFun(userID, etat, role) {
      axios
        .put(`${API_URL}/rapport/changeState/${userID}/${role}/${etat}`)
        .then(() => {
          this.getAllStepperData()
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.rigthSideContents {
  width: 80%;
  float: left;
  height: 90vh !important;
  padding: 2% 4%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: $primaryFont;
  overflow: auto;
      .Filter{
        display:flex;
        justify-content:space-around;
        align-items:center;
        width:80%;
        margin:30px  auto;
        .FilterParCatég {
            h6 {
                color: $primaryColor;
            }
        }
        h6{
        font-family:$primaryFont ;
        font-size: 18px;
        font-weight:bold;
        color:#142048;
        padding-bottom: 5px;
        }
        select,Input{
                border: 1px solid #035a7f;
                width:100%;
                padding:6px  ;
                border-radius: 6px;
                cursor: pointer;
        }
    }
    .isLoading {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  .v-sheet.v-stepper:not(.v-sheet--outlined) {
    background: linear-gradient(180deg, #f3f3f3 50%, #dfdfdf 100%);
    margin-bottom: 2%;
  }
  .steper {
    width: 100%;
    .magasineName {
      background: linear-gradient(180deg, #f3f3f3 50%, #dfdfdf 100%);
      padding: 3% 0%;
      border-radius: 20px;
      text-align: center;
      cursor: pointer;
      .magasinID {
        font-size: 20px;
        color: #167a71;
        font-weight: 500;
      }
      .magasinSubName,
      .magasinSubNameTwo,
      .magasinSubNameTree {
        color: #8a8d8d;
        font-size: 13px;
      }
      p {
        margin: 0;
      }
    }
  }
  .centerContents {
    text-align: center;
    .userName {
      color: #7b3f37;
      font-weight: 600;
      margin-bottom: 30px;
      letter-spacing: 1px;
    }
    .TXT {
      font-weight: bold;
      margin-right: 8px;
    }
    .allTxt {
      line-height: 15px;
    }
    // .email {
    // }
    // .phone {
    // }
    // .zone {
    // }
  }
  .modifyIcone {
    @media (max-width: 990px) {
      display: flex;
      justify-content: space-around;
      align-items: baseline;
    }
    i {
      cursor: pointer;
    }
  }
  .usersImg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .userImg {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
  @media (max-width: 1280px) {
    width: 70%;
  }
  @media (max-width: 989px) {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .v-card {
    width: 50%;
    margin: 0% -2%;
    margin-bottom: 3%;
    @media (max-width: 1400px) {
      width: 100%;
    }
  }
}

.refreshBtn {
  position: fixed;
  bottom: 0;
  right: 0;
  cursor: pointer;
  i {
    font-size: 90px !important;
  }
}
</style>
